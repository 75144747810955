import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { sessionService } from 'redux-react-session';
import axios from 'axios';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import CONSTANTS from '../config/constants.react';
import { composeWithDevTools } from 'redux-devtools-extension';
import _ from 'lodash';
import reducer from './reducers/index.js';

const validateSession = (session) => {
  // Check if your session is still valid
  if (!_.isNil(session)) {
    // Use axios error handling
    return axios({
      method: 'post',
      url: CONSTANTS.SERVER_URL + CONSTANTS.CALLS.ADMIN_VALIDATE,
      headers: { Authorization: `Bearer ${session.token}` }
    })
      .then((res) => {
        if (res.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        // Handle the Axios error here
        console.error('Axios error:', error);
        return false;
      });
  } else {
    return false;
  }
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: []
};

export default function configureStore(initialState) {
  const persistedReducer = persistReducer(persistConfig, reducer);
  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  
  const options = {
    refreshOnCheckAuth: true,
    redirectPath: '/login',
    driver: 'COOKIES',
    validateSession,
  };

  // Initialize session service and handle success/failure
  sessionService.initSessionService(store, options).then(() => {
    console.log('Redux React Session is ready and a session was refreshed from your storage');
  }).catch(() => {
    console.log('Redux React Session is ready and there is no session in your storage');
  });

  // Debugging: You can subscribe to store changes for debugging
  // store.subscribe(() => console.log('ReduxState', store.getState()));

  return { store, persistor };
}
