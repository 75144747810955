import React from 'react';
import { getEntries } from '../../../redux/apiCalls/appData';
import { connect } from 'react-redux';
import { setRowsPerPage } from '../../../redux/actions/appAction';
import CustomTable from "../../components/tables/CustomTable";
import { CopyIcon, DragIcon, DragSortIcon, EditIcon, PlusIcon, SortIcon, TrashIcon } from '../../icons/Icons';
import Spinner from '../../shared/Spinner';
import _ from "lodash";

class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            input: '',
            sort: 'DESC',
            sortIcon: 'caret-down',
            entries: [],
            loading: true,
            totalRecords: 0,
            rowsPerPage: 20,
        };
        this.load = true;
    }
    componentDidMount = () => {
        this.timer = null;
        this.props.getEntries({sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage}, (data) => {
           console.log("Data : ", data);
            this.setState({loading:false, entries: this.props.entries, totalRecords: data.totalRecords, rowsPerPage:this.state.rowsPerPage});
            // this.setState({loading:false, entries: this.props.records, totalRecords: data.totalRecords, rowsPerPage:this.props.rowsPerPage});
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            users: nextProps.users
        }
    }

    handleChangePage = (page) => {
        this.setState({page});
        this.props.getEntries({ sort: this.state.sort, page: page, rowsPerPage: this.state.rowsPerPage }, (data) => {
            console.log("Data : ", data);
            let appendNewRecords = [...this.state.entries, ...data.records]
            this.setState({  entries: appendNewRecords, totalRecords: data.totalRecords, page });
        });
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({ page: 0, loading:true });
        this.props.setRowsPerPage(event.target.value);
        this.props.getEntries({ sort: this.state.sort, page: this.state.page, rowsPerPage: event.target.value }, (data) => {
            console.log("Data : ", data);
            this.setState({ loading: false, entries: this.props.entries, totalRecords: data.totalRecords, rowsPerPage: this.state.rowsPerPage });
        });
        // this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: event.target.value, defaultTemplate: true, baseTemplate: true}, (data) => {
        //     this.props.deleteTemplates();
        //     this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords});
        // });
    }

    // setAdminRights = (user) => {
    //     console.log("Set Admin Rights", user);
    //     let data = {
    //         userType: user.userType == "inspector" ? "admin" : "inspector",
    //         userId: user.id

    //     }
    //     this.props.setUserType(data);
    // }
    // setUserStatus = (user) => {
    //     console.log("Set User Status", user);
    //     let data = {
    //         userStatus: user.userStatus == 0 ? 1 : 0,
    //         userId: user.id
    
    //     }
    //     this.props.setUserStatus(data);
    // }
    render = () => {
        const { loading, page, totalRecords, entries , rowsPerPage} = this.state;
        return (
            <div>
                 <div className="scope-templetes-heading">
                    <div className="scope-templetes-title">Payments</div>
                    <div className="scope-templetes-filters">
                        
                    </div>
                </div>
                 {loading ? <Spinner/> :  !_.isEmpty(entries) ?
                                        <CustomTable
                                        tableHead={tableHead}
                                        tableData={entries}
                                        type="payments"
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        totalNumber={totalRecords}
                                        handleChangePage={this.handleChangePage}
                                        sorting={this.sortingTemplates}
                                        disableDrag={true}
                                    />

                    : <h4 className="scope-templetes-heading">No Payments Found</h4> }
        </div>
        );
    }
}

const tableHead = [
    { id: 'payment/sr', label: 'Sr / Serial No.', sortable: false },
    { id: 'payment/date', label: 'Date.', sortable: false },
    { id: 'payment/customer', label: 'Customer', sortable: false },
    { id: 'payment/plan', label: 'Subscription', sortable: false },
    { id: 'payment/amount', label: 'Amount', sortable: false },
];

const mapStateToProps = (state) => {
    return {
        rowsPerPage: state.appData.rowsPerPage,
        entries: state.appData.entries
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows)),
        getEntries: (object, cb) => dispatch(getEntries(object, cb))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);