import {combineReducers} from 'redux';
import appData from './appReducer';
import authData from './authReducer';
import { sessionReducer } from 'redux-react-session';

export default combineReducers({
    appData,
    authData,
    session: sessionReducer
});
