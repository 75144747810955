// types of action
const Types = {
    SET_ALL_TEMPLATES: 'SET_ALL_TEMPLATES',
    DELETE_TEMPLATES: 'DELETE_TEMPLATES',
    ALERT_POPUP: 'ALERT_POPUP',
    ROWS_PER_PAGE: 'ROWS_PER_PAGE',
    SET_ITEMS: 'SET_ITEMS',
    SET_USERS: 'SET_USERS',
    SET_USER: 'SET_USER',
    SET_ENTRIES: 'SET_ENTRIES',
    SET_PLANS: 'SET_PLANS',
    SET_COMMENTS: 'SET_COMMENTS',
    SET_USER_INSPECTIONS: 'SET_USER_INSPECTIONS',
    SET_CONTACT: 'SET_CONTACT',
    SEND_INSPECTION_REPORT: 'SEND_INSPECTION_REPORT',
    SET_EMAIL_HOOKS: 'SET_EMAIL_HOOKS',
};

// actions
export const loading = status => ({
    type: Types.LOADER,
    payload: status
});

export const setAllTemplates = (templates) => {
    return {
        type: Types.SET_ALL_TEMPLATES,
        payload: templates
    };
};

export const setEmailHooks = (hooks) => {
    return {
        type: Types.SET_EMAIL_HOOKS,
        payload: hooks
    };
};

export const setAllItems = (items) => {
    return {
        type: Types.SET_ITEMS,
        payload: items
    };
};

export const setAllUsers = (users) => {
    return {
        type: Types.SET_USERS,
        payload: users
    };
};

export const setUser = (user) => {
    return {
        type: Types.SET_USER,
        payload: user
    };
};

export const deleteTemplates = () => ({
    type: Types.DELETE_TEMPLATES
});

export const alertPopupAction = (status, message, color) => {
    return {
        type: Types.ALERT_POPUP,
        data: {
            open: status, message, color
        }
    };
};

export const setRowsPerPage = (rowsPerPage) => {
    return {
        type: Types.ROWS_PER_PAGE,
        data: rowsPerPage
    }
}

export const setPlans = (plans) => {
    return {
        type: Types.SET_PLANS,
        payload: plans
    };
};
export const setEntries = (users) => {
    return {
        type: Types.SET_ENTRIES,
        payload: users
    };
};

export const setComments = (comments) => {
    return {
        type: Types.SET_COMMENTS,
        payload: comments
    }
}
export const setUserAllInspections = (inspections) => {
    return {
        type: Types.SET_USER_INSPECTIONS,
        payload: inspections
    };
};
export const setContact = (contacts) => {
    return {
        type: Types.SET_CONTACT,
        payload: contacts
    };
};
export const sendInspectionReport = (reportSent) => {
    return {
        type: Types.SEND_INSPECTION_REPORT,
        payload: reportSent
    };
};


export default {
    Types
};