import React, { Component } from 'react'

export class Spinner extends Component {
  render() {
    let customClass = this.props.className == undefined ? 'spinner-wrapper' : this.props.className+' spinner-wrapper' ;
    return (
      <div>
        <div className={customClass}>
          <div className="donut"></div>
        </div>
      </div>
    )
  }
}

export default Spinner
