import PropTypes from "prop-types";
import React from "react";
import "./IconexFilledStar.css";

export const IconexFilledStar = ({ className, starClassName, star = "star-2.svg" }) => {
  return (
    <div className={`iconex-filled-star ${className}`}>
      <div className="star">
        <img className={`img ${starClassName}`} alt="Star" src={star} />
      </div>
    </div>
  );
};

IconexFilledStar.propTypes = {
  star: PropTypes.string,
};
