/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
import React from 'react';
// @material-ui/core components
// core components
import _ from "lodash";
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { setRowsPerPage } from '../../../redux/actions/appAction';
import { getUsers, setUserStatus, setUserType, updateUserData } from '../../../redux/apiCalls/appData';
import UserEditModal from '../../components/modal/userEditModal';
import CustomTable from "../../components/tables/CustomTable";
import { SearchIcon, SortIcon } from '../../icons/Icons';
import Spinner from '../../shared/Spinner';
class UserListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            input: '',
            sort: 'DESC',
            sortBy: 'firstname',
            sortIcon: 'caret-down',
            users: [],
            loading: true,
            totalRecords: 0,
            rowsPerPage: 20,
            userModalSettings: {
                show: false,
                user: {},
            }
        };
        this.load = true;
    }
    componentDidMount = () => {
        this.timer = null;
        this.props.getUsers({sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage}, (data) => {
           console.log("Data : ", data);
            this.setState({loading:false, users: this.props.users, totalRecords: data.totalRecords, recordsPerPage:this.state.rowsPerPage});
        });
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     return {
    //         users: nextProps.users
    //     }
    // }

    handleChangePage = (page) => {
        this.setState({page});
        this.props.getUsers({ sort: this.state.sort, sortBy: this.state.sortBy, input: this.state.input, page: page, rowsPerPage: this.state.rowsPerPage }, (data) => {
            let appendNewRecords = [...this.state.users, ...data.records]
            this.setState({users: appendNewRecords, totalRecords: data.totalRecords, page });
        });
        // this.props.getAllTemplates({ sort: this.state.sort, input: this.state.input, page: page, rowsPerPage: this.state.rowsPerPage, defaultTemplate: true, baseTemplate: true }, (data) => {
        //     this.props.deleteTemplates();
        //     this.setState({ loading: false, templates: data.records, totalRecords: data.totalRecords, page });
        // });
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({ page: 0, loading:true });
        this.props.setRowsPerPage(event.target.value);
        this.props.getUsers({ sort: this.state.sort, page: this.state.page, rowsPerPage: event.target.value }, (data) => {
            this.setState({ loading: false, users: this.props.users, totalRecords: data.totalRecords, recordsPerPage: this.state.rowsPerPage });
        });
        // this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: event.target.value, defaultTemplate: true, baseTemplate: true}, (data) => {
        //     this.props.deleteTemplates();
        //     this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords});
        // });
    }

    setAdminRights = (user) => {
        console.log("Set Admin Rights", user);
        let data = {
            userType: user.userType === "inspector" ? "admin" : "inspector",
            userId: user.id

        }
        this.props.setUserType(data);
    }
    setUserStatus = (user) => {
        console.log("Set User Status", user);
        let data = {
            userStatus: user.userStatus === 0 ? 1 : 0,
            userId: user.id
        }
        this.props.setUserStatus(data, (userData) => {
            if(userData){
                if(userData.userStatus === 1){
                    toast.error(`${userData.email} has been blocked`);
                } else {
                    toast.success(`${userData.email} has been unblocked`);
                }
                this.updateUserById(user.id, { userStatus: userData.userStatus });
            }
        });
        // this.updateUserById(user.id, { userStatus: user.userStatus });
    }

    openUserEditModal = (user) => {
        this.state.userModalSettings = {
            show: true,
            user: _.merge({}, user)
        };
        this.setState(this.state);
    }

    searchUsers = () => {
        this.setState({loading:true, page:0});
        this.props.getUsers({sort: this.state.sort, page: 0, rowsPerPage: this.state.rowsPerPage, input: this.state.input}, (data) => {
             this.setState({loading:false, users: this.props.users, totalRecords: data.totalRecords, recordsPerPage:this.state.rowsPerPage});
         });
    }

    handleSearchFilter = (e) => {
        this.state.input = e.target.value.toLowerCase();
        this.setState(this.state);
        this.searchUsers();
    }

    handleSorting = (orderBy) => {
        this.state.sort = this.state.sort == 'DESC' ? 'ASC' : 'DESC';
        this.state.sortBy = orderBy;
        this.state.loading = true;
        this.state.page = 0;
        this.setState(this.state);
        // let currentPageRecords = ((this.state.page+1) * this.state.rowsPerPage);
        this.props.getUsers({sort: this.state.sort, sortBy: orderBy, page: this.state.page, rowsPerPage: this.state.rowsPerPage, input: this.state.input}, (data) => {
            this.setState({loading:false, users: this.props.users, totalRecords: data.totalRecords, recordsPerPage:this.state.rowsPerPage});
        });
    }
    

    userModalClose = (action, user) => {
        if(action === "Save"){
            this.props.updateUserData({userId: user.id, reportLimits: user.reportLimits, userType: user.userType}, (data) => {
                if(data){
                    toast.success(`${user.email} successfully updated`);
                    this.updateUserById(user.id, { reportLimits: user.reportLimits, userType: user.userType });
                }
            });
        }
        this.state.userModalSettings.show = false;
        this.setState(this.state);
    }

    // Function to update a user by ID
    updateUserById = (id, updatedUserInfo) => {
        this.setState(prevState => {
        const updatedUsers = prevState.users.map(user => {
            if (user.id === id) {
            // Update the user object by spreading the existing properties and updating the specified fields
            return { ...user, ...updatedUserInfo };
            }
            return user; // Return unchanged objects
        });
        return { users: updatedUsers }; // Update the state with the new array
        });
    };
  
    render = () => {
        const { rowsPerPage } = this.props;
        const { loading, page, totalRecords, users } = this.state;
        return (
            <div>
                <div className="scope-templetes-heading">
                    <div className="scope-templetes-title">Users</div>
                    <div className="scope-comment-search">
                        <div className="input-group">
                            <div className="input-group-append">
                            <div className='search-icon-box'>
                                <SearchIcon />
                            </div>
                            </div>
                            <input className="form-control" 
                            id="comment-search-input"
                            type="text"
                            placeholder="Search User by Email/Phone/Name" 
                            onChange={this.handleSearchFilter}
                            />
                        </div>
                    </div>
                    <div className="scope-templetes-filters">
                        
                    </div>
                 
                    {/* <div className="scope-templetes-add-new-button" >
                        <PlusIcon className="plus"/>
                        <div className="scope-templetes-add-new-title">Add User</div>
                    </div> */}
                </div>
                {/* <Spinner/>  */}
                 {loading ? <Spinner/> :  !_.isEmpty(users) ?
                                        <CustomTable
                                        tableHead={tableHead}
                                        tableData={users}
                                        openUserEditModal={this.openUserEditModal}
                                        block={this.setUserStatus}
                                        type="users"
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        totalNumber={totalRecords}
                                        handleChangePage={this.handleChangePage}
                                        sorting={this.sortingTemplates}
                                        handleSorting={this.handleSorting}
                                        disableDrag={true}
                                    />

                    : <h4 className="scope-templetes-heading">No Users Found</h4> }
                    <UserEditModal modalSettings={this.state.userModalSettings} close={this.userModalClose} />
        </div>
        );
    }
}

const tableHead = [
    { id: 'firstname', label: 'First Name', sortable: true,  afterIcon: <SortIcon className="scope-svg-header-after-icon"/>, width:"16%" },
    { id: 'lastname', label: 'Last Name', sortable: true,  afterIcon: <SortIcon className="scope-svg-header-after-icon"/> , width:"15%" },
    { id: 'email', label: 'Email', sortable: true,  afterIcon: <SortIcon className="scope-svg-header-after-icon"/> , width:"150px" },
    { id: 'phone', label: 'Phone', sortable: true,  width:"130px"  },
    { id: 'userType', label: 'Type', sortable: true ,  afterIcon: <SortIcon className="scope-svg-header-after-icon"/> , width:"10%" },
    { id: 'createdAt', label: 'Sign up', sortable: true,  afterIcon: <SortIcon className="scope-svg-header-after-icon"/> , width:"11%" },
    { id: 'updatedAt', label: 'Last Used', sortable: true ,  afterIcon: <SortIcon className="scope-svg-header-after-icon"/>, width:"12%" },
    // { id: 'user/password', label: 'password', sortable: false },
    { id: 'planMode', label: 'Plan', sortable: true, afterIcon: <SortIcon className="scope-svg-header-after-icon"/>, width:"100px" },
    { id: 'reportLimits', label: 'Report Limits', sortable: true, afterIcon: <SortIcon className="scope-svg-header-after-icon"/>, width:"100px" },
    { id: 'user/action', label: 'Action', sortable: false , width:"10%"},
];

const mapStateToProps = (state) => {
    return {
        rowsPerPage: state.appData.rowsPerPage,
        users: state.appData.users
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows)),
        setUserType: (object, cb) => dispatch(setUserType(object, cb)),
        setUserStatus: (object, cb) => dispatch(setUserStatus(object, cb)),
        getUsers: (object, cb) => dispatch(getUsers(object, cb)),
        updateUserData: (object, cb) => dispatch(updateUserData(object, cb))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListing);