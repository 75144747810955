/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import {EditIcon, TrashIcon, LinkIcon, EyeIcon, CollapsebleItemIcons, PlusIcon, CheckIcon} from "../../icons/Icons";
import { Collapse } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import {Button as Btn} from "../../components/button/button";
import ChoiceItemEditModal from "../modal/choiceItemEditModal";
import Form from 'react-bootstrap/Form';
import DeleteConfirmationModal from "../modal/deleteConfirmationModal";
/* eslint-disable react/no-direct-mutation-state */


class ListItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      item: {},
      type: "",
      options: {},
      index: 0,
      active: "",
      collapseState: false,
      optionIndex:null,
      newOption:"",
      optionPlaceHolder:"Add new option",
      placeholderColorClass:"",
      itemEditModalSettings:{
          show:false,
          item:{},
          index: null
      },
      deleteConfirmationModal:{
        show: false,
        title: "",
        body: "",
        type: "",
      }
    }
  }
  componentDidMount = async () => {
    this.state.item = this.props.item;
    this.state.type = this.props.type;
    this.state.index = this.props.index;
    this.state.options = this.props.options;
    this.state.active = this.props.active;
    this.state.collapseState = this.props.collapseState;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.item = nextProps.item;
    this.state.type = nextProps.type;
    this.state.options = nextProps.options;
    this.state.index = nextProps.index;
    this.state.active = nextProps.active;
    this.state.collapseState = nextProps.collapseState;
    this.setState( this.state );
  }

  changeCollapseState = () => {
    this.state.collapseState = !this.state.collapseState;
    this.setState(this.state);
  }

  addOption = () => {
    let option = this.state.newOption;
    if(option !== ""){
      this.props.addChoiceOption(this.state.index, option);
      this.state.newOption = "";
      this.state.optionPlaceHolder = "Add new option";
      this.state.placeholderColorClass = "";
    } else {
      this.state.optionPlaceHolder = "Please enter value first";
      this.state.placeholderColorClass = "error";
    }
    this.setState(this.state);
  }

  onChange = (e) => {
    this.state.newOption = e.target.value;
    this.setState(this.state);
  }

  editItemModalClose = (action, value, index) => {
    if(action === "Save"){
      this.props.updateChoiceOption(index, this.state.index, value)
    }
    this.setState({itemEditModalSettings: {show: false}});
  }
  openEditModal = (option, index) => {
    let settings = {show:true, item:this.state.item, index:index, title: `${this.state.item.itemLabel} > ${option}`, value: option};
    this.setState({itemEditModalSettings:settings});
  }

  deleteConfirmationModalClose = (action) => {
    this.state.deleteConfirmationModal = {
      show: false,
      title: "",
      body: "",
      type: "",
    };
    this.setState(this.state);
    if(action === "Yes"){
      this.props.deleteItem(this.state.index);
    }
  }

  openDeleteConfirmationModal = () => {
    this.state.deleteConfirmationModal = {
      show: true,
      title: `Delete item`,
      body: ``,
      type: 'danger'
    }
    this.setState(this.state);
  }
  // item.hasOwnProperty("itemTrail") && Object.keys(item.itemTrail).length > 0
  render = () => {
    const {type, item, options, index, active, collapseState} = this.state;
    const {templateType} = this.props;
    return (
      <>
        <ListGroup.Item key={item.uid ? item.uid : `${item.itemLabel}-${index}`} action onClick={(e) => {if(item.itemType === "choice"){e.stopPropagation();this.props.changeCollapseState(index);this.props.listClick(index, type, this.props.index)} else { this.props.listClick(index, type, this.props.index)}}} className={`listitem label item${type} ${active} item-${item.itemType}`} as={this.props.draggableComponent}>
            <div className="labelName">
              {item.itemLabel}<br/>
              <span className={item.itemValue ? "subLabelName" : ""}>{item.itemValue}</span>
            </div>
            <div className="manage-actions">
              {item.itemType === "choice" && 
                <div className="collapseIcon" onClick={(e) => {e.stopPropagation();this.props.changeCollapseState(index)}}>
                  <CollapsebleItemIcons className="scope-svg-menu-icon cursor" icon={collapseState === true ? "open" : "close"} />
                </div>}
              {options.visible && 
                <div className="eyeIcon" onClick={(e) => {e.stopPropagation(); this.props.visibilityHandler(index, 'item')}}>
                  <EyeIcon icon={item.visibility === true ? "visible" : "invisible"}/>
                </div>
              }
              {options.link && 
                <div className="linkIcon" onClick={(e) => {e.stopPropagation(); this.props.addRef({show:true,itemIndex:index, view: "menu", item: item})}}>
                  <LinkIcon/>
                </div>
              }
                {options.edit &&
                <div className="editIcon" onClick={(e) => {e.stopPropagation();this.props.editItem({show:true, item:item, index:index, title: item.itemLabel})}}>
                    <EditIcon />
                </div>
                }
                {options.delete && 
                <div className="trashIcon" onClick={(e) => {e.stopPropagation(); this.openDeleteConfirmationModal()}}>
                    <TrashIcon/>
                </div>
                }
            </div>
        </ListGroup.Item>
        {item.itemType === "choice" && 
          <Collapse in={collapseState}>
            <ListGroup className="nested-list">
              {item.itemOptions && item.itemOptions.length > 0 && 
                item.itemOptions.map((option, i) => {
                  return (
                    <ListGroup.Item key={`${option}-${i}`} className="listitem nestedlistitem label itemTemplateOptions">
                        <div className="labelName">{option}</div>
                        <div className="manage-actions">
                        {options.edit &&
                            <div className="editIcon" onClick={(e) => this.openEditModal(option, i)}>
                                <EditIcon />
                            </div>
                        }
                        {options.delete && 
                            <div className="trashIcon" onClick={(e) => {e.stopPropagation(); this.props.deleteChoiceOption(i, index)}}>
                                <TrashIcon/>
                            </div>
                        }
                        </div>
                    </ListGroup.Item>
                  )
                })
              }
              {(options.edit && options.delete) && <>
              <ListGroup.Item className="listitem nestedlistitem label">
                  <div className="labelName">
                      <Form.Control type="text" placeholder={this.state.optionPlaceHolder} value={this.state.newOption} onChange={(e) => this.onChange(e)} className={`addNewOption ${this.state.placeholderColorClass}`}></Form.Control>
                  </div>
                  <div className="manage-actions">
                  <Btn
                      className={`item-option-add addChoiceOptionBtn`}
                      label={``}
                      showLeftIcon={true}
                      leftIcon={<PlusIcon svgFill="#FFFFFF" iconFill="#FFFFF" className="template_add_option_btn"/>}
                      showRightIcon={false}
                      size="large"
                      state="default"
                      variant="filled"
                      type="primary"
                      clickEvent={(e) => this.addOption()}
                      />
                  </div>
              </ListGroup.Item>
              {(templateType !== "fixed") && <>
              <ListGroup.Item className="listitem nestedlistitem label">
                  <div className="labelName">Allow Multiple Selection</div>
                  <div className="manage-actions">
                      <Button variant="link" className="collapse-btn" onClick={(e) => this.props.choiceCheckHandle(index, "allowMultipleSelection")}>
                          <CheckIcon className="scope-svg-menu-icon cursor" icon={item.allowMultipleSelection === true ? "check" : "uncheck"} />
                      </Button>
                  </div>
              </ListGroup.Item>
              <ListGroup.Item className="listitem nestedlistitem label">
                  <div className="labelName">Prohibit Condition Ratings</div>
                  <div className="manage-actions">
                      <Button variant="link" className="collapse-btn" onClick={(e) => this.props.choiceCheckHandle(index, "prohibitConditionRatings")}>
                          <CheckIcon className="scope-svg-menu-icon cursor" icon={item.prohibitConditionRatings === true ? "check" : "uncheck"} />
                      </Button>
                  </div>
              </ListGroup.Item></>}
              </>}
            </ListGroup>
          </Collapse>
        }
        <ChoiceItemEditModal modalSettings={this.state.itemEditModalSettings} close={this.editItemModalClose} />
        <DeleteConfirmationModal modalSettings={this.state.deleteConfirmationModal} close={this.deleteConfirmationModalClose} />
      </>
      )
    }
}

export default ListItem;