/* eslint-disable eqeqeq */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
import React from 'react';
// @material-ui/core components
// core components
import { connect } from 'react-redux';
import { stripePayment, getCurrentUser, getUserPlan, stripeGetPaymentLink, cancelSubscription, getPlans, stripeConnect, stripeAccountRetrieve } from '../../../redux/apiCalls/appData';
// import StripeCheckout from 'react-stripe-checkout';
// import moment from 'moment';
import { Plans } from '../../components/plans/plans';
import { Button } from '../../components/button/button';
import DeleteConfirmationModal from '../../components/modal/deleteConfirmationModal';
import _ from "lodash";
import toast from 'react-hot-toast';
import Spinner from '../../shared/Spinner';
class PlanListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            product: "Free",
            purchaseDate: null,
            cycle: "non",
            cycleEnd: "M",
            cancelAtEnd: false,
            user: {},
            stripeAccount:{},
            plans: [],
            deleteConfirmationModal:{
                show: false,
                title: "",
                body: "",
                type: "",
            },
            btnState:false,
            stripeBtnState: false
        };
        this.load = true;
    }
    componentDidMount = () => {
        this.props.getUserPlan({}, (data)=>{
            // console.log(data);
            if(data){
                let cycle = "non";
                let cycleEnd = 0;
                if(data.purchaseDetails.planMode === "monthly"){
                    cycle = "monthly";
                    cycleEnd = "M";

                } else if(data.purchaseDetails.planMode === "yearly"){
                    cycle = "yearly";
                    cycleEnd = "Y";
                }
                let cancelAtEnd = false;
                if(data.cancelAtPeriodEnd){
                    cancelAtEnd = true;
                }
                if(this.props.user){
                    this.setState({product:data.purchaseDetails.product, purchaseDate: data.purchaseDate, cycle:cycle, cycleEnd:cycleEnd, cancelAtEnd:cancelAtEnd, user: this.props.user});
                } else {
                    this.setState({product:data.purchaseDetails.product, purchaseDate: data.purchaseDate, cycle:cycle, cycleEnd:cycleEnd, cancelAtEnd:cancelAtEnd});
                }
            } else {
                // this.setState({loading:false});
            }
            // console.log(this.state);
            // console.log(this.props);
        });
        this.props.getPlans({}, (data) => {
            if(data.length > 0){
                if (this.props.user){
                    this.setState({loading:false, plans:data});
                } else {
                    this.setState({loading:false, plans:data, user: this.props.user});
                }
            }
        });

        // Get StripeAccountDetails
        this.props.stripeAccountRetrieve({}, (data) => {
            if (data != "No Account ID Found"){
                this.setState({stripeAccount: data});
                // console.log(this.props.user)
                if ( this.props.user ) {
                    this.setState({user: this.props.user});
                }
            }
        });

        // After plan purchase (Redirected by Stripe just to confirm if the plans are not reflected)
        const searchParams = new URLSearchParams(document.location.search);
        let paramP = searchParams.get('payment');
        if(paramP === "success"){
            if(this.state.product === "Free"){
                // toast.success("Your payment has been recieved, kindly reload the page if you are unable to see your activated plan", {
                //     duration: 10000,
                //     ariaProps: {
                //         role: 'status',
                //         'aria-live': 'polite',
                //       },
                //       position: 'bottom-left',
                // });
                // toast.loading('Waiting...');
                var myPromise = new Promise( (resolve, reject) => {
                    this.props.getUserPlan({}, (data)=>{
                        this.setState({loading:true});
                        if(data){
                            let cycle = "non";
                            let cycleEnd = 0;
                            if(data.purchaseDetails.planMode === "monthly"){
                                cycle = "monthly";
                                cycleEnd = "M";
            
                            } else if(data.purchaseDetails.planMode === "yearly"){
                                cycle = "yearly";
                                cycleEnd = "Y";
                            }
                            let cancelAtEnd = false;
                            if(data.cancelAtPeriodEnd){
                                cancelAtEnd = true;
                            }
                            if(this.props.user){
                                this.setState({loading:false, product:data.purchaseDetails.product, purchaseDate: data.purchaseDate, cycle:cycle, cycleEnd:cycleEnd, cancelAtEnd:cancelAtEnd, user: this.props.user});
                            } else {
                                this.setState({loading:false, product:data.purchaseDetails.product, purchaseDate: data.purchaseDate, cycle:cycle, cycleEnd:cycleEnd, cancelAtEnd:cancelAtEnd});
                            }
                            resolve("Promise resolved successfully");
                            searchParams.delete('payment');
                            const updatedSearch = searchParams.toString();
                            const newUrl = `${window.location.pathname}${updatedSearch ? `?${updatedSearch}` : ''}`;
                            window.history.pushState({}, '', newUrl);
                        } else {
                            this.setState({loading:false});
                            reject(Error("Promise rejected"));
                        }
                    });
                    this.props.getPlans({}, (data) => {
                        if(data.length > 0){
                            if (this.props.user){
                                this.setState({plans:data});
                            } else {
                                this.setState({plans:data, user: this.props.user});
                            }
                        }
                    });
            
                    // Get StripeAccountDetails
                    this.props.stripeAccountRetrieve({}, (data) => {
                        if (data != "No Account ID Found"){
                            this.setState({stripeAccount: data});
                            // console.log(this.props.user)
                            if ( this.props.user ) {
                                this.setState({user: this.props.user});
                            }
                        }
                    });
                    // let name = 'Dave'
               
                    // if (name === 'Dave') {
                    //    resolve("Promise resolved successfully");
                    // }
                    // else {
                    //    reject(Error("Promise rejected"));
                    // }
                 });
                toast.promise(myPromise, {
                    loading: 'Waiting for payment confirmation!',
                    success: 'Payment confirmed',
                    error: 'Something went wrong, please try logging out and login again!',
                  });
            }
        }

        let paramV = searchParams.get('verification');
        if(paramV && (paramV === "success" || paramV === "error")){
            this.props.getCurrentUser();
        }
        
    }

    submit = async (e, type) => {
        e.preventDefault();

        // console.log(this.props);
        // console.log(type);
    }
    onToken = (token, addresses) => {
        // console.log(token);
        // console.log(addresses);
        // TODO: Send the token information and any other
        // relevant information to your payment process
        // server, wait for the response, and update the UI
        // accordingly. How this is done is up to you. Using
        // XHR, fetch, or a GraphQL mutation is typical.
    };

    handleToken = (token, addresses, product, amount, price_id, planMode) => {
        // console.log("App#handleToken");
        // console.log(token);
        // console.log(addresses);
        // console.log(product);
    
        const body = new FormData();
        // Send information to determine how to charge customer:
        body.append("product", product);
        body.append("amount", amount);
        body.append("quantity", 1);
        body.append("price_id", price_id);
        body.append("planMode", planMode+"ly");
    
        // Send standard Stripe information:
        body.append("stripeEmail", token.email);
        body.append("stripeToken", token.id);
        body.append("stripeTokenType", token.type);
    
        body.append("stripeBillingName", addresses.billing_name || "");
        body.append(
          "stripeBillingAddressLine1",
          addresses.billing_address_line1 || ""
        );
        body.append("stripeBillingAddressZip", addresses.billing_address_zip || "");
        body.append(
          "stripeBillingAddressState",
          addresses.billing_address_state || ""
        );
        body.append(
          "stripeBillingAddressCity",
          addresses.billing_address_city || ""
        );
        body.append(
          "stripeBillingAddressCountry",
          addresses.billing_address_country || ""
        );
        body.append(
          "stripeBillingAddressCountryCode",
          addresses.billing_address_country_code || ""
        );
    
        body.append("stripeShippingName", addresses.shipping_name || "");
        body.append(
          "stripeShippingAddressLine1",
          addresses.shipping_address_line1 || ""
        );
        body.append(
          "stripeShippingAddressZip",
          addresses.shipping_address_zip || ""
        );
        body.append(
          "stripeShippingAddressState",
          addresses.shipping_address_state || ""
        );
        body.append(
          "stripeShippingAddressCity",
          addresses.shipping_address_city || ""
        );
        body.append(
          "stripeShippingAddressCountry",
          addresses.shipping_address_country || ""
        );
        body.append(
          "stripeShippingAddressCountryCode",
          addresses.shipping_address_country_code || ""
        );
        
        this.props.stripePayment(body,(data) => {
            console.log("DATA");
            console.log(data);
            window.location.reload();
        })
      };

      cancelSubscription = (e) => {
        this.props.cancelSubscription({}, (data) => {
            console.log(data);
            window.location.reload();
        });
      }

      connectStripe = (e) => {
        this.setState({loading:true, stripeBtnState: true});
        this.props.stripeConnect({success: window.location.href+"/?verification=success", error: window.location.href+"/?verification=error"}, (data)=>{
            if (data.url){
                window.location.href = data.url;
                // var win = window.open(data.url, "_blank");
                // win.focus();
                // this.setState({loading:true, stripeBtnState: true});
            }
        })
      }

      onPurchase = (e, title, price_id) => {
        this.setState({btnState: true});
        this.props.stripeGetPaymentLink({"price_id":price_id}, (data)=> {
            if(data.url){
                window.location.href = data.url;
                // var win = window.open(data.url);
                // win.focus();
                this.setState({btnState: false});
            } else {
                this.setState({btnState: false});
            }
        });
      }

      deleteConfirmationModalClose = (action) => {
        this.state.deleteConfirmationModal = {
          show: false,
          title: "",
          body: "",
          type: "",
        };
        if(action === "Yes"){
            this.props.cancelSubscription({}, (data) => {
                window.location.reload();
            });
        } else {
            this.state.btnState = false;
            this.setState(this.state);
        }
      }

      onCancel = (e, title) => {
        this.state.deleteConfirmationModal = {
            show: true,
            title: "Cancelling Subscription",
            body: "Are you sure, you want to cancel your current subscription?",
            type: "danger"
        }
        this.state.btnState = true;
        this.setState(this.state);
      }

    render = () => {
        const { user} = this.props;
        const { product, plans, stripeAccount } = this.state;
        let accountVerifyLabel = "Connect your stripe account";
        if(user && user.stripeOnboarded == "1"){
            accountVerifyLabel = `Account Verified (id: ${user.stripeConnectId})`;
        } else if(user && user.stripeConnectId){
            accountVerifyLabel = `Verify your account (id: ${user.stripeConnectId})`;
        }
        console.log(stripeAccount.requirements);
        let newPlans = _.merge([], plans);
        const requirementsToBeFilledMap = {
            "business_profile.mcc": "Business Category",
            "business_profile.url": "Business Website",
            "external_account": "External Account",
            "individual.address.city": "City",
            "individual.address.line1": "Address Line 1",
            "individual.address.postal_code": "Postal Code",
            "individual.address.state": "State",
            "individual.dob.day": "Date of Birth (Day)",
            "individual.dob.month": "Date of Birth (Month)",
            "individual.dob.year": "Date of Birth (Year)",
            "individual.email": "Email",
            "individual.first_name": "First Name",
            "individual.id_number": "ID Number",
            "individual.last_name": "Last Name",
            "individual.phone": "Phone",
            "tos_acceptance.date": "Date of Acceptance of Terms of Service",
            "tos_acceptance.ip": "IP Address at Acceptance of Terms of Service",
            "individual.ssn_last_4": "Social Security Last 4 Digits",
            "individual.verification.document": "Incorrect/Inaccurate documents provided"
          };
        return (
            <div>
                {this.state.loading && <Spinner />}
                <div className="scope-plans-heading">
                    <div className="scope-plans-title"> Plans </div>
                </div>
                <hr/>
                <div className="row plan-page pt-3">
                    <div className='container'>
                        <div className="col-lg-12 grid-margin">
                            { plans.length > 0 ? 
                            <div className="row">
                                <div className='col-md-4 plan-column'>
                                    <Plans
                                        className={`plans-instance free ${this.state.purchaseDate != null && 'increaseHeight-'+product}`}
                                        buttetPoints={["5 Reports", "5 Reports Remaining", "Life Time"]}
                                        planTitle="Free"
                                        planPrice="$0"
                                        planDuration="0"
                                        active={product === "Free"}
                                        />
                                </div>
                                    {newPlans.reverse().map((plan, index) => {
                                        let typevalue = "One Time";
                                        if(plan.product.metadata.type === "subscription"){
                                            typevalue = plan.product.metadata.duration.toLowerCase().replace(/\b[a-z]/g, (letter) => {
                                                return letter.toUpperCase();
                                            });
                                        }
                                        let buttonPoints = [
                                            "Unlimited Reports",
                                            "No hidden fees",
                                            "Never pay for updates",
                                            "Cancel anytime"
                                        ];
                                        if(plan.product.name === "Annual"){
                                            buttonPoints.unshift("2 months Free!")
                                        }
                                        return (
                                            <div className='col-md-4 plan-column'>
                                            <Plans
                                                className={`plans-instance ${plan.product.name.toLowerCase()} ${this.state.purchaseDate != null && 'increaseHeight-'+product}`}
                                                buttetPoints={buttonPoints}
                                                planTitle={plan.product.name}
                                                planPrice={plan.product.metadata.price}
                                                planDuration={typevalue}
                                                active={product === plan.product.name}
                                                bestValue={plan.product.name === "Annual"}
                                                displayButton={true}
                                                buttonType={product === plan.product.name && !this.state.cancelAtEnd ? "cancel" : "purchase"}
                                                onPurchase={this.onPurchase}
                                                price_id={plan.id}
                                                onCancel={this.onCancel}
                                                status={this.state}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            : "" }
                            </div>
                        </div>
                    </div>
                    <hr className='mt-0 mb-0'/>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card stripe-strip'>
                                    <div className='row stripe-strip-row'>
                                        <div className='col-md-7'>
                                            <div className='stripe-icon-label'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                    <g clipPath="url(#clip0_12650_9803)">
                                                        <path d="M9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5Z" fill="#635BFF"/>
                                                        <path d="M8.15622 7.10054C8.15622 6.59204 8.57337 6.39607 9.26457 6.39607C10.3929 6.41988 11.5001 6.70573 12.4989 7.23105V4.16384C11.4701 3.75866 10.3728 3.55561 9.26705 3.56579C6.6197 3.56579 4.8584 4.94842 4.8584 7.25714C4.8584 10.8571 9.8147 10.2834 9.8147 11.835C9.8147 12.4348 9.29247 12.6306 8.5628 12.6306C7.48055 12.6306 6.09725 12.1876 5.00195 11.5859V14.6909C6.12632 15.1772 7.33779 15.4298 8.5628 15.4334C11.2756 15.4334 13.1404 14.0899 13.1404 11.7549C13.1258 7.86937 8.15622 8.55989 8.15622 7.09987V7.10054Z" fill="white"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_12650_9803">
                                                        <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="stripe-label">Stripe</span>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='stripe-action-button'>
                                                <Button
                                                    className="stripe-button-instance"
                                                    label={accountVerifyLabel}
                                                    showLeftIcon={false}
                                                    showRightIcon={false}
                                                    size="large"
                                                    state="default"
                                                    variant="filled"
                                                    type="primary"
                                                    disabled={(user && user.stripeOnboarded == "1") || this.state.stripeBtnState}
                                                    clickEvent={(e) => this.connectStripe(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { stripeAccount && stripeAccount.id ?
                        <div className='container'>
                            <div className='card stripe-verification-card'>
                                <div className='status-label'>Status</div>
                                <div className='row mt-4'>
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Charges Enabled
                                        </div>
                                        <div className={`status-body text-${stripeAccount.charges_enabled ? 'green' : 'orange'}`}>
                                            {stripeAccount.charges_enabled ? "Enabled" : "Disabled"}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Card Payments
                                        </div>
                                        <div className={`status-body text-${stripeAccount.capabilities.card_payments === 'active' ? 'green' : 'orange'}`}>
                                            {stripeAccount.capabilities.card_payments}
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Details Submitted
                                        </div>
                                        <div className={`status-body text-${stripeAccount.details_submitted ? 'green' : 'orange'}`}>
                                            {stripeAccount.details_submitted ? "YES" : "NO (Please verify your account)"}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Email
                                        </div>
                                        <div className='status-body'>
                                            {stripeAccount.email ? stripeAccount.email : user.email}
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-12'>
                                        <div className='status-head'>
                                            Payouts
                                        </div>
                                        <div className={`status-body text-${stripeAccount.payouts_enabled ? 'green' : 'orange'}`}>
                                            {stripeAccount.payouts_enabled ? "Enabled" : "Disabled"}
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                { stripeAccount.requirements.currently_due.length > 0 &&
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Requirements to be filled
                                        </div>
                                        <div className='status-body'>
                                            <ul key={'l1'}>
                                            {stripeAccount.requirements.currently_due.map((requirement) => {
                                                if(requirementsToBeFilledMap[requirement]){
                                                    return(<li key={requirement}>{requirementsToBeFilledMap[requirement]}</li>);
                                                } else {
                                                    return(<li key={requirement}>{requirement}</li>);
                                                }
                                            })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                { stripeAccount.requirements.past_due && stripeAccount.requirements.past_due.length > 0 &&
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Past Dues (Requirments)
                                        </div>
                                        <div className='status-body'>
                                            <ul key={'l1'}>
                                            {stripeAccount.requirements.past_due.map((due) => {
                                                if(requirementsToBeFilledMap[due]){
                                                    return(<li key={due}>{requirementsToBeFilledMap[due]}</li>);
                                                } else {
                                                    return(<li key={due}>{due}</li>);
                                                }
                                            })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                { stripeAccount.requirements.pending_verification && stripeAccount.requirements.pending_verification.length > 0 &&
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Verification (Pending)
                                        </div>
                                        <div className='status-body'>
                                            <ul key={'l2'}>
                                            {stripeAccount.requirements.pending_verification.map((pending) => {
                                               if(requirementsToBeFilledMap[pending]){
                                                return(<li key={pending}>{requirementsToBeFilledMap[pending]}</li>);
                                            } else {
                                                return(<li key={pending}>{pending}</li>);
                                            }
                                            })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                { stripeAccount.requirements.disabled_reason && stripeAccount.requirements.disabled_reason.length > 0 &&
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Account Disabled (Reason)
                                        </div>
                                        <div className='status-body text-orange'>
                                            {stripeAccount.requirements.disabled_reason}
                                        </div>
                                    </div>
                                }
                                { stripeAccount.requirements.errors && stripeAccount.requirements.errors.length > 0 &&
                                    <div className='col-md-6'>
                                        <div className='status-head'>
                                            Errors
                                        </div>
                                        <div className='status-body'>
                                            <ul key={'l3'}>
                                            {stripeAccount.requirements.errors.map((error) => {
                                                if(typeof error === "object"){
                                                    if(error.reason){
                                                        return (<li key={error}>{error.reason}</li>);
                                                    } else {
                                                        return (<li key={"errorx"}>Please verify the information you have shared with stripe</li>);
                                                    }
                                                } else {
                                                    return(<li key={error}>{error}</li>);
                                                }
                                            })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                        : "" }
                        <DeleteConfirmationModal modalSettings={this.state.deleteConfirmationModal} close={this.deleteConfirmationModalClose} />
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        // rowsPerPage: state.appData.rowsPerPage,
        // users: state.appData.users
        user: state.authData.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        cancelSubscription: (object, cb) => dispatch(cancelSubscription(object, cb)),
        stripePayment: (object, cb) => dispatch(stripePayment(object, cb)),
        getUserPlan: (object, cb) => dispatch(getUserPlan(object, cb)),
        getPlans: (object, cb) => dispatch(getPlans(object, cb)),
        stripeConnect: (object, cb) => dispatch(stripeConnect(object, cb)),
        stripeAccountRetrieve: (object, cb) => dispatch(stripeAccountRetrieve(object, cb)),
        stripeGetPaymentLink: (object, cb) => dispatch(stripeGetPaymentLink(object, cb)),
        getCurrentUser: (object, cb) => dispatch(getCurrentUser(object, cb))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanListing);
