import React, { Component } from 'react';
import { connect } from "react-redux";
import { SignUpUser } from '../../redux/apiCalls/authData';
import { LoginLogo, SoicalApple, SoicalFB, SoicalGoogle } from '../icons/Icons';
import Spinner from '../shared/Spinner';
import { Form } from 'react-bootstrap';
import { validateEmail, validatePassword } from "./../../helpers/helpers";
import { Link } from 'react-router-dom';
import _ from "lodash";

export class Signup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirm_password: '',
      loading:false,
      isSubmitting: true,
      showPassword: true,
      showConfirmPassword: true,
			errors:{}
    };
  }

  componentDidMount = async () => {
    // console.log("Signup -- componentDidMount ::", this.props);
		// this.validate(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // console.log("Signup -- componentWillReceiveProps ::", nextProps);
		// this.validate(nextProps);
  }

	validate(nextProps){
		let errors ={};
		let isValid = true;
		
		if(nextProps.password){
			errors.password = validatePassword(nextProps.password.trim());
			if(!_.isNil(errors.email)) isValid = true;
		}
		if(nextProps.confirm_password){
			errors.confirm_password = validatePassword(nextProps.confirm_password.trim());
			if(!_.isNil(errors.email)) isValid = true;
		}
		if (nextProps.password == '' && nextProps.confirm_password == '') {
			isValid = true;
		} else {
			if (nextProps.password != nextProps.confirm_password) {
				isValid = true;
				errors.password =  "Confirm password is Not Matched";
			} else if(nextProps.password == nextProps.confirm_password){
				isValid = false;
				errors.password =  "";
			}
		}
		if(nextProps.email){
			errors.email = validateEmail(nextProps.email.trim());
			if(!_.isNil(errors.email)) isValid = true;
		} else {
      isValid = true;
    }

		this.setState({errors:errors, isSubmitting:isValid});
	}

  toggleSwitch = (e) => {
    this.state.showPassword = !this.state.showPassword;
    this.setState(this.state);
  }
  toggleConfirmSwitch = (e) => {
    this.state.showConfirmPassword = !this.state.showConfirmPassword;
    this.setState(this.state);
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
    setTimeout(
      function() {
          this.validate(this.state);
      }
      .bind(this),
      500
    );
  }
  handleSubmit = () => {
    this.setState({loading:true});
    if(!this.validate(this.state)){
      let data = {
          email: this.state.email,
          password: this.state.password,
          password_confirm: this.state.confirm_password,
      }
      this.props.SignUpUser(data, (status) => {
        this.setState({loading:false});
        console.log('SignUpUser:: ', status, this.props.user);
          if(status){
            
          } else {
            if(this.props.user.userType === "admin" || this.props.user.userType === "developer"){
              window.location = "/templates";
            } else {
              window.location = "/plans";
            }
          }
      });
    }
    this.setState({loading:false});
  }
   
  render() {
    const { email, password, confirm_password, isSubmitting } = this.state;
    return (
      <div id="login-detail">
      <div className="login-container splash">
          <div className="width60">
            {this.state.loading ? <Spinner/> : <></>}
          </div> 
        <div className="content-container">
          <div className="welcome-back-login">
            <LoginLogo className="img-logo" />
          </div>
          <div className="already-account-container">
                <div className="already-account">if you already have an account you can</div>
								<Link to="/login" className="login-here">Login here!</Link>
          </div>
        </div>
        <div className="auth-form-splash-signup">      
          <div className="scope-form">
            <div className="signup-title">Sign up</div>
              <div className="form-container">
                  <div className="step-indicator">
                      {/* <div className="step-icon">
                          <div className="step-number">1</div>
                      </div>
                      <div className="step-line"></div>
                      <div className="step-line"></div>
                      <div className="step-line"></div>
                      <div className="step-line"></div> */}
                  </div>
                  <div className="account-creation">
                      <div className="account-text">
                          <div className="account account-title">Let’s make your account</div>
                          <div className="account account-subtitle">to begin your Inspection journey</div>
                      </div>
                      <br/>
                      <div className="set-your-emailpass">Set your email & password</div>
                      <br/>
                      <div className="form-label">
                          <div className="label-text">Email</div>
                      </div>
											<Form.Group className="step1-email-box">
												<input className="form-control email-field"  
												id="email"
												type="email"
												name="email"
												placeholder="Enter email" 
												value={email}
												onChange={this.handleChange}
												/>
											</Form.Group>
                      <div className="form-label">
                          <div className="label-text">Password</div>
                      </div>
											<Form.Group className="step1-password-box">
											<div className="input-group">
												<input className="form-control password-field "  
												id="password"
												type={this.state.showPassword ? "password" : "text"} 
												name="password" 
												placeholder="Set your password" 
												value={password}
												onChange={this.handleChange}
												/>
												<div className="input-group-append">
													<span className="input-group-text pass-eye"> <i onClick={this.toggleSwitch} className={this.state.showPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}></i></span>
												</div>
											</div>
											</Form.Group>
                      <div className="form-label">
                          <div className="label-text">Confirm Password</div>
                      </div>
											<Form.Group className="step1-confirm-password-box">
												<div className="input-group">
													<input className="form-control password-field" 
													id="confirm_password"
													type={this.state.showConfirmPassword ? "password" : "text"} 
													placeholder="Re-type password" 
													value={confirm_password}
													onChange={this.handleChange}
													name="confirm_password"
													/>
													<div className="input-group-append">
														<span className="input-group-text pass-eye"> <i onClick={this.toggleConfirmSwitch} className={this.state.showPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}></i></span>
													</div>
												</div>
												<div className="text-danger">{this.state.errors.email}</div>
												<div className="text-danger">{this.state.errors.password}</div>
                  			<div className="text-danger">{this.state.errors.confirm_password}</div>
											</Form.Group>
                      <br />
                      <br />
                      <br />
                  </div>
              </div>
          </div>
            <div className="form-actions">
                <div className="action-buttons">
                <button type="submit" disabled={isSubmitting} onClick={this.handleSubmit} className="next-button">
                  <div  className="button-text">Signup</div>
                </button>
                </div>
            </div>
        </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.authData.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
      SignUpUser: (template, cb) => dispatch(SignUpUser(template, cb))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup)
