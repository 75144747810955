import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";

function ReactSwitch(props) {
  return (
    <>
      <Col md={props.col}>
        {/* <Form.Group> */}
          <Form.Check
            type="switch"
            id={props.id}
            name={props.name}
            // label={props.label}
            checked={props.checked}
            onChange={props.onChange}
            title={props.title}
          />
        {/* </Form.Group> */}
      </Col>
    </>
  )
}

export class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:props.field,
      id:props.id,
      uid:props.uid,
      index:props.index,
      on:props.switchOnTitle,
      off:props.switchOffTitle
    };
  }
  onAction = (newValue) => {
    this.props.onSwitchAction(this.state.id, newValue, this.state.index);
    this.setState({value: newValue});
  }
  render() {
    let title= "Switch On";
    this.state.value == true ? title= "Switch Off" : title="Switch On";
    if(this.state.value && this.state.on){
      title = this.state.on
    } else if(!this.state.value && this.state.off) {
      title = this.state.off
    }
    return (
      <ReactSwitch
      name={`switch[${this.state.uid}]`}
      label=""
      id={`switch[${this.state.uid}]`}
      checked={this.state.value}
      onChange={() => this.onAction(!this.state.value)
      }
      title={title}
    />
    );
  }
}  
Switch.propTypes = {
  field: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onSwitchAction: PropTypes.func.isRequired,
};
