/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import Modal from 'react-bootstrap/Modal';
import {Button as Btn} from "../../components/button/button";
import Form from 'react-bootstrap/Form';


class ChoiceItemEditModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalSettings: {
            show: false,
            title: "",
            item: {},
            index: null,
            value: ""
        },
        saveBtnDisabled: false
    }
  }
  componentDidMount = async () => {
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.modalSettings = nextProps.modalSettings;
    this.state.saveBtnDisabled = false;
    this.setState( this.state );
  }

  onChange = (e) => {
    this.state.modalSettings.value = e.target.value;
    if(this.state.modalSettings.value.trim().length === 0){
      this.state.saveBtnDisabled = true;
    } else {
      this.state.saveBtnDisabled = false;
    }
    
    this.setState(this.state);
  }

  render = () => {
    const {modalSettings} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            // onHide={() => this.props.close(item)}
            dialogClassName="modal-90w itemEdit modal-scope"
            aria-labelledby="template-item-edit"
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
            <Modal.Title id="template-item-edit">
                Editing {modalSettings.title}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Form.Control
                    type="text"
                    placeholder="Field Title"
                    value={modalSettings.value}
                    onChange={(e) => this.onChange(e)}
                    />
            </Modal.Body>
            <Modal.Footer>
            <Btn className={`template-item-edit`}
                label={`Cancel`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="ghost"
                type="primary"
                clickEvent={(e) => this.props.close("Cancel", modalSettings.value, modalSettings.index)} />
                <Btn className={`template-item-edit`}
                label={`Save`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="filled"
                type="primary"
                disabled={this.state.saveBtnDisabled}
                clickEvent={(e) => this.props.close("Save", modalSettings.value, modalSettings.index)} />
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default ChoiceItemEditModal;