/* eslint-disable eqeqeq */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
import React from "react";
// @material-ui/core components
// core components
import _ from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import toast from 'react-hot-toast';
import { connect } from "react-redux";
import {
    destroyTemplate,
    getAllTemplates,
    getItems,
    getTemplateByID,
    updateTemplate,
} from "../../../redux/apiCalls/appData";
import { Button } from "../../components/button/button";
import List from "../../components/list/list.jsx";
import ListHeader from "../../components/list/listHeaders.jsx";
import AddItemModal from "../../components/modal/addItemModal.jsx";
import AddRefItemModal from "../../components/modal/addRefItemModal.jsx";
import AddSectionModal from "../../components/modal/addSectionModal.jsx";
import ItemEditModal from "../../components/modal/itemEditModal.jsx";
import SectionEditModal from "../../components/modal/sectionEditModal.jsx";
import SectionHeaderFooterEditModal from "../../components/modal/sectionHeaderFooterEditModal.jsx";
import TemplateOptionsModal from "../../components/modal/templateOptions.jsx";
import { ArrowIcon, DragIcon, EditBlueIcon, TemplateOptionIcon, InformationIconWhite } from '../../icons/Icons';
import Spinner from "../../shared/Spinner.js";
class TemplatePage extends React.Component {
    constructor(props) {
        super(props);
        this.textfieldRef = React.createRef();
        this.state = {
            template: {
                templateName: "Template Name",
                templateDescription: "Template Description",
                showTableOfContents: false,
                showChoiceTypeDefaultOptions: true,
                adminPublished: false,
                listWithUserTemplates: false,
                startEachSectionOnNewPageInPdf: false,
                inspectorAppearanceName: "Inspected By: ",
                printedReportTitle: "Report Title: ",
                preparedForDescription: "Prepared By: ",
                propertyAddressDescription: "",
                inspectionDateAppearance: "",
                verticallyCenterTitlePageContent: false,
                scaleThePropertyPhotoLarge: false,
                reportSummaryOptions: {
                    options: {
                        endOfReport: false,
                        beginningOfReport: true,
                        noReportSummary: false,
                    },
                    includeImagesInTheReportSummary: true,
                    includeOriginalCommentNumberInTheReportSummary: true,
                    summaryHeader: "",
                    summaryFooter: "",
                },
                conditionRatingOptions: {
                    enableConditionRatings: true,
                    conditionalOptions: ["Good", "Fair", "Poor"],
                    requireConditionRatingsForItemsToBeConsideredComplete: true,
                },
                reportHeader: "",
                reportFooter: "",
                pageFooter: "",
                defaultTemplate: true,
                templateComments: [],
                templateContent: [
                    {
                        sectionLabel: "general",
                        visibility: true,
                        comments: [],
                        items: [],
                        header: "",
                        footer: "",
                        subSections: [],
                    },
                ],
                user:{}
            },
            loading: true,
            sectionIndex: 0,
            subSectionIndex: null,
            subSectionSelected: false,
            templateOptions: true,
            templateUpdated: true,
            sectionCollapseState: [],
            itemCollapseState: {
                section: [],
                subSection: [],
            },
            templateHashMap: {},
            pdfTemplate: "",
            templateDialog: {
                label: "",
                maxWidth: "sm",
                value: "",
                title: "",
                description: "",
                field: "",
                parent: false,
                child: false,
                nestedChild: false,
                visibleState: false,
                templateContent: false,
                fieldType: "text",
                templateContentData: {},
                itemEditable: false,
                RefTemplates: [],
            },
            user: {},
            allTemplates:[],
            selectedSectionIndex: 0,
            selectedSubSectionIndex: null,
            selectedItemIndex: null,
            templateNameEditState: false,
            templateOptionModalSettings:{
                show:false
            },
            itemEditModalSettings:{
                show:false,
                item:{},
                index: null
            },
            sectionEditModalSettings: {
                show:false,
                section:{},
                index: null
            },
            addSectionModalSettings: {
                show:false,
                title: "",
                value: "",
                index: null,
                type:"",
                sectionName: ""
            },
            addItemModalSettings: {
                show:false,
                listOfItems: [],
                duplicates: [],
                selectedItem: {},
                view:"menu"
            },
            addRefItemModalSettings: {
                show:false,
                templates:[],
                itemIndex:null,
                ref:{},
                currentTempId: "",
                view:"menu",
                item:{}
            },
            sectionHeaderFooterModalSettings: {
                show: false,
                title: "",
                section: {},
                index: null,
                value: "",
                field: "",
            },
            availableItemTypes: [],
            itemCollapsebleState:[],
            currentTempId:"",
            oldTemplateState: {},
            fixedNotificationDisplayed: false
        };
        this.load = true;
    }
    componentDidMount = async () => {
        this.timer = null;
        let template_id = this.props.match.params.id;
        this.props.getItems({}, (data)=> {
            this.state.availableItemTypes = data;
        });
        this.state.allTemplates = this.props.templates;
        if (
            template_id &&
            this.props.match.params.id != undefined &&
            this.props.match.params.id.includes("clone=")
        ) {
            template_id = template_id.substring(6);
        }
        if (
            template_id &&
            !_.isNil(this.props.templates) &&
            !_.isEmpty(this.props.templates)
        ) {
            this.state.template = this.props.templates.find(
                (template) => template.id == template_id
            );
            if(this.state.template === "" || this.state.template === null || this.state.template === undefined){
                console.log("IF");
                this.props.getTemplateByID(template_id, (template) => {
                    if (template) {
                        this.state.template = template;
                        this.state.currentTempId = template.id;
                        this.state.itemCollapsebleState = this.choiceTypeItemIndexes(this.state.template.templateContent);
                        if (this.props.match.params.id.includes("clone=")) {
                            delete this.state.template.id;
                            this.state.template.user = this.props.user.id;
                        }
                        this.setState({ template: this.state.template, loading: false });
                    }
                });
            } else {
                this.state.currentTempId = template_id;
                this.state.itemCollapsebleState = this.choiceTypeItemIndexes(this.state.template.templateContent);
                if (this.props.match.params.id.includes("clone=")) {
                    delete this.state.template.id;
                    this.state.template.user = this.props.user.id;
                }
                this.setState({ template: this.state.template, loading: false });
            }
            // this.state.oldTemplateState = _.merge({}, this.state.template);
            // this.setState({ template: this.state.template, loading: false });
        } else if (template_id) {
            this.props.getTemplateByID(template_id, (template) => {
                if (template) {
                    this.state.template = template;
                    this.setState({
                        template: this.state.template,
                        loading: false,
                    });
                    this.state.itemCollapsebleState = this.choiceTypeItemIndexes(template.templateContent);
                }
            });
        } else {
            this.setState({ loading: false });
        }
        // this.props.getAllTemplates(
        //     {
        //         sort: this.state.sort,
        //         page: this.state.page,
        //         rowsPerPage: 100,
        //         defaultTemplate: true,
        //         baseTemplate: true,
        //     },
        //     (data) => {
        //         this.state.templateDialog.RefTemplates = this.props.templates;
        //         // this.state.allTemplates = this.props.templates;
        //         this.setState(this.state);
        //     }
        // );
            let session = this.props.user;
            this.state.user = session;
            if (
                template_id &&
                this.props.match.params.id != undefined &&
                this.props.match.params.id.includes("clone=")
            ) {
                this.state.template.user = session.id;
            }
            this.state.loading = false;
            this.state.oldTemplateState = _.merge({}, this.state.template);
            this.updateState(this.state,false,false);
            if(this.state.template.templateType == "fixed" && this.props.user.userType != "developer" && this.state.template.user != this.props.user.id ){
                if(!this.state.fixedNotificationDisplayed && !this.props.match.params.id.includes("clone=")){
                    toast.error("Fixed type templates are not editable, but you can clone (make a copy) and edit the template.", {position: 'bottom-center', duration:15000, style: {maxWidth:"80%"}, icon: <InformationIconWhite/>});
                    this.setState({fixedNotificationDisplayed:true});
                }
            }
    };

    componentWillUnmount = () => {
        toast.dismiss();
    }

    onDragEnd = (path, result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        // console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`)
        let array = [];
        if (path.items) {
            if (this.state.subSectionSelected) {
                array = this.state.template.templateContent[path.sectionIndex][
                    "subSections"
                ][this.state.subSectionIndex][path.head];
                this.state.itemCollapseState.subSection.push(
                    result.destination.index
                );
                this.state.itemCollapseState.subSection = this.state.itemCollapseState.subSection.filter(
                    function (value, i, arr) {
                        return value != result.source.index;
                    }
                );
            } else {
                array = this.state.template.templateContent[path.sectionIndex][
                    path.head
                ];
                this.state.itemCollapseState.section.push(
                    result.destination.index
                );
                this.state.itemCollapseState.section = this.state.itemCollapseState.section.filter(
                    function (value, i, arr) {
                        return value != result.source.index;
                    }
                );
            }
        } else if(path.sections) {
            array = this.state.template.templateContent;
            this.state.sectionCollapseState.push(result.destination.index);
            this.state.sectionCollapseState = this.state.sectionCollapseState.filter(
                function (value, i, arr) {
                    return value != result.source.index;
                }
            );
        } else if(path.subsections){
            array = this.state.template.templateContent[path.sectionIndex]["subSections"]
        }
        array = reorder(array, result.source.index, result.destination.index);
        if (path.items) {
            if (this.state.subSectionSelected) {
                this.state.template.templateContent[path.sectionIndex][
                    "subSections"
                ][this.state.subSectionIndex][path.head] = array;
            } else {
                this.state.template.templateContent[path.sectionIndex][
                    path.head
                ] = array;
            }
        } else if(path.sections) {
            this.state.sectionIndex = result.destination.index;
            this.state.selectedSectionIndex = result.destination.index;
            this.state.subSectionIndex = null;
            this.state.template.templateContent = array;
            this.state.subSectionSelected = false;
            this.state.selectedSubSectionIndex = null;
        } else if(path.subsections){
            this.state.subSectionIndex = result.destination.index;
            this.state.selectedSubSectionIndex = result.destination.index;
            this.state.subSectionSelected = true;
            // this.state.subSectionIndex = result.destination.index;
            // this.state.subSectionSelected = true;
            this.state.template.templateContent[path.sectionIndex]['subSections'] = array;
        }
        // this.state.itemCollapsebleState = this.choiceTypeItemIndexes(this.state.template.templateContent);

        // For keeping the section selected which is being dragged only if sections are dragged
        // if (!path.items) this.state.sectionIndex = result.destination.index;
        // items = sortNumberSorting(items);
        // this.state.templateUpdated = false;
        // this.setState(this.state);
        this.updateState(this.state, true);
    };

    componentDidUpdate = () => {};

    onChangeSection = (data) => {
        if (
            this.state.sectionIndex != data.sectionIndex ||
            this.state.subSectionIndex != data.subSectionIndex
        ) {
            this.state.itemCollapseState.section = [];
            this.state.itemCollapseState.subSection = [];
        }
        this.state.templateOptions = data.templateOptions;
        this.state.sectionIndex = data.sectionIndex;
        this.state.subSectionIndex = data.subSectionIndex;
        this.state.subSectionSelected = data.subSectionSelected;
        // this.setState(this.state);
        this.updateState(this.state, false, this.state.templateUpdated);
    };

    collapseStateHandler = (index, item) => {
        if (item) {
            if (this.state.subSectionSelected) {
                if (!this.state.itemCollapseState.subSection.includes(index)) {
                    this.state.itemCollapseState.subSection.push(index);
                } else {
                    this.state.itemCollapseState.subSection = this.state.itemCollapseState.subSection.filter(
                        (value, i) => {
                            return value != index;
                        }
                    );
                }
            } else {
                if (!this.state.itemCollapseState.section.includes(index)) {
                    this.state.itemCollapseState.section.push(index);
                } else {
                    this.state.itemCollapseState.section = this.state.itemCollapseState.section.filter(
                        (value, i) => {
                            return value != index;
                        }
                    );
                }
            }
        } else {
            if (!this.state.sectionCollapseState.includes(index)) {
                this.state.sectionCollapseState.push(index);
            } else {
                // this.state.sectionCollapseState.splice(index,1);
                this.state.sectionCollapseState = this.state.sectionCollapseState.filter(
                    function (value, i, arr) {
                        return value != index;
                    }
                );
            }
        }
        // this.setState(this.state);
        this.updateState(this.state, false, false);
    };

    visibilityHandler = (index, type='item') => {
        if (type === 'item') {
            if (this.state.subSectionSelected) {
                this.state.template.templateContent[this.state.sectionIndex][
                    "subSections"
                ][this.state.subSectionIndex]["items"][index][
                    "visibility"
                ] = this.state.template.templateContent[
                    this.state.sectionIndex
                ]["subSections"][this.state.subSectionIndex]["items"][index][
                    "visibility"
                ]
                    ? false
                    : true;
            } else {
                this.state.template.templateContent[this.state.sectionIndex][
                    "items"
                ][index]["visibility"] = this.state.template.templateContent[
                    this.state.sectionIndex
                ]["items"][index]["visibility"]
                    ? false
                    : true;
            }
        } else if(type === 'Section') {
            this.state.template.templateContent[index]["visibility"] = !this.state.template.templateContent[index]["visibility"]
        } else if(type === "Subsections"){
            this.state.template.templateContent[this.state.sectionIndex][
                "subSections"
            ][index]["visibility"] = !this.state.template.templateContent[this.state.sectionIndex][
                "subSections"
            ][index]["visibility"];
        }
        // this.state.templateUpdated = false;
        // this.setState(this.state);
        this.updateState(this.state, false);
    };

    toggleCheck = (data) => {
        // console.log({...data});
        if (data.templateContent) {
            let {
                sectionName,
                itemIndex,
                head,
                sectionIndex,
                key,
                subSectionSelected,
                subSectionIndex,
            } = data.templateContentData;
            if (data.templateContentData.choices) {
                if (subSectionSelected) {
                    this.state.template.templateContent[sectionIndex][
                        "subSections"
                    ][subSectionIndex][head][itemIndex][key] = data.value;
                } else {
                    this.state.template.templateContent[sectionIndex][head][
                        itemIndex
                    ][key] = data.value;
                }
            }
        } else {
            if (
                (data.parent != "" || data.parent != false) &&
                (data.child != "" || data.child != false) &&
                (data.nestedChild != "" || data.nestedChild != false)
            ) {
                this.state[data.parent][data.child][data.nestedChild][
                    data.field
                ] = data.value;
            } else if (
                (data.parent != "" || data.parent != false) &&
                (data.child != "" || data.child != false) &&
                data.nestedChild == false
            ) {
                this.state[data.parent][data.child][data.field] = data.value;
            } else if (
                (data.parent != "" || data.parent != false) &&
                data.child == false
            ) {
                this.state[data.parent][data.field] = data.value;
            } else if (data.parent == false && data.child == false) {
                this.state[data.field] = data.value;
            }
        }
        this.state.templateUpdated = false;
        // this.setState(this.state);
        this.updateState(this.state, false, true);
    };
    CheckSummaryOption = (data) => {
        this.state.template.reportSummaryOptions.options.endOfReport = false;
        this.state.template.reportSummaryOptions.options.beginningOfReport = false;
        this.state.template.reportSummaryOptions.options.noReportSummary = false;
        this.state.template.reportSummaryOptions.options[data.field] = true;
        this.state.templateUpdated = false;
        // this.setState(this.state);
        this.updateState(this.state);
    };

    onClickOpenDialog = (options, e) => {
        this.state.templateDialog = {
            ...this.state.templateDialog,
            ...options,
        };
        // this.props.getAllTemplates({sort: this.state.sort, page: this.state.page, rowsPerPage: 100, defaultTemplate: true, baseTemplate: true}, (data) => {
        let template_id = this.props.match.params.id;
        //     // Excluding the current template from the list of RefTemplates
        this.state.templateDialog.RefTemplates = this.props.templates.filter(
            (template) => template.id !== template_id
        );
        //     this.setState(this.state);
        // });
        // console.log(this.props.templates);
        // this.setState(this.state);
        this.updateState(this.state);
    };

    dialogOnClose = (data) => {
        this.state.templateDialog = data;
        // this.setState(this.state);
        this.updateState(this.state);
    };

    dialogOnSave = (data) => {
        // console.log({...data});
        if (data.templateContent) {
            let {
                itemIndex,
                head,
                sectionIndex,
                key,
                subSectionSelected,
                subSectionIndex,
            } = data.templateContentData;
            if (data.templateContentData.choices) {
                if (subSectionSelected) {
                    if (data.templateContentData.choiceIndex < 999) {
                        this.state.template.templateContent[sectionIndex][
                            "subSections"
                        ][subSectionIndex][head][itemIndex]["itemOptions"][
                            data.templateContentData.choiceIndex
                        ] = data.value;
                    } else {
                        this.state.template.templateContent[sectionIndex][
                            "subSections"
                        ][subSectionIndex][head][itemIndex]["itemOptions"].push(
                            data.value
                        );
                    }
                } else {
                    if (data.templateContentData.choiceIndex < 999) {
                        this.state.template.templateContent[sectionIndex][head][
                            itemIndex
                        ]["itemOptions"][data.templateContentData.choiceIndex] =
                            data.value;
                    } else {
                        this.state.template.templateContent[sectionIndex][head][
                            itemIndex
                        ]["itemOptions"].push(data.value);
                    }
                }
            } else if (data.fieldType == "section") {
                if (sectionIndex < 999) {
                    this.state.template.templateContent[sectionIndex][key] =
                        data.title;
                } else {
                    this.state.template.templateContent.push({
                        sectionLabel: data.title,
                        comments: [],
                        items: [],
                        header: "",
                        footer: "",
                        subSections: [],
                    });
                }
            } else if (data.fieldType == "subSection") {
                if (itemIndex < 999) {
                    this.state.template.templateContent[sectionIndex][
                        "subSections"
                    ][itemIndex][key] = data.title;
                    if (this.state.template.pdfTemplate == "texas") {
                        this.state.template.templateContent[sectionIndex][
                            "subSections"
                        ][itemIndex].items[0].itemLabel = data.title;
                    }
                } else {
                    if (
                        !this.state.template.templateContent[sectionIndex][
                            "subSections"
                        ]
                    ) {
                        this.state.template.templateContent[
                            sectionIndex
                        ].subSections = [];
                    }
                    let defaultItem = [];
                    if (this.state.template.pdfTemplate == "texas") {
                        defaultItem = [
                            {
                                allowMultipleSelection: true,
                                visibility: true,
                                defaultOptions: [
                                    "Not Present",
                                    "Not Inspected",
                                ],
                                itemSelected: "Good",
                                uid: "item-LxtB7gSBXq224XtauWsb",
                                itemType: "choice",
                                itemOptions: [
                                    "Inspected",
                                    "Not Inspected",
                                    "Not Present",
                                    "Deficient",
                                ],
                                itemLabel: data.title,
                                editable: false,
                                id: "2.1.1.0.0",
                            },
                        ];
                    }
                    this.state.template.templateContent[sectionIndex][
                        "subSections"
                    ].push({
                        sectionLabel: data.title,
                        comments: [],
                        items: defaultItem,
                        header: "",
                        footer: "",
                    });
                }
            } else if (data.fieldType == "text" && data.field == "headers") {
                if (subSectionSelected) {
                    this.state.template.templateContent[sectionIndex][
                        "subSections"
                    ][subSectionIndex][key] = data.value;
                } else {
                    this.state.template.templateContent[sectionIndex][key] =
                        data.value;
                }
            } else {
                if (subSectionSelected) {
                    this.state.template.templateContent[sectionIndex][
                        "subSections"
                    ][subSectionIndex][head][itemIndex][key] = data.value;
                    if (data.itemEditable) {
                        this.state.template.templateContent[sectionIndex][
                            "subSections"
                        ][subSectionIndex][head][itemIndex]["itemLabel"] =
                            data.title;
                        if (data.newTrail && data.itemTrail) {
                            this.state.template.templateContent[sectionIndex][
                                "subSections"
                            ][subSectionIndex][head][itemIndex].itemTrail =
                                data.itemTrail;
                        }
                    }
                } else {
                    this.state.template.templateContent[sectionIndex][head][
                        itemIndex
                    ][key] = data.value;
                    if (data.itemEditable) {
                        this.state.template.templateContent[sectionIndex][head][
                            itemIndex
                        ]["itemLabel"] = data.title;
                        if (data.newTrail && data.itemTrail) {
                            this.state.template.templateContent[sectionIndex][
                                head
                            ][itemIndex].itemTrail = data.itemTrail;
                        }
                    }
                }
            }
        } else {
            if (typeof data.index == "number") {
                if (
                    (data.parent != "" || data.parent != false) &&
                    (data.child != "" || data.child != false) &&
                    (data.nestedChild != "" || data.nestedChild != false)
                ) {
                    if (data.index < 999)
                        this.state[data.parent][data.child][data.nestedChild][
                            data.field
                        ][data.index] = data.value;
                    else
                        this.state[data.parent][data.child][data.nestedChild][
                            data.field
                        ].push(data.value);
                } else if (
                    (data.parent != "" || data.parent != false) &&
                    (data.child != "" || data.child != false) &&
                    data.nestedChild == false
                ) {
                    if (data.index < 999)
                        this.state[data.parent][data.child][data.field][
                            data.index
                        ] = data.value;
                    else
                        this.state[data.parent][data.child][data.field].push(
                            data.value
                        );
                } else if (
                    (data.parent != "" || data.parent != false) &&
                    data.child == false
                ) {
                    if (data.index < 999)
                        this.state[data.parent][data.field][data.index] =
                            data.value;
                    else this.state[data.parent][data.field].push(data.value);
                } else if (data.parent == false && data.child == false) {
                    if (data.index < 999)
                        this.state[data.field][data.index] = data.value;
                    else this.state[data.field].push(data.value);
                }
            } else {
                if (
                    (data.parent != "" || data.parent != false) &&
                    (data.child != "" || data.child != false) &&
                    (data.nestedChild != "" || data.nestedChild != false)
                ) {
                    this.state[data.parent][data.child][data.nestedChild][
                        data.field
                    ] = data.value;
                } else if (
                    (data.parent != "" || data.parent != false) &&
                    (data.child != "" || data.child != false) &&
                    data.nestedChild == false
                ) {
                    this.state[data.parent][data.child][data.field] =
                        data.value;
                } else if (
                    (data.parent != "" || data.parent != false) &&
                    data.child == false
                ) {
                    this.state[data.parent][data.field] = data.value;
                } else if (data.parent == false && data.child == false) {
                    this.state[data.field] = data.value;
                }
            }
        }
        this.state.templateDialog = {
            label: "",
            maxWidth: "sm",
            value: "",
            title: "",
            description: "",
            field: "",
            parent: "",
            child: "",
            visibleState: false,
            fieldType: "text",
            templateContent: false,
            templateContentData: {},
            itemEditable: false,
        };
        // this.state.templateUpdated = false;
        this.setState(this.state, false, true);
    };

    handleEditOptions = (data) => {
        this.onClickOpenDialog(data);
    };

    handleRemoveOptions = (data) => {
        if (data.templateContent) {
            let {
                sectionName,
                itemIndex,
                head,
                sectionIndex,
                key,
                subSectionSelected,
                subSectionIndex,
            } = data.templateContentData;
            if (data.templateContentData.choices) {
                if (subSectionSelected) {
                    let allOptions = this.state.template.templateContent[
                        sectionIndex
                    ]["subSections"][subSectionIndex][head][itemIndex][
                        "itemOptions"
                    ];
                    allOptions = removeTemplate(
                        allOptions,
                        data.templateContentData.choiceIndex
                    );
                    this.state.template.templateContent[sectionIndex][
                        "subSections"
                    ][subSectionIndex][head][itemIndex][
                        "itemOptions"
                    ] = allOptions;
                } else {
                    let allOptions = this.state.template.templateContent[
                        sectionIndex
                    ][head][itemIndex]["itemOptions"];
                    allOptions = removeTemplate(
                        allOptions,
                        data.templateContentData.choiceIndex
                    );
                    this.state.template.templateContent[sectionIndex][head][
                        itemIndex
                    ]["itemOptions"] = allOptions;
                }
            }
            if (data.templateContentData.removeItem) {
                if (subSectionSelected) {
                    let allOptions = this.state.template.templateContent[
                        sectionIndex
                    ]["subSections"][subSectionIndex][head];
                    allOptions = removeTemplate(
                        allOptions,
                        data.templateContentData.itemIndex
                    );
                    this.state.template.templateContent[sectionIndex][
                        "subSections"
                    ][subSectionIndex][head] = allOptions;
                } else {
                    let allOptions = this.state.template.templateContent[
                        sectionIndex
                    ][head];
                    allOptions = removeTemplate(
                        allOptions,
                        data.templateContentData.itemIndex
                    );
                    this.state.template.templateContent[sectionIndex][
                        head
                    ] = allOptions;
                }
            }
            if (data.templateContentData.removeSection) {
                let allOptions = this.state.template.templateContent;
                allOptions = removeTemplate(
                    allOptions,
                    data.templateContentData.sectionIndex
                );
                this.state.template.templateContent = allOptions;
                // To reset template view to template options in order to preventing undefined index selected issue
                this.state.sectionIndex = 0;
                this.state.templateOptions = true;
            }
            if (data.templateContentData.removeSubSection) {
                let allOptions = this.state.template.templateContent[
                    sectionIndex
                ]["subSections"];
                allOptions = removeTemplate(
                    allOptions,
                    data.templateContentData.itemIndex
                );
                this.state.template.templateContent[sectionIndex][
                    "subSections"
                ] = allOptions;
                if (this.state.subSectionSelected)
                    this.state.subSectionSelected = false;
                this.state.subSectionIndex = 0;
            }
        } else {
            if (
                (data.parent != "" || data.parent != false) &&
                (data.child != "" || data.child != false) &&
                (data.nestedChild != "" || data.nestedChild != false)
            ) {
                let allOptions = this.state[data.parent][data.child][
                    data.nestedChild
                ][data.field];
                allOptions = removeTemplate(allOptions, data.index);
                this.state[data.parent][data.child][data.nestedChild][
                    data.field
                ] = allOptions;
            } else if (
                (data.parent != "" || data.parent != false) &&
                (data.child != "" || data.child != false) &&
                data.nestedChild == false
            ) {
                let allOptions = this.state[data.parent][data.child][
                    data.field
                ];
                allOptions = removeTemplate(allOptions, data.index);
                this.state[data.parent][data.child][data.field] = allOptions;
            } else if (
                (data.parent != "" || data.parent != false) &&
                data.child == false
            ) {
                let allOptions = this.state[data.parent][data.field];
                allOptions = removeTemplate(allOptions, data.index);
                this.state[data.parent][data.field] = allOptions;
            } else if (data.parent == false && data.child == false) {
                let allOptions = this.state[data.field];
                allOptions = removeTemplate(allOptions, data.index);
                this.state[data.field] = allOptions;
            }
        }
        // this.state.templateUpdated = false;
        this.setState(this.state, true);
    };

    handleAddOptions = (data) => {
        this.onClickOpenDialog(data);
    };

    addSubSection = (sections, index) => {
        let data = {
            label: "Enter Text",
            maxWidth: "xs",
            title: "",
            value: "",
            description: "",
            field: "",
            visibleState: true,
            templateContent: true,
            templateContentData: {
                head: "sections",
                itemIndex: 999,
                key: "sectionLabel",
                sectionIndex: index,
            },
            fieldType: "subSection",
            itemEditable: true,
        };
        this.onClickOpenDialog(data);
    };

    addItemDialog = (
        sections,
        sectionIndex,
        subSectionIndex,
        subSectionSelected
    ) => {
        let data = {
            label: "Enter Text",
            maxWidth: "xs",
            title: "ADDING ITEMS",
            value: "",
            description: "",
            field: "",
            visibleState: true,
            templateContent: true,
            templateContentData: { subSectionIndex, subSectionSelected },
            fieldType: "addItems",
            sectionIndex: sectionIndex,
            items: this.props.items,
            contentItems: subSectionSelected
                ? sections[sectionIndex]["subSections"][subSectionIndex][
                      "items"
                  ]
                : sections[sectionIndex]["items"],
        };
        this.onClickOpenDialog(data);
    };

    addItemDialogGetSelectedItem = (
        item,
        sectionIndex,
        sectionName,
        contentData,
        ref = false,
        refItem = {}
    ) => {
        if (item.itemType == "choice") {
            if (
                this.state.template.conditionRatingOptions
                    .enableConditionRatings
            ) {
                item.prohibitConditionRatings = false;
            }
            item.defaultOptions = ["Not Present", "Not Inspected"];
            item.allowMultipleSelection = false;
        }
        item.visibility = true;
        if (ref) {
            if (contentData.subSectionSelected) {
                this.state.template.templateContent[sectionIndex][
                    "subSections"
                ][contentData.subSectionIndex]["items"].push(refItem);
            } else {
                this.state.template.templateContent[sectionIndex]["items"].push(
                    refItem
                );
            }
        } else {
            if (contentData.subSectionSelected) {
                this.state.template.templateContent[sectionIndex][
                    "subSections"
                ][contentData.subSectionIndex]["items"].push(item);
            } else {
                this.state.template.templateContent[sectionIndex]["items"].push(
                    item
                );
            }
        }
        this.state.templateUpdated = false;
        // this.setState(this.state);
        this.updateState(this.state, false, true);
    };

    updateTemplate = () => {
        if(this.state.template.templateType == "fixed" && this.props.user.userType != "developer" && this.state.template.user != this.props.user.id){
            toast.error("Fixed templates generated by admin are not editable. Please clone the template first");
            return;
        }
        this.setState({loading:true});
        this.props.updateTemplate(this.state.template, (data) => {
            if (data.status == false) {
                toast.error(data.message);
                this.state.loading = false
                this.updateState(this.state, true, false);
            } else {
                this.state.template = data;
                this.state.oldTemplateState = _.merge({}, this.state.template);
                this.state.loading = false
                toast.success("Template updated successfully!");
                this.updateState(this.state, true, false);
                // this.setState({ templateUpdated: true, template: data });
            }
        });
    };

    sectionItemsChanger = (index, type) => {
        if(type == "Subsections"){
            this.state.selectedSubSectionIndex = index;
            this.state.subSectionIndex = index;
            this.state.subSectionSelected = true;
        }
        if(type == "Section"){
            this.state.sectionIndex = index;
            this.state.selectedSectionIndex = index;
            this.state.selectedSubSectionIndex = null;
            this.state.subSectionIndex = null;
            this.state.subSectionSelected = false;
        }
        this.updateState(this.state, false, !this.state.templateUpdated);
    }

    onDragEndx = (result) => {
        if (!result.destination) {
          return; // Dropped outside the list
        }
      
        const reorderedSections = Array.from(this.state.template.templateContent);
        const [reorderedItem] = reorderedSections.splice(result.source.index, 1);
        reorderedSections.splice(result.destination.index, 0, reorderedItem);
      
        // Update the state with the reordered list
        this.setState({
          template: {
            ...this.state.template,
            templateContent: reorderedSections,
          },
        });
      };

      handleOnDragEnd = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(this.state.template.templateContent);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        this.state.template.templateContent = items;
        this.updateState(this.state, true);
        // updateCharacters(items);
      }

    closeTemplateOptions = (options) => {
        for (const key in options) {
            // Check if the key exists in a
            if (this.state.template.hasOwnProperty(key)) {
                this.state.template[key] = options[key]; // Update the value in a with the value from b
            }
          }
        this.state.templateOptionModalSettings.show = false;
        this.updateState(this.state, false, true);
    }

    openTemplateOptions = () => {
        if(this.state.template.templateType === "fixed"){
            toast.error("Template options are disabled for this template");
            return;
        }
        this.state.templateOptionModalSettings.show = true;
        this.updateState(this.state);
    }

    editItemModalClose = (action, item, index) => {
        if(action === "Save"){
            if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
                this.state.template.templateContent[this.state.selectedSectionIndex]['items'][index] = item;
            } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
                this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]['items'][index] = item;
            }
        }
        this.state.itemEditModalSettings = {
            show:false,
            item:{},
            index: null
        };
        
        this.updateState(this.state, false);
    }
    openItemEditModal = (settings) => {
        this.state.itemEditModalSettings = settings;
        this.updateState(this.state, false, false);
    }

    editSectionModalClose = (action, section, index) => {
        if(action === "Save"){
            if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
                this.state.template.templateContent[index] = section
            } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
                if(this.state.template.pdfTemplate === "texas"){
                    section.items[0].itemLabel = section.sectionLabel;
                }
                this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][index] = section;
            }
        }
        this.state.sectionEditModalSettings = {
            show:false,
            section:{},
            index: null
        };
        this.updateState(this.state, false);
    }

    openSectionModal = (settings, type) => {
        if(this.state.template.pdfTemplate === "texas" && type === "Section"){
            toast.error("You can not create sections in this template");
            return ;
        }
        this.state.addSectionModalSettings = settings;
        this.updateState(this.state, false, false);
    }

    openAddItemModal = (settings) => {
        this.state.addItemModalSettings = settings;
        this.state.addItemModalSettings.listOfItems = this.state.availableItemTypes.records;
        this.updateState(this.state, false, false);
    }

    addItemnModalClose = (action, item) => {
        if(action === "Save"){
            if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
                this.state.template.templateContent[this.state.selectedSectionIndex]["items"].push(item);
            } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
                this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]["items"].push(item);
            }
        }
        // this.state.itemCollapsebleState = this.choiceTypeItemIndexes(this.state.template.templateContent);
        this.state.addItemModalSettings = {
            show:false,
            listOfItems: [],
            duplicates: [],
            selectedItem: {},
            view:"menu",
        };
        this.updateState(this.state, true);
    }

    openSectionHeaderFooterModal = (settings) => {
        this.state.sectionHeaderFooterModalSettings = settings;
        if(settings.type === "section"){
            this.state.sectionHeaderFooterModalSettings.title = this.state.template.templateContent[this.state.selectedSectionIndex].sectionLabel;
            this.state.sectionHeaderFooterModalSettings.section = this.state.template.templateContent[this.state.selectedSectionIndex];
            this.state.sectionHeaderFooterModalSettings.index = this.state.selectedSectionIndex;
            if(!this.state.template.templateContent[this.state.selectedSectionIndex][settings.field]){
                this.state.template.templateContent[this.state.selectedSectionIndex][settings.field] = "";
            }
            this.state.sectionHeaderFooterModalSettings.value = this.state.template.templateContent[this.state.selectedSectionIndex][settings.field];
        } else if( settings.type === "subsection" ){
            this.state.sectionHeaderFooterModalSettings.title = this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex].sectionLabel;
            this.state.sectionHeaderFooterModalSettings.section = this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex];
            this.state.sectionHeaderFooterModalSettings.index = this.state.selectedSectionIndex;
            if(!this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex][settings.field]){
                this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex][settings.field] = "";
            }
            this.state.sectionHeaderFooterModalSettings.value = this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex][settings.field];
        }
        this.updateState(this.state, false);
    }

    sectionHeaderFooterModalClose = (action, object) => {
        if(action === "Save"){
            if(object.type === "section"){
                this.state.template.templateContent[this.state.selectedSectionIndex][object.field] = object.value;
            } else if( object.type === "subsection" ){
                this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex][object.field] = object.value;
            }
        }
        this.state.sectionHeaderFooterModalSettings = {
            show: false,
            title: "",
            section: {},
            index: null,
            value: "",
            field: "",
        };
        this.updateState(this.state, false);
    }

    openAddRefItemModal = (settings) => {
        this.state.addRefItemModalSettings = settings;
        this.state.addRefItemModalSettings.templates = this.state.allTemplates;
        this.state.addRefItemModalSettings.currentTempId = this.state.currentTempId;
        this.updateState(this.state, false, false);
    }

    addRefItemModalClose = (action, object) => {
        if(action === "Save"){
            if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
                this.state.template.templateContent[this.state.selectedSectionIndex]["items"][object.itemIndex] = object.item;
            } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
                this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]["items"][object.itemIndex] = object.item;
            }
        }
        this.state.addRefItemModalSettings = {
            show:false,
            templates:[],
            itemIndex:null,
            view:"menu",
            ref: {},
            currentTempId: "",
            item:{}
        };
        this.updateState(this.state, false);
    }

    addSectionModalClose = (action, object) => {
        let section = {
            sectionLabel: object.value,
            items: [],
            header: "",
            footer: "",
            visibility: true,
            editable: true,
            subSections: [],
            comments: [],
        };
        if(action === "Save"){
            if(object.type === "Subsections"){
                if(this.state.template.pdfTemplate === "texas"){
                    section.items.push(
                        {
                            "allowMultipleSelection": true,
                            "visibility": true,
                            "defaultOptions": [
                                "Not Present",
                                "Not Inspected"
                            ],
                            "itemSelected": "Good",
                            "itemType": "choice",
                            "itemOptions": [
                                "Inspected",
                                "Not Inspected",
                                "Not Present",
                                "Deficient"
                            ],
                            "itemLabel": object.value,
                            "editable": false,
                        }
                    )
                }
                this.state.template.templateContent[object.index]["subSections"].push(section);
            } else {
                this.state.template.templateContent.push(section);
            }
        }
        this.state.itemCollapsebleState = this.choiceTypeItemIndexes(this.state.template.templateContent);
        this.state.addSectionModalSettings = {
            show:false,
            title: "",
            value: "",
            index: null,
            type:"",
            sectionName: ""
        };
        this.updateState(this.state, true);
    }

    deleteSection = (index, type) => {
        if(this.state.selectedSectionIndex >= 0 && type === "Section"){
            if((this.state.template.templateContent.length-1) == index){
                this.state.sectionIndex = index-1;
                this.state.selectedSectionIndex = index-1;
                this.state.selectedSubSectionIndex = null;
                this.state.subSectionIndex = null;
                this.state.subSectionSelected = false;
            }
            if(this.state.template.templateContent.length == 1){
                this.state.sectionIndex = null;
                this.state.selectedSectionIndex = null;
                this.state.selectedSubSectionIndex = null;
                this.state.subSectionIndex = null;
                this.state.subSectionSelected = true;
            }
            if((this.state.template.templateContent.length-1) === this.state.selectedSectionIndex){
                this.state.sectionIndex = this.state.sectionIndex-1;
                this.state.selectedSectionIndex = this.state.selectedSectionIndex-1;
                this.state.selectedSubSectionIndex = null;
                this.state.subSectionIndex = null;
                this.state.subSectionSelected = false;
            }
            this.state.template.templateContent.splice(index, 1);
        } else if( this.state.selectedSectionIndex >= 0 && type === "Subsections" ){
            this.state.selectedSubSectionIndex = null;
            this.state.subSectionIndex = null;
            this.state.subSectionSelected = false;
            this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'].splice(index, 1);
        }
        this.updateState(this.state, true);
    }

    openSectionEditModal = (settings) => {
        this.state.sectionEditModalSettings = settings;
        this.updateState(this.state, false, false);
    }

    deleteItem = (index) => {
        if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
            this.state.template.templateContent[this.state.selectedSectionIndex]['items'].splice(index, 1);
        } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
            this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]['items'].splice(index, 1);
        }
        this.updateState(this.state, true);
    }

    deleteOptionForChoiceTypeItem = (index, itemIndex) => {
        if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
            this.state.template.templateContent[this.state.selectedSectionIndex]['items'][itemIndex]['itemOptions'].splice(index,1);
        } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
            this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]['items'][itemIndex]['itemOptions'].splice(index,1);
        }
        this.updateState(this.state);
    }

    addOptionForChoiceTypeItem = (index, option) => {
        if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
            this.state.template.templateContent[this.state.selectedSectionIndex]['items'][index]['itemOptions'].push(option);
        } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
            this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]['items'][index]['itemOptions'].push(option);
        }
        this.updateState(this.state);
    }

    updateOptionForChoiceTypeItem = (index, itemIndex, value) => {
        if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
            this.state.template.templateContent[this.state.selectedSectionIndex]['items'][itemIndex]['itemOptions'][index] = value;
        } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
            this.state.template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]['items'][itemIndex]['itemOptions'][index] = value;
        }
        this.updateState(this.state);
    }

    choiceCheckHandle = (index, option) => {
        if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
            this.state.template.templateContent[this.state.selectedSectionIndex]['items'][index][option] = !this.state.template.templateContent[this.state.selectedSectionIndex]['items'][index][option];
        } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
            this.state.template.templateContent[this.state.selectedSectionIndex]['subSections']['items'][index][option] = !this.state.template.templateContent[this.state.selectedSectionIndex]['subSections']['items'][index][option];
        }
        this.updateState(this.state);
    }

    changeItemCollapseState = (index) => {
        this.state.itemCollapsebleState = this.toggleItemValue(this.state.itemCollapsebleState, this.state.selectedSectionIndex, this.state.selectedSubSectionIndex, index);
        // this.setState(this.state);
        this.updateState(this.state);
    }

    choiceTypeItemIndexes = (templateContent) => {
        let indexesOfChoiceItems = {};
        // Iterate through sections
        templateContent.forEach((section, sectionIndex) => {
            indexesOfChoiceItems[`section-${sectionIndex}`] = [];
          
            // Check if the section has items
            if (section.items) {
              // Iterate through items in the section
              section.items.forEach((item, itemIndex) => {
                if (item.itemType === 'choice') {
                  // Store the index of the choice item with a default value of false
                  indexesOfChoiceItems[`section-${sectionIndex}`].push({ [`item-${itemIndex}`]: false });
                }
              });
            }
          
            // Check if the section has subsections
            if (section.subSections) {
              section.subSections.forEach((subsection, subsectionIndex) => {
                indexesOfChoiceItems[`section-${sectionIndex}`][`subsection-${subsectionIndex}`] = [];
          
                // Check if the subsection has items
                if (subsection.items) {
                  // Iterate through items in the subsection
                  subsection.items.forEach((item, itemIndex) => {
                    if (item.itemType === 'choice') {
                      // Store the index of the choice item with a default value of false
                      indexesOfChoiceItems[`section-${sectionIndex}`][`subsection-${subsectionIndex}`].push({
                        [`item-${itemIndex}`]: false,
                      });
                    }
                  });
                }
              });
            }
        });
        return indexesOfChoiceItems;
    }

    toggleItemValue = (indexesOfChoiceItems, sectionIndex, subsectionIndex, itemIndex) => {
        const sectionKey = `section-${sectionIndex}`;
        const subsectionKey = `subsection-${subsectionIndex}`;
        const itemKey = `item-${itemIndex}`;

        if(indexesOfChoiceItems[sectionKey] && subsectionIndex == null){
            indexesOfChoiceItems[sectionKey].forEach((item) => {
                if(item[itemKey] !== undefined){
                    item[itemKey] = !item[itemKey];
                }
            })
        } else if(subsectionIndex != null){
            if(indexesOfChoiceItems[sectionKey] && indexesOfChoiceItems[sectionKey][subsectionKey]){
                indexesOfChoiceItems[sectionKey][subsectionKey].forEach((item) => {
                    if(item[itemKey] !== undefined){
                        item[itemKey] = !item[itemKey];
                    } 
                })
            }
        }
        return indexesOfChoiceItems;
    }

    getChoiceStateValue = (indexesOfChoiceItems, sectionIndex, subsectionIndex, itemIndex) => {
        const sectionKey = `section-${sectionIndex}`;
        const subsectionKey = `subsection-${subsectionIndex}`;
        const itemKey = `item-${itemIndex}`;
        let value = false;
        if(indexesOfChoiceItems[sectionKey] && subsectionIndex == null){
            indexesOfChoiceItems[sectionKey].forEach((item) => {
                if(item[itemKey] !== undefined){
                    value = item[itemKey];
                }
            })
        } else if(subsectionIndex != null){
            if(indexesOfChoiceItems[sectionKey] && indexesOfChoiceItems[sectionKey][subsectionKey]){
                indexesOfChoiceItems[sectionKey][subsectionKey].forEach((item) => {
                    if(item[itemKey] !== undefined){
                        value = item[itemKey];
                    } 
                })
            }
        }
        return value;
    }

    updateState = (newState, generateIndexes = false, templateUpdated = true) => {
        if(generateIndexes){
            newState.itemCollapsebleState = this.choiceTypeItemIndexes(this.state.template.templateContent);
        }
        newState.templateUpdated = !templateUpdated;
        this.setState((prevState) => ({
          ...prevState,
          ...newState,
        }));
    }

    onChangeTemplateInfo = (e, field) => {
        this.state.template[field] = e.target.value;
        this.updateState(this.state, false, true);
    }

    changeTemplateFieldType = () => {
        this.setState({templateNameEditState: !this.state.templateNameEditState})
        setTimeout(() => {
            if(this.textfieldRef.current){
                this.textfieldRef.current.focus();
            }
        }, 500);
    }

    cancelChanges = () => {
        this.state.template = _.merge({}, this.state.oldTemplateState);
        this.updateState(this.state,true,false);
    }

    
    render = () => {
        let { template, user, templateOptionModalSettings, sectionEditModalSettings, itemEditModalSettings } = this.state;
        if(!template){
            return (<Spinner/>);
        }
        let templateOptions = {
            showTableOfContents: false,
                showChoiceTypeDefaultOptions: true,
                startEachSectionOnNewPageInPdf: false,
                inspectorAppearanceName: "Inspected By: ",
                printedReportTitle: "Report Title: ",
                preparedForDescription: "Prepared By: ",
                propertyAddressDescription: "",
                inspectionDateAppearance: "",
                verticallyCenterTitlePageContent: false,
                scaleThePropertyPhotoLarge: false,
                reportSummaryOptions: {
                    options: {
                        endOfReport: false,
                        beginningOfReport: true,
                        noReportSummary: false,
                    },
                    includeImagesInTheReportSummary: true,
                    includeOriginalCommentNumberInTheReportSummary: true,
                    summaryHeader: "",
                    summaryFooter: "",
                },
                conditionRatingOptions: {
                    enableConditionRatings: true,
                    conditionalOptions: ["Good", "Fair", "Poor"],
                    requireConditionRatingsForItemsToBeConsideredComplete: true,
                },
                reportHeader: "",
                reportFooter: "",
                pageFooter: "",
        }
        if(template){
            templateOptions = {
                showTableOfContents: template.showTableOfContents,
                showChoiceTypeDefaultOptions: template.showChoiceTypeDefaultOptions,
                startEachSectionOnNewPageInPdf: template.startEachSectionOnNewPageInPdf,
                inspectorAppearanceName: template.inspectorAppearanceName,
                printedReportTitle: template.printedReportTitle,
                preparedForDescription: template.preparedForDescription,
                propertyAddressDescription: template.propertyAddressDescription,
                inspectionDateAppearance: template.inspectionDateAppearance,
                verticallyCenterTitlePageContent: template.verticallyCenterTitlePageContent,
                scaleThePropertyPhotoLarge: template.scaleThePropertyPhotoLarge,
                reportSummaryOptions: template.reportSummaryOptions,
                conditionRatingOptions: template.conditionRatingOptions,
                reportHeader: template.reportHeader,
                reportFooter: template.reportFooter,
                pageFooter: template.pageFooter,
            };
        }
        let templateOwner = false;
        if(template.user == user.id){
            templateOwner = true;
        }
        let templateUser = "inspector";
        if(template.hasOwnProperty("templateUser")){
            templateUser = template.templateUser;
        }
        let templateOptionsEnabled = true;
        if(template.templateType == "fixed"){
            templateOptionsEnabled = false;
        }
        if(template.templateType == "fixed" && user.userType != "developer" && template.user != user.id ){
            this.state.templateUpdated = true;
        }
        if(template.showChoiceTypeDefaultOptions === undefined){
            template.showChoiceTypeDefaultOptions = true;
        }
        let itemList = [];
        let selectedSection = {};
        let selectedSubsection = {};
        let hasSubSections = false;
        let sectionVisibility = true;
        let sectionEditable = true;
        if(this.state.selectedSectionIndex != null){
            if(this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex === null){
                itemList = template.templateContent[this.state.selectedSectionIndex]['items'];
                selectedSection = template.templateContent[this.state.selectedSectionIndex];
                if(template.templateContent[this.state.selectedSectionIndex]['subSections'].length > 0){
                    hasSubSections = true;
                }
                sectionVisibility = template.templateContent[this.state.selectedSectionIndex]['visibility'];
                sectionEditable = template.templateContent[this.state.selectedSectionIndex]['editable'];
            } else if( this.state.selectedSectionIndex >= 0 && this.state.selectedSubSectionIndex >= 0 ){
                itemList = template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]['items'];
                selectedSubsection = template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex];
                selectedSection = template.templateContent[this.state.selectedSectionIndex];
                hasSubSections = true;
                sectionVisibility = template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]['visibility'];
                sectionEditable = template.templateContent[this.state.selectedSectionIndex]['subSections'][this.state.selectedSubSectionIndex]['editable'];
            }
        }
        const sectionOptions = {
            drag:true,
            edit: true,
            delete:true,
            visible: true
        }
        const itemOptions = {
            link: true,
            ...sectionOptions
        }
        return (
            <div>
                <div className="scope-sticky">
                    <div className="container">
                        {this.state.loading && <Spinner />}
                        <div className="row mt-3 mb-3 edit-template-head">
                            <div className="col-md-9">
                                <div className="scope-templetes-title">
                                    {this.state.templateNameEditState ? 
                                    <Form.Control
                                    type="text"
                                    value={template.templateName}
                                    ref={this.textfieldRef}
                                    onChange={(e) => this.onChangeTemplateInfo(e, "templateName")}
                                    className="templateName-edit"/>
                                    : template.templateName }
                                    <div className="editIcon" onClick={(e) => this.changeTemplateFieldType()}>
                                        {this.state.templateNameEditState ?  <></>
                                        // <CheckIcon className="scope-svg-menu-icon cursor" icon={this.state.templateNameEditState === true ? "check" : "uncheck"} />
                                        :
                                        <EditBlueIcon title="Edit Template Title"/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row template-edit-action-btns">
                                {this.state.templateNameEditState ? 
                                <div className="col-md-12 text-right">
                                    {/* <CheckIcon className="scope-svg-menu-icon cursor" icon={this.state.templateNameEditState === true ? "check" : "uncheck"} /> */}
                                    <Button
                                        className="templateName-edit-buttons"
                                        label="Done"
                                        showLeftIcon={false}
                                        showRightIcon={false}
                                        size="large"
                                        state="default"
                                        variant="filled"
                                        type="primary"
                                        clickEvent={(e) => this.changeTemplateFieldType()}
                                        />
                                </div>
                                :<>
                                    <div className="col-md-6 col-sm-3 col-6 text-right">
                                        <Button
                                        className="template-edit-buttons"
                                        label="Cancel"
                                        showLeftIcon={false}
                                        showRightIcon={false}
                                        size="large"
                                        state="default"
                                        variant="ghost"
                                        type="primary"
                                        disabled={this.state.templateUpdated}
                                        clickEvent={(e) => this.cancelChanges()}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-3 col-6">
                                        <Button
                                        className="template-edit-buttons"
                                        label="Save"
                                        showLeftIcon={false}
                                        showRightIcon={false}
                                        size="large"
                                        state="default"
                                        variant="filled"
                                        type="primary"
                                        disabled={this.state.templateUpdated}
                                        clickEvent={(e) => this.updateTemplate()}
                                        />
                                    </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container edittemplatecard card">
                    <div className="row description-head">
                        <div className="col-md-12">
                            <div className="description-label mb-2">
                                Description
                            </div>
                            <div className="description-textarea">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Template description goes here..."
                                    style={{ height: '100px' }}
                                    value={template.templateDescription}
                                    onChange={(e) => this.onChangeTemplateInfo(e, "templateDescription")}
                                    />
                                {/* <textarea rows={4} value={template.templateDescription}>
                                    {template.templateDescription}
                                </textarea> */}
                            </div>
                        </div>
                    </div>
                    <br/>
                    <button className={`button large default outlined template-options-btn`} onClick={this.openTemplateOptions}>
                        <div className="leftIcon-templateOptions">
                            <TemplateOptionIcon />
                        </div>
                        <div className="button-label label-templateOptions">Template Options</div>
                        <div className="rightIcon-templateOptions">
                            <ArrowIcon width="20" height="20" />
                        </div>
                    </button>
                    <div className="container templateStructure">
                        <div className="row">
                            <div className="col-lg-7 col-md-12">
                                <div className="row">
                                    <div className="col-md-6 p-2">
                                        <div className="sections">
                                            <List 
                                            type="Section"
                                            options={sectionOptions}
                                            list={template.templateContent ? template.templateContent : []}
                                            listClick={this.sectionItemsChanger}
                                            editSection={this.openSectionEditModal}
                                            deleteSection={this.deleteSection}
                                            itemCollapsebleState={this.state.itemCollapsebleState}
                                            draggableComponent={DraggableComponent}
                                            droppableComponent={DroppableComponent(this.onDragEnd, {path: "templateContent", items: false, sections: true, subsections: false, sectionIndex: this.state.sectionIndex, head: "sections"})}
                                            add={this.openSectionModal}
                                            editHeaderFooter={this.openSectionHeaderFooterModal}
                                            selectedIndex={this.state.sectionIndex}
                                            visibilityHandler={this.visibilityHandler}
                                            selectedSubIndex={this.state.subSectionIndex}
                                            templateType={template.templateType}
                                            user={this.props.user}
                                            template={templateOwner}
                                            />
                                            <div className="spacer templateSpacer"></div>
                                            {this.state.selectedSectionIndex != null &&
                                            <ListHeader
                                                sectionIndex={this.state.selectedSectionIndex}
                                                subSectionIndex={this.state.selectedSubSectionIndex}
                                                section={selectedSection}
                                                editHeaderFooter={this.openSectionHeaderFooterModal}
                                                header=""
                                                footer=""
                                                type="section"
                                            />}
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-2">
                                        <div className="subSections">
                                            <List 
                                            type="Subsections"
                                            options={sectionOptions}
                                            list={template.templateContent && this.state.selectedSectionIndex !== null ? template.templateContent[this.state.selectedSectionIndex]['subSections'] : []}
                                            listClick={this.sectionItemsChanger}
                                            editSection={this.openSectionEditModal}
                                            itemCollapsebleState={this.state.itemCollapsebleState}
                                            deleteSection={this.deleteSection}
                                            draggableComponent={DraggableComponent}
                                            add={this.openSectionModal}
                                            visibilityHandler={this.visibilityHandler}
                                            droppableComponent={DroppableComponent(this.onDragEnd, {path: "templateContent", items: false, sections: false, subsections: true, sectionIndex: this.state.sectionIndex, head: "subSections"})}
                                            selectedIndex={this.state.sectionIndex}
                                            selectedSubIndex={this.state.subSectionIndex}
                                            templateType={template.templateType}
                                            user={this.props.user}
                                            template={templateOwner}
                                            />
                                            {hasSubSections && this.state.selectedSubSectionIndex != null && <>
                                            <div className="spacer templateSpacer"></div>
                                            <ListHeader
                                                sectionIndex={this.state.selectedSectionIndex}
                                                subSectionIndex={this.state.selectedSubSectionIndex}
                                                editHeaderFooter={this.openSectionHeaderFooterModal}
                                                section={selectedSubsection}
                                                header=""
                                                footer=""
                                                type="subsection"
                                            /></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 p-2">
                                <div className="items">
                                    <List 
                                    type="Items"
                                    options={itemOptions}
                                    editItem={this.openItemEditModal}
                                    deleteItem={this.deleteItem}
                                    list={itemList}
                                    selectedIndex={this.state.sectionIndex}
                                    template={templateOwner}
                                    selectedSubIndex={this.state.subSectionIndex}
                                    itemCollapsebleState={this.state.itemCollapsebleState}
                                    changeCollapseState={this.changeItemCollapseState}
                                    getCollapseStateValue={this.getChoiceStateValue}
                                    choiceCheckHandle={this.choiceCheckHandle}
                                    sectionVisibility={sectionVisibility}
                                    sectionEditable={sectionEditable}
                                    add={this.openAddItemModal}
                                    addRef={this.openAddRefItemModal}
                                    visibilityHandler={this.visibilityHandler}
                                    deleteChoiceOption={this.deleteOptionForChoiceTypeItem}
                                    addChoiceOption={this.addOptionForChoiceTypeItem}
                                    updateChoiceOption={this.updateOptionForChoiceTypeItem}
                                    listClick={this.sectionItemsChanger}
                                    templateType={template.templateType}
                                    user={this.props.user}
                                    draggableComponent={DraggableComponent}
                                    droppableComponent={DroppableComponent(this.onDragEnd, {path: "templateContent", items: true, sections: false, subsections: false, sectionIndex: this.state.sectionIndex, head: "items"})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TemplateOptionsModal  options={templateOptions} modalSettings={templateOptionModalSettings} close={this.closeTemplateOptions}/>
                <ItemEditModal modalSettings={itemEditModalSettings} close={this.editItemModalClose} />
                <SectionEditModal modalSettings={sectionEditModalSettings} close={this.editSectionModalClose} />
                <AddSectionModal modalSettings={this.state.addSectionModalSettings} close={this.addSectionModalClose} />
                <AddItemModal modalSettings={this.state.addItemModalSettings} close={this.addItemnModalClose} />
                <AddRefItemModal modalSettings={this.state.addRefItemModalSettings} close={this.addRefItemModalClose} />
                <SectionHeaderFooterEditModal modalSettings={this.state.sectionHeaderFooterModalSettings} close={this.sectionHeaderFooterModalClose} />
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        templates: state.appData.templates,
        items: state.appData.items,
        user: state.authData.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        destroyTemplate: (object, cb) => dispatch(destroyTemplate(object, cb)),
        getItems: (object, cb) => dispatch(getItems(object, cb)),
        getTemplateByID: (id, cb) => dispatch(getTemplateByID(id, cb)),
        updateTemplate: (template, cb) =>
        dispatch(updateTemplate(template, cb)),
        getAllTemplates: (object, cb) => dispatch(getAllTemplates(object, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);

function removeTemplate(arr, index) {
    arr.splice(index, 1);
    return arr;
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)",
    }),
});

const sortNumberSorting = (items) => {
    return items.map((item, index) => {
        item.sortNo = index + 1 + "";
        return item;
    });
};


const DraggableComponent = (id, index, item) => (props) => {
    return (
        item.hasOwnProperty("editable") && item.editable === false ?
            <ListGroup.Item
            {...props}
            >
                <div className="listbody">
                    {props.children}
                </div>
            </ListGroup.Item>
        :
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <ListGroup.Item
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >   
                    <div className="listbody">
                        <div className={`dragIcon`} {...provided.dragHandleProps}>
                            <DragIcon />
                        </div> 
                        {props.children}
                    </div>
                </ListGroup.Item>
            )}
        </Draggable>
    )
}

const DroppableComponent = (onDragEnd: (result, provided) => void, path) => (props) =>
    {
    return (
        <DragDropContext onDragEnd={onDragEnd.bind(this, path)}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <ListGroup ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </ListGroup>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
}

// const DraggableComponentX = (id, index) => (props) => {
//     return (
//         <Draggable key={id} draggableId={id} index={index}>
//         {(provided, snapshot) => (
//             <ListGroup.Item 
//             className={`listitem label itemsection list-group-item-action xxxxx`} 
//             ref={provided.innerRef} 
//             {...provided.draggableProps}
//             style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
//             {...props}
//             >
//                 <div className="dragIcon" {...provided.dragHandleProps}>
//                         <DragIcon />
//                     </div>
//                     {props.children}
//             </ListGroup.Item>
//         )}
//         </Draggable>
//     )
// }

// const testComponent = (data) => {
//     return (
//         <ListGroup.Item className='hello world'>
//             {data.children}
//         </ListGroup.Item>
//     )
// }

// const DraggableComponentXx = (data) => {
//     return (
//       <Draggable draggableId={data.id} index={data.index}>
//         {(provided, snapshot) => (
//           <ListGroup.Item
//             className="helloworld"
//             ref={provided.innerRef}
//             {...provided.draggableProps}
//             style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
//           >
//             <div className="dragIcon" {...provided.dragHandleProps}>
//               <DragIcon />
//             </div>
//             {data.children}
//           </ListGroup.Item>
//         )}
//       </Draggable>
//     );
// };
// const DroppableComponentXx = (onDragEnd: (result, provided) => void, path) => (props) => {
//     return (
//         <DragDropContext onDragEnd={onDragEnd.bind(this, path)}>
//             <Droppable droppableId={'1'} direction="vertical">
//             {(provided) => {
//                 return (
//                     <ListGroup ref={provided.innerRef} {...provided.droppableProps} {...props}>
//                             {props.children}
//                             {provided.placeholder}
//                         </ListGroup>
//                 )
//             }}
//             </Droppable>
//         </DragDropContext>
//     );
// }
  
