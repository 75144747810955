/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import {EditIcon, CheckIcon, CollapsebleIcons, TrashIcon, PlusIcon, RadioIcon} from "../../icons/Icons";
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import {Button as Btn} from "../../components/button/button";
import Form from 'react-bootstrap/Form';


class TemplateOptionsModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        options: {
            showTableOfContents: false,
            showChoiceTypeDefaultOptions: true,
            startEachSectionOnNewPageInPdf: false,
            inspectorAppearanceName: "Inspected By: ",
            printedReportTitle: "Report Title: ",
            preparedForDescription: "Prepared By: ",
            propertyAddressDescription: "",
            inspectionDateAppearance: "",
            verticallyCenterTitlePageContent: false,
            scaleThePropertyPhotoLarge: false,
            reportSummaryOptions: {
                options: {
                    endOfReport: false,
                    beginningOfReport: true,
                    noReportSummary: false,
                },
                includeImagesInTheReportSummary: true,
                includeOriginalCommentNumberInTheReportSummary: true,
                summaryHeader: "",
                summaryFooter: "",
            },
            conditionRatingOptions: {
                enableConditionRatings: true,
                conditionalOptions: ["Good", "Fair", "Poor"],
                requireConditionRatingsForItemsToBeConsideredComplete: true,
            },
            reportHeader: "",
            reportFooter: "",
            pageFooter: "",
        },
        modalSettings: {
            show: false,
            dialogClassName: "modal-90w"
        },
        conditionalRatingOpenState: false,
        reportTitleOptionsOpenState: false,
        reportSummaryOptionsOpenState: false,
        reportHeaderEditState: false,
        reportFooterEditState: false,
        reportPageFooterEditState: false,
        newOption: "",
        optionPlaceHolder: "Add new option",
        placeholderColorClass: '',
        secondaryModalSettings:{
            secondaryModalState: false,
            title:"",
            field: "",
            index: null,
            type: "",
            path: "",
        }
    }
  }
  componentDidMount = async () => {
    this.state.options = this.props.options;
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.options = nextProps.options;
    this.state.modalSettings = nextProps.modalSettings;
    this.state.conditionalRatingOpenState = false;
    this.state.reportTitleOptionsOpenState = false;
    this.state.reportSummaryOptionsOpenState = false;
    this.state.reportHeaderEditState = false;
    this.state.reportFooterEditState = false;
    this.state.reportPageFooterEditState = false;
    this.state.newOption = "";
    this.state.optionPlaceHolder = "Add new option";
    this.state.placeholderColorClass = '';
    this.state.secondaryModalSettings = {
        secondaryModalState: false,
        title: "",
        field: "",
        index: null,
        type: "",
        path: "",
    };
    this.setState( this.state );
  }

  changeState = (state, value, path) => {
    if(path === "root"){
        this.state[state] = !value;
    } else {
        this.state = this.getObjectPropertyByPath(this.state, path, !value)
    }
    this.setState(this.state);
  }

  changeStateRadio = (selected) => {
    this.state.options.reportSummaryOptions.options = {
        endOfReport: false,
        beginningOfReport: false,
        noReportSummary: false,
    };
    this.state.options.reportSummaryOptions.options[selected] = true;
    this.setState(this.state);
  }

  onChange = (e, key, path) => {
    if(path === "root"){
        this.state[key] = e.target.value;
    } else {
        this.state = this.getObjectPropertyByPath(this.state, path, e.target.value);
    }
    this.setState(this.state);
  }

  addNewOption = () => {
    if(this.state.newOption !== ""){
        this.state.options.conditionRatingOptions.conditionalOptions.push(this.state.newOption);
        this.state.newOption = "";
        this.state.optionPlaceHolder = "Add new option";
        this.state.placeholderColorClass = "";
    } else {
        this.state.optionPlaceHolder = "Please enter value first";
        this.state.placeholderColorClass = "error";
    }
    this.setState(this.state);
  }


  getObjectPropertyByPath = (obj, path, value) => {
    // Split the path string into an array of keys
    const keys = path.split('.');
    const lastKey = keys.pop(); // Get the last key
  
    // Use reduce to navigate through the object properties
    const result = keys.reduce((acc, key) => {
      if (acc && typeof acc === 'object' && key in acc) {
        return acc[key];
      } else {
        return undefined; // Property not found
      }
    }, obj);
  
    if (result && typeof result === 'object') {
      result[lastKey] = value; // Set the value
    }
  
    return obj; // Return the modified object
  };

  deleteOption = (index) => {
    this.state.options.conditionRatingOptions.conditionalOptions.splice(index, 1);
    this.setState(this.state);
  }

  secondaryModalClose = (type) => {
    if(type === "Save"){
        if(this.state.secondaryModalSettings.type === "conditionalRatings"){
            this.state.options.conditionRatingOptions.conditionalOptions[this.state.secondaryModalSettings.index] = this.state.secondaryModalSettings.value;
        }
        if(this.state.secondaryModalSettings.type === "reportTitlePageOptions"){
            this.state = this.getObjectPropertyByPath(this.state, this.state.secondaryModalSettings.path, this.state.secondaryModalSettings.value);
        }
    }
    this.state.secondaryModalSettings = {
        secondaryModalState: false,
        title: "",
        field: "",
        index: null,
        type: "",
        path: "",
    };
    this.setState(this.state);
  }

  openSecondaryModal = (title, field, type, path, value, index) => {
    this.state.secondaryModalSettings = {
        secondaryModalState: !this.state.secondaryModalSettings.secondaryModalState,
        title:title,
        type:type,
        path:path,
        value:value,
        index:index,
        field: field
    }
    this.setState(this.state);
  }

  render = () => {
    const {modalSettings, secondaryModalSettings, options, newOption, conditionalRatingOpenState, reportTitleOptionsOpenState, reportSummaryOptionsOpenState, reportHeaderEditState, reportFooterEditState, reportPageFooterEditState} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            onHide={() => this.props.close(options)}
            dialogClassName="modal-90w templateOptions"
            aria-labelledby="template-options"
        >
            <Modal.Header closeButton>
            <Modal.Title id="template-options-header">
                Template Options
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col className="pr-2">
                            <ListGroup className={`list list-templateOptions`}>
                                <ListGroup.Item key="conditional-rating" className="listitem label itemTemplateOptions" action onClick={(e) => this.changeState("conditionalRatingOpenState", conditionalRatingOpenState, "root")}>
                                    <div className="labelName">Conditional Rating Options</div>
                                    <div className="manage-actions">
                                        <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("conditionalRatingOpenState", conditionalRatingOpenState, "root")}>
                                            <CollapsebleIcons className="scope-svg-menu-icon cursor" icon={conditionalRatingOpenState === true ? "open" : "close"} />
                                        </Button>
                                    </div>
                                </ListGroup.Item>
                                <Collapse in={conditionalRatingOpenState}>
                                        <ListGroup className="nested-list">
                                            <ListGroup.Item key="conditional-rating-enable" className="listitem nestedlistitem label itemTemplateOptions" action onClick={(e) => this.changeState("conditionalRatingOpenState", options.conditionRatingOptions.enableConditionRatings, "options.conditionRatingOptions.enableConditionRatings")}>
                                                <div className="labelName">Enable Conditional Ratings</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn">
                                                        <CheckIcon className="scope-svg-menu-icon cursor" icon={options.conditionRatingOptions.enableConditionRatings === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                            { options.conditionRatingOptions.enableConditionRatings && <>{options.conditionRatingOptions.conditionalOptions.map((option, index) => {
                                                return (
                                                    <ListGroup.Item key={`conditional-rating-nestings-${index}`} className="listitem nestedlistitem label itemTemplateOptions">
                                                        <div className="labelName">{option}</div>
                                                        <div className="manage-actions">
                                                            <div className="editIcon" onClick={(e) => this.openSecondaryModal(option, option, "conditionalRatings", "options.conditionRatingOptions.enableConditionRatings", option, index)}>
                                                                <EditIcon />
                                                            </div>
                                                            <div className="trashIcon" onClick={(e) => this.deleteOption(index)}>
                                                                <TrashIcon/>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                );
                                            })}
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="conditional-rating-add-new">
                                                <div className="labelName">
                                                    <Form.Control type="text" placeholder={this.state.optionPlaceHolder} value={newOption} onChange={(e) => this.onChange(e, "newOption", "root")} className={`addNewOption ${this.state.placeholderColorClass}`}></Form.Control>
                                                </div>
                                                <div className="manage-actions">
                                                <Btn
                                                    className={`template-option-add addChoiceOptionBtn`}
                                                    label={``}
                                                    showLeftIcon={true}
                                                    leftIcon={<PlusIcon svgFill="#FFFFFF" iconFill="#FFFFF" className="template_add_option_btn"/>}
                                                    showRightIcon={false}
                                                    size="large"
                                                    state="default"
                                                    variant="filled"
                                                    type="primary"
                                                    clickEvent={this.addNewOption}
                                                    />
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="conditional-rating-consider" action onClick={(e) => this.changeState("conditionalRatingOpenState", options.conditionRatingOptions.requireConditionRatingsForItemsToBeConsideredComplete, "options.conditionRatingOptions.requireConditionRatingsForItemsToBeConsideredComplete")}>
                                                <div className="labelName">Require Condition Ratings For Items To Be Considered Complete</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn">
                                                        <CheckIcon className="scope-svg-menu-icon cursor" icon={options.conditionRatingOptions.requireConditionRatingsForItemsToBeConsideredComplete === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                            </>
                                            }
                                        </ListGroup>
                                    </Collapse>
                                    <ListGroup.Item className="listitem label itemTemplateOptions" key="conditional-rating-prohibit" action onClick={(e) => this.changeState("showChoiceTypeDefaultOptions", options.showChoiceTypeDefaultOptions, "options.showChoiceTypeDefaultOptions")}>
                                        <div className="labelName">Conditional Default Options for Choice type item to display<br/><span className="subLabelName">(Inspected/Not Inspected)</span></div>
                                        <div className="manage-actions">
                                            <Button variant="link" className="collapse-btn">
                                                <CheckIcon className="scope-svg-menu-icon cursor" icon={options.showChoiceTypeDefaultOptions === true ? "check" : "uncheck"} />
                                            </Button>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="listitem label itemTemplateOptions" key="template-options-report-title" action onClick={(e) => this.changeState("reportTitleOptionsOpenState", reportTitleOptionsOpenState, "root")}>
                                        <div className="labelName">Report Title Page Option</div>
                                        <div className="manage-actions">
                                            <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("reportTitleOptionsOpenState", reportTitleOptionsOpenState, "root")}>
                                                <CollapsebleIcons className="scope-svg-menu-icon cursor" icon={reportTitleOptionsOpenState === true ? "open" : "close"} />
                                            </Button>
                                        </div>
                                    </ListGroup.Item>
                                    <Collapse in={reportTitleOptionsOpenState}>
                                        <ListGroup className="nested-list">
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-insname">
                                                <div className="labelName">Inspector Name Appearance<br/><span className="subLabelName">{options.inspectorAppearanceName}</span></div>
                                                <div className="manage-actions">
                                                    <div className="editIcon" onClick={(e) => this.openSecondaryModal(options.inspectorAppearanceName, options.inspectorAppearanceName, "reportTitlePageOptions", "options.inspectorAppearanceName", options.inspectorAppearanceName, null)}>
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-pt">
                                                <div className="labelName">Printed Report Title<br/><span className="subLabelName">{options.printedReportTitle}</span></div>
                                                <div className="manage-actions">
                                                    <div className="editIcon" onClick={(e) => this.openSecondaryModal(options.printedReportTitle, options.printedReportTitle, "reportTitlePageOptions", "options.printedReportTitle", options.printedReportTitle, null)}>
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-pd">
                                                <div className="labelName">Prepared for Description<br/><span className="subLabelName">{options.preparedForDescription}</span></div>
                                                <div className="manage-actions">
                                                    <div className="editIcon" onClick={(e) => this.openSecondaryModal(options.preparedForDescription, options.preparedForDescription, "reportTitlePageOptions", "options.preparedForDescription", options.preparedForDescription, null)}>
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-pad">
                                                <div className="labelName">Property Address Description<br/><span className="subLabelName">{options.propertyAddressDescription}</span></div>
                                                <div className="manage-actions">
                                                    <div className="editIcon" onClick={(e) => this.openSecondaryModal(options.propertyAddressDescription, options.propertyAddressDescription, "reportTitlePageOptions", "options.propertyAddressDescription", options.propertyAddressDescription, null)}>
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-ida">
                                                <div className="labelName">Inspection Date Appearance<br/><span className="subLabelName">{options.inspectionDateAppearance}</span></div>
                                                <div className="manage-actions">
                                                    <div className="editIcon" onClick={(e) => this.openSecondaryModal(options.inspectionDateAppearance, options.inspectionDateAppearance, "reportTitlePageOptions", "options.inspectionDateAppearance", options.inspectionDateAppearance, null)}>
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-ctp">
                                                <div className="labelName">Vertically Center Title Page Content</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("verticallyCenterTitlePageContent", options.verticallyCenterTitlePageContent, "options.verticallyCenterTitlePageContent")}>
                                                        <CheckIcon className="scope-svg-menu-icon cursor" icon={options.verticallyCenterTitlePageContent === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-scp">
                                                <div className="labelName">Scale The Property Photo To Be As Large As Possible</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("scaleThePropertyPhotoLarge", options.scaleThePropertyPhotoLarge, "options.scaleThePropertyPhotoLarge")}>
                                                        <CheckIcon className="scope-svg-menu-icon cursor" icon={options.scaleThePropertyPhotoLarge === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Collapse>
                                    <ListGroup.Item key="template-options-rsp" className={`listitem label itemTemplateOptions ${reportSummaryOptionsOpenState ? "" : "lastListGroup"}`} action onClick={(e) => this.changeState("reportSummaryOptionsOpenState", reportSummaryOptionsOpenState, "root")}>
                                        <div className="labelName">Report Summary Option</div>
                                        <Button variant="link" className="collapse-btn">
                                            <CollapsebleIcons className="scope-svg-menu-icon cursor" icon={reportSummaryOptionsOpenState === true ? "open" : "close"} />
                                        </Button>
                                    </ListGroup.Item>
                                    <Collapse in={reportSummaryOptionsOpenState}>
                                        <ListGroup className="nested-list lastListGroup">
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-rso-e">
                                                <div className="labelName">End Of Report</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn" onClick={(e) => this.changeStateRadio("endOfReport")}>
                                                        <RadioIcon className="scope-svg-menu-icon cursor" icon={options.reportSummaryOptions.options.endOfReport === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-rso-b">
                                                <div className="labelName">Beginning Of Report</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn" onClick={(e) => this.changeStateRadio("beginningOfReport")}>
                                                        <RadioIcon className="scope-svg-menu-icon cursor" icon={options.reportSummaryOptions.options.beginningOfReport === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-rso-n">
                                                <div className="labelName">No Summary Report</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn" onClick={(e) => this.changeStateRadio("noReportSummary")}>
                                                        <RadioIcon className="scope-svg-menu-icon cursor" icon={options.reportSummaryOptions.options.noReportSummary === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-rso-inc">
                                                <div className="labelName">Include Images In The Report Summary</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("includeImagesInTheReportSummary", options.reportSummaryOptions.options.includeImagesInTheReportSummary, "options.reportSummaryOptions.options.includeImagesInTheReportSummary")}>
                                                        <CheckIcon className="scope-svg-menu-icon cursor" icon={options.reportSummaryOptions.options.includeImagesInTheReportSummary === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-rso-ocnr">
                                                <div className="labelName">Include Original Comment Number In The Report Summary</div>
                                                <div className="manage-actions">
                                                    <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("includeOriginalCommentNumberInTheReportSummary", options.reportSummaryOptions.options.includeOriginalCommentNumberInTheReportSummary, "options.reportSummaryOptions.options.includeOriginalCommentNumberInTheReportSummary")}>
                                                        <CheckIcon className="scope-svg-menu-icon cursor" icon={options.reportSummaryOptions.options.includeOriginalCommentNumberInTheReportSummary === true ? "check" : "uncheck"} />
                                                    </Button>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-rso-sh">
                                                <div className="labelName">Add Summary Header<br/><span className="subLabelName">{options.reportSummaryOptions.options.summaryHeader}</span></div>
                                                <div className="manage-actions">
                                                    <div className="editIcon" onClick={(e) => this.openSecondaryModal(options.reportSummaryOptions.options.summaryHeader, options.reportSummaryOptions.options.summaryHeader, "reportTitlePageOptions", "options.reportSummaryOptions.options.summaryHeader", options.reportSummaryOptions.options.summaryHeader, null)}>
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="listitem nestedlistitem label itemTemplateOptions" key="template-options-rso-sf">
                                                <div className="labelName">Add Summary Footer<br/><span className="subLabelName">{options.reportSummaryOptions.options.summaryFooter}</span></div>
                                                <div className="manage-actions">
                                                    <div className="editIcon" onClick={(e) => this.openSecondaryModal(options.reportSummaryOptions.options.summaryFooter, options.reportSummaryOptions.options.summaryFooter, "reportTitlePageOptions", "options.reportSummaryOptions.options.summaryFooter", options.reportSummaryOptions.options.summaryFooter, null)}>
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Collapse>
                            </ListGroup>
                        </Col>
                        <Col className="pl-2">
                            <ListGroup className={`list list-templateOptions`}>
                                <ListGroup.Item className="listitem label itemTemplateOptions" key="template-options-tableofContents" action onClick={(e) => this.changeState("showTableOfContents", options.showTableOfContents, "options.showTableOfContents")}>
                                    <div className="labelName">Show Table of Contents</div>
                                    <div className="manage-actions">
                                        <Button variant="link" className="collapse-btn">
                                            <CheckIcon className="scope-svg-menu-icon cursor" icon={options.showTableOfContents === true ? "check" : "uncheck"} />
                                        </Button>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="listitem label itemTemplateOptions" key="template-options-esoanpitp" action onClick={(e) => this.changeState("startEachSectionOnNewPageInPdf", options.startEachSectionOnNewPageInPdf, "options.startEachSectionOnNewPageInPdf")}>
                                    <div className="labelName">Start Each Section On A New Page In The PDF</div>
                                    <div className="manage-actions">
                                        <Button variant="link" className="collapse-btn">
                                            <CheckIcon className="scope-svg-menu-icon cursor" icon={options.startEachSectionOnNewPageInPdf === true ? "check" : "uncheck"} />
                                        </Button>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <ListGroup className={`list list-templateOptions headerFooter`}>
                                <ListGroup.Item className="listitem label itemTemplateOptions itemheaders" key="template-options-report-header">
                                    <div className="labelName">
                                        Add Report Header<br/>
                                        <span className="subLabelName">
                                            {reportHeaderEditState ? 
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Report header goes here..."
                                                    style={{ height: '100px' }}
                                                    value={options.reportHeader}
                                                    onChange={(e) => this.onChange(e, "reportHeader", "options.reportHeader")}
                                                />
                                            : options.reportHeader}
                                        </span>
                                    </div>
                                    <div className="manage-actions">
                                        {reportHeaderEditState ? 
                                            <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("reportHeaderEditState", reportHeaderEditState, "root")}>
                                                <CheckIcon className="scope-svg-menu-icon cursor" icon={reportHeaderEditState === true ? "check" : "uncheck"} />
                                            </Button>
                                        :
                                            <div className="editIcon" onClick={(e) => this.changeState("reportHeaderEditState", reportHeaderEditState, "root")}>
                                                <EditIcon />
                                            </div>
                                        }
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="listitem label itemTemplateOptions itemfooters" key="template-options-report-footer">
                                    <div className="labelName">
                                        Add Report Footer<br/>
                                        <span className="subLabelName">
                                            {reportFooterEditState ? 
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Report Footer goes here..."
                                                    style={{ height: '100px' }}
                                                    value={options.reportFooter}
                                                    onChange={(e) => this.onChange(e, "reportFooter", "options.reportFooter")}
                                                />
                                            : options.reportFooter}
                                        </span>
                                    </div>
                                    <div className="manage-actions">
                                        {reportFooterEditState ? 
                                            <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("reportFooterEditState", reportFooterEditState, "root")}>
                                                <CheckIcon className="scope-svg-menu-icon cursor" icon={reportFooterEditState === true ? "check" : "uncheck"} />
                                            </Button>
                                        :
                                            <div className="editIcon" onClick={(e) => this.changeState("reportFooterEditState", reportFooterEditState, "root")}>
                                                <EditIcon />
                                            </div>
                                        }
                                    </div>    
                                </ListGroup.Item>
                                <ListGroup.Item className="listitem label itemTemplateOptions itempageFooter" key="template-options-page-footer">
                                    <div className="labelName">
                                        Add Page Footer<br/>
                                        <span className="subLabelName">
                                            {reportPageFooterEditState ? 
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Footer page content goes here..."
                                                    style={{ height: '100px' }}
                                                    value={options.pageFooter}
                                                    onChange={(e) => this.onChange(e, "pageFooter", "options.pageFooter")}
                                                />
                                            : options.pageFooter}
                                        </span>
                                    </div>
                                    <div className="manage-actions">
                                        {reportPageFooterEditState ? 
                                            <Button variant="link" className="collapse-btn" onClick={(e) => this.changeState("reportPageFooterEditState", reportPageFooterEditState, "root")}>
                                                <CheckIcon className="scope-svg-menu-icon cursor" icon={reportPageFooterEditState === true ? "check" : "uncheck"} />
                                            </Button>
                                        :
                                            <div className="editIcon" onClick={(e) => this.changeState("reportPageFooterEditState", reportPageFooterEditState, "root")}>
                                                <EditIcon />
                                            </div>
                                        }
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    
            </Modal.Body>
            <br />
        <br />
        </Modal>
        
        <Modal
            show={secondaryModalSettings.secondaryModalState}
            onHide={(e) => this.secondaryModalClose("Cancel")}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w secondaryModal"
        >
            <Modal.Header>
            <Modal.Title>Editing {secondaryModalSettings.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {secondaryModalSettings.type === "conditionalRatings" && <Form.Control type="text" value={secondaryModalSettings.value} onChange={(e) =>  this.onChange(e, secondaryModalSettings.field, "secondaryModalSettings.value")} />}
                {secondaryModalSettings.type === "reportTitlePageOptions" && <Form.Control type="text" value={secondaryModalSettings.value} onChange={(e) =>  this.onChange(e, secondaryModalSettings.field, "secondaryModalSettings.value")} />}
            </Modal.Body>
            <Modal.Footer>
            <Btn className={`template-option-edit`}
                label={`Cancel`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="ghost"
                type="primary"
                clickEvent={(e) => this.secondaryModalClose("Cancel")} />
                <Btn className={`template-option-edit`}
                label={`Save`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="filled"
                type="primary"
                clickEvent={(e) => this.secondaryModalClose("Save")} />
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default TemplateOptionsModal;