import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { deleteTemplates, setRowsPerPage } from '../../../redux/actions/appAction';
import { getAllTemplates } from '../../../redux/apiCalls/appData';
import CustomTable from "../../components/tables/CustomTable";
import Spinner from '../../shared/Spinner';
import { Button } from "../../components/button/button.js";

const sortNumberSorting = (items) => {
    return items.map((item, index) =>{
        item.sortNo = index+1;
        return item;
    })
}

class AgreementsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            input: '',
            sort: 'DESC',
            sortIcon: 'caret-down',
            templates: [],
            loading: true,
            totalRecords: 0,
            rowsPerPage: 20,
            user:{}
        };
        this.load = true;
    }
    componentDidMount = async () => {
        this.timer = null;
        this.props.deleteTemplates();
        this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage:this.state.rowsPerPage, 
            defaultTemplate: true, baseTemplate: true}, (data) => {
            this.setState({loading:false, templates: this.props.templates, totalRecords: data.totalRecords, recordsPerPage: this.state.rowsPerPage
            });
        });
        try {
            let session = await sessionService.loadSession();
            this.setState({user:session});
            } catch (error) {
            return null;    
        }
    }

    componentDidUpdate = () => {

    }

    handleChangePage = (page) => {
        this.setState({page});
        this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: page, rowsPerPage:this.state.rowsPerPage, 
            defaultTemplate: true, baseTemplate: true}, (data) => {
            let appendNewRecords = [...this.state.templates, ...data.records]
            this.setState({templates: appendNewRecords, totalRecords: data.totalRecords, page});
        });
    }
    handleSorting = (orderBy) => {
        let sort = this.state.sort === 'DESC' ? 'ASC' : this.state.sort;
        this.setState({sort: sort, loading:true});
        let currentPageRecords = (this.state.page+1 * 3);
        console.log("handleSorting ", sort, currentPageRecords);
        this.props.getAllTemplates({sortKey: orderBy, sortValue:sort, input: this.state.input, page: 0, rowsPerPage: currentPageRecords,//this.state.rowsPerPage, 
            defaultTemplate: true, baseTemplate: true}, (data) => {
            this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords});
        });
    }
    handleChangeRowsPerPage = (newRowSet) => {
        this.setState({ page: 0, loading:true });
        this.props.setRowsPerPage(newRowSet);
        this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: newRowSet, defaultTemplate: true, baseTemplate: true}, (data) => {
            this.props.deleteTemplates();
            this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords});
        });
    }

    editAgreement = (id) =>{
        this.props.history.push(`/agreement/${id}`);
    }

    handleDrop = (e, targetIndex) => {
        const sourceIndex = e.dataTransfer.getData('index');
        let reorderedItems = [...this.state.templates];
        const [movedItem] = reorderedItems.splice(sourceIndex, 1);
        reorderedItems.splice(targetIndex, 0, movedItem);
        console.log("handleDrop",reorderedItems);
        this.setState({
            templates: reorderedItems
        })
    };
    
    render = () => {
        const { loading, rowsPerPage, templates, page, totalRecords } = this.state;
        const { user } = this.props;
        const agreementTemplatesData = !_.isEmpty(templates) ? templates.map((t) => {
            let a = {};
            a.id = t.id;
            a.templateName = t.templateName;
            // a.templateDescription = t.templateDescription;
            return a;
        }) : [];
        return (
            <div>
                <div className="scope-templetes-heading">
                    <div className="scope-templetes-title">Agreements</div>
                    <div className="scope-templetes-filters">
                    </div>
                    { user && user.userType === "admin" && 
                    <Button
                        className="master-agreement"
                        label={`Master Copy`}
                        showLeftIcon={false}
                        showRightIcon={false}
                        size="large"
                        state="default"
                        variant="filled"
                        type="primary"
                        clickEvent={(e) => this.editAgreement("master")}
                    />
                    }
                </div>
                {loading ? <Spinner/> :  !_.isEmpty(agreementTemplatesData) ?
                                        <CustomTable
                                        tableHead={tableHead}
                                        tableData={agreementTemplatesData}
                                        editRow={this.editAgreement}
                                        type="agreements"
                                        page={page}
                                        loading={loading}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={this.handleChangePage}
                                        handleSorting={this.handleSorting}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        totalNumber={totalRecords}
                                        sorting={this.sortingTemplates}
                                        disableDrag={true}
                                    />

                    : <h4 className="scope-templetes-heading">No Template Found</h4> }  
        </div>
        );
    }
}
  
const mapStateToProps = (state) => {
    return {
        templates: state.appData.templates,
        rowsPerPage: state.appData.rowsPerPage,
        user: state.authData.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllTemplates: (object, cb) => dispatch(getAllTemplates(object, cb)),
        deleteTemplates: () => dispatch(deleteTemplates()),
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementsPage);
const tableHead = [
    { id: 'templateName', label: 'Template Name', sortable: false,  width:"80%"},
    { id: 'agreementEdit/delete', label: 'Action', sortable: false },
];