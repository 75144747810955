import axios from 'axios';
import _ from "lodash";
// import Constants from '../config/constants.js';
// import {logoutUser} from "./../redux/actions/user";
import {hist} from "./../index";
import { sessionService } from 'redux-react-session';


let cancelCallTokens = {};
export function apiWithCallbacks(params = {}, onSuccess, onFailure, token) {
    return (dispatch) => {
        // if(params.header && _.isNil(params.header.access_token)) return
        // let mainUrl = Constants.BASE_URL + params.path
        sessionService.loadSession().then((currentSession) => {
            params.access_token = currentSession.token;
            callApi(params, params.url).then(function (res) {
                if (res && res.status === 200) {
                    dispatch(onSuccess(res, params, token))
                }
                else if (res && res.status === 401 && params.path !== "account/login"){
                    sessionService.deleteSession().then(() => {
                        sessionService.deleteUser().then(() => {
                            hist.push("/login");
                        });
                    });
                    // dispatch(logoutUser());
                    // hist.push("/login");
                }
                else {
                    if (res.data && res.data.message) {
                        console.log(res);
                        dispatch(onFailure(res.data.message, params))
                        if(res.data.code === "ERRINV0007"){
                            window.location.replace(`${window.location.origin}/login`);
                        }
                    }
                    else {
                        let response = "Unable to connect to server"
                        dispatch(onFailure(response, params))
                        if(res.data.code === "ERRINV0007"){
                            window.location.replace(`${window.location.origin}/login`);
                        }
                    }
                }
            }).catch(function (error) {
                dispatch(onFailure(error, params))
            })
        }).catch((err) => {
            console.log("e:",err);
            // hist.push("/login");
            window.location.replace(`${window.location.origin}/login`);
            dispatch(onFailure(err, params))
        });
    }
}

function callApi(params, url) {
    // console.log("PARAMS", params, url)
    var CancelToken = axios.CancelToken;
    _.forEach(cancelCallTokens, function(value, key) {
        if(params.path === key && value){
            value.cancel("Cancelled by new call");
        }
    });
    console.log(params);
    cancelCallTokens[params.path] = CancelToken.source();
    return axios({
        method: params.method ? params.method : 'POST',
        url: url,
        data: params.data,
        params: (params.params) ? params.params : {},
        headers: createHeaderObject(params),
        responseType: params.responseType && params.responseType,
        cancelToken: cancelCallTokens[params.path].token
    }).then(function (response) {
        // console.log("in call APi then", response);
        _.forEach(cancelCallTokens, function(value, key) {
            if(params.path === key){
                cancelCallTokens[key] = false;
            }
        });
        return response
    }).catch(function (error) {
        console.log("req failed", error.response);
        _.forEach(cancelCallTokens, function(value, key) {
            if(params.path === key){
                cancelCallTokens[key] = false;
            }
        });
        return error.response
    })
}

function createHeaderObject(params) {
    let header = {}
    if (params.access_token) {
        header = { 'Authorization': 'Bearer ' + params.access_token }
    }
    if(params && params.data && params.data.image){
        header['Content-Type'] = "multipart/form-data";
    }
    console.log(header);
    return header
}