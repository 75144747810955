import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
// import { Trans } from 'react-i18next';
import { Link, withRouter , Redirect} from 'react-router-dom';
import routes from '../routes';
import {LogoutIcon} from "../../app/icons/Icons";
import Dropdown from 'react-bootstrap/Dropdown';
import { sessionService } from 'redux-react-session';
class Sidebar extends Component {

  state = {
    userType : ''
  };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // document.querySelector('#sidebar').classList.remove('active');
    // setTimeout(() => {
    //   document.body.classList.remove('sidebar-icon-only');
    // }, 60);
    

    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  }

  logoutUser  = () => {
    sessionService.deleteSession().then(() => {
      sessionService.deleteUser().then(() => {
          // props.history.history.push('/login');
          window.location.reload();
      });
  });
  }

  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav scope-nav">
          {/* <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={evt =>evt.preventDefault()}>
              <div className="nav-profile-image">
                <img src={ require("../../assets/images/faces/face1.jpg") } alt="profile" />
                <span className="login-status online"></span> 
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2">David Grey. H</span>
                <span className="text-secondary text-small">Project Manager</span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li> */}

          {
            
            routes.map((prop, key) => {
              const usertype = this.props.user ? this.props.user.userType: 'user';
              if( (prop.allowedRoles && prop.isMenuItem && prop.allowedRoles.indexOf(usertype) > -1) || (usertype === "developer" && prop.isMenuItem) ){
                  return (
                    <li key={key} className={ this.isPathActive(prop.path) ? 'nav-item active nav-item-active' : 'nav-item nav-item-li' }>
                  <Link className="nav-link" to={prop.path}>
                  {prop.icon}
                  <span className="menu-title">{prop.name}</span>
                  </Link>
                  </li>
                )
              } else {
                return "";
                // <Redirect from="/" to="/login" />
              }
            })
          }
         
{/*           

          <li className={ this.isPathActive('/templates') ? 'nav-item active nav-item-active' : 'nav-item nav-item-li' }>
            <Link className="nav-link" to="/templates">
              <TemplateIcon />
              
              <span className="menu-title">Templates</span>
            </Link>
          </li>

          <li className={ this.isPathActive('/comments') ? 'nav-item active nav-item-active' : 'nav-item nav-item-li' }>
            <Link className="nav-link" to="/comments">
             
              <CommentIcon />
              <span className="menu-title">Comments</span>
            </Link>
          </li>

          <li className={ this.isPathActive('/users') ? 'nav-item active nav-item-active' : 'nav-item nav-item-li' }>
            <Link className="nav-link" to="/users">
              
              <UserIcon />
              <span className="menu-title">Users</span>
            </Link>
          </li>

          <li className={ this.isPathActive('/plans') ? 'nav-item active nav-item-active' : 'nav-item nav-item-li' }>
            <Link className="nav-link" to="/plans">
             
              <PlanIcon />
              <span className="menu-title">Plans</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/payments') ? 'nav-item active nav-item-active' : 'nav-item nav-item-li' }>
            <Link className="nav-link" to="/payments">
             
              <PaymentIcon />
              <span className="menu-title">Payments</span>
            </Link>
          </li>
         
          <li className={ this.isPathActive('/icons') ? 'nav-item active nav-item-active' : 'nav-item nav-item-li' }>
            <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <BookIcon />
              <span className="menu-title sub-menu-shevron" id="me">Library</span>
              <ShevronIcon className={ this.state.iconsMenuOpen ? 'menu-shevron-expanded' : '' } />
            </div>
            <Collapse in={ this.state.iconsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> 
                <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Templates</Link>
                </li>
                <li className="nav-item"> 
                <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Templates</Link>
                </li>
                <li className="nav-item"> 
                <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Templates</Link>
                </li>
              </ul>
            </Collapse>
          </li>  
          <li>
          <MenuSeparator />
          </li>
          <li className={ this.isPathActive('/logout') ? 'nav-item active nav-item-active' : 'nav-item nav-item-li' }>
            <Link className="nav-link" to="/logout">
             
              <LogoutIcon />
              <span className="menu-title">Logout</span>
            </Link>
          </li>


          
          <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title">Basic UI Elements</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={ this.state.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons">Buttons</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns">Dropdowns</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link' } to="/basic-ui/typography">Typography</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
              <span className="menu-title">Form Elements</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </div>
            <Collapse in={ this.state.formElementsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements">Basic Elements</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <span className="menu-title">Tables</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-table-large menu-icon"></i>
            </div>
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table">Basic Table</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <span className="menu-title">Icons</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-contacts menu-icon"></i>
            </div>
            <Collapse in={ this.state.iconsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Material</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
              <span className="menu-title">Charts</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </div>
            <Collapse in={ this.state.chartsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js">Chart Js</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title">User Pages</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-lock menu-icon"></i>
            </div>
            <Collapse in={ this.state.userPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1">Login</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1">Register</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/lockscreen') ? 'nav-link active' : 'nav-link' } to="/user-pages/lockscreen">Lockscreen</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title">Error Pages</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-security menu-icon"></i>
            </div>
            <Collapse in={ this.state.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/general-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.generalPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('generalPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title">General Pages</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-medical-bag menu-icon"></i>
            </div>
            <Collapse in={ this.state.generalPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/blank-page') ? 'nav-link active' : 'nav-link' } to="/general-pages/blank-page">Blank Page</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="http://bootstrapdash.com/demo/purple-react-free/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <span className="menu-title">Documentation</span>
              <i className="mdi mdi-file-document-box menu-icon"></i>
            </a>
          </li> */}
        </ul>
        <Dropdown.Divider className="sidebar-divider" />
        <ul className="nav scope-nav">
          <li key={"logout-key"} className={ 'nav-item nav-item-li' }>
            <Link className="nav-link" to={""} onClick={this.logoutUser}>
              <LogoutIcon />
              <span className="menu-title">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
    // const  elm = document.getElementById("me");

    // document.querySelectorAll('.sidebar .nav').forEach((el) => {

    //   // el.forEach((elc) => {
    //   //   elc.addEventListener("mouseover", (e) => {
    //   //     e.target.closest(".nav-link").classList.add("currentHover");
    //   //   });
    //   //   elc.addEventListener("mouseout", (e) => {
    //   //     e.target.closest(".nav-link").classList.remove("currentHover");
    //   //   });
    //   // });
    
      
    //   el.addEventListener('mouseover', function(e) {
    //   // console.log("el",el.closest(".nav-link"));

    //     // console.log("addEventListener over", e);
    //     // el(".nav-link").classList.add("currentHover");
    //     // el.target.closest("div ").classList.add('currentHover');
    //     // el.parentNode.classList.add("currentHover");

    //     // console.log(el.target);
    //     // el.classList.add("currentHover");
    //     // e.closest(".menu-expanded").classList.add("currentHover");
    //     // const  elm = document.getElementById("#me");

    //     // console.log("el",elm.closest(".nav li"));
    //     // console.log("e",e.target.closest(".nav > #me"));
    //     // el.parentElement.classList.add("currentHover");
    //     // el.parentNode.vscode-file://vscode-app/c:/Users/Doozie%20Labs/AppData/Local/Programs/Microsoft%20VS%20Code/resources/app/out/vs/code/electron-sandbox/workbench/workbench.htmlclassList.add('currentHover');
    //     // document.querySelectorAll('.sidebar .nav-item li').forEach((par) => {
    //     //   el.classList.add('currentHover');
    //     // });
    //     // if(body.classList.contains('sidebar-icon-only')) {
    //     //   el.classList.add('hover-open');
    //     // }
    //   });
    //   el.addEventListener('mouseout', function(e) {
    //     // el.classList.remove('currentHover');
    //     // e.closest(".menu-expanded").classList.remove("currentHover");
    //     // el.parentElement.classList.remove("currentHover");

    //     // e.target.closest(".nav-link").classList.remove("currentHover");

    //     // el.parentNode.classList.remove('currentHover');

    //     // if(body.classList.contains('sidebar-icon-only')) {
    //     //   el.classList.remove('hover-open');
    //     // }
    //   });
    // });
  }

}

export default withRouter(Sidebar);