/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from 'react-redux';
import { addMedia, saveAgreement, getAgreement } from '../../../redux/apiCalls/appData';
import Spinner from '../../shared/Spinner';
import { Button } from "../../components/button/button.js";
import toast from "react-hot-toast";
import BundledEditor from "../../components/editor/tinyEditor.jsx";
import AgreementImportModal from "../../components/modal/AgreementImportModal.jsx";
class Agreement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate:"",
            loading: true,
            agreement:{},
            currentTemplate: {},
            modalSettings:{
                show:false,
                templates: this.props.templates
            }
        };
        this.quillEditorRef = React.createRef();
    }
    componentDidMount = () => {
        let template_id = this.props.match.params.id;
        this.state.currentTemplate = this.props.templates.find(
            (template) => template.id === template_id
        );
        if(template_id === "master"){
            this.state.currentTemplate = {templateName: "Master"};
        }
        if(template_id !== undefined) {
            this.props.getAgreement({template:template_id}, (data) => {
                if(data){
                    if(this.quillEditorRef && this.quillEditorRef.current){
                        this.quillEditorRef.current.setContent(data.content);
                        this.state.loading = false;
                    }
                    this.state.agreement = data;
                    this.state.selectedTemplate = template_id;
                    this.state.currentTemplate = this.props.templates.find((t) => t.id === template_id);
                    if(template_id === "master"){
                        this.state.currentTemplate = {templateName: "Master"};
                    }
                    this.setState(this.state);
                    // this.quillEditorRef.setEditorContent(data.content);
                    // this.setState({loading:false});
                } else {
                    // this.setState({loading:false});
                }
            });
        }
    }

    componentDidUpdate = () => {
        console.log(this.quillEditorRef);
        if(this.quillEditorRef && this.quillEditorRef.current && this.state.agreement && this.state.agreement.content)
            this.quillEditorRef.current.setContent(this.state.agreement.content);
    }

    onChangeTemplate = (e) => {
        this.setState({selectedTemplate: e.target.value, loading:true});
        this.props.getAgreement({template:e.target.value}, (data) => {
            console.log(data);
            if(data){
                this.quillEditorRef.current.setContent(data.content);
                // this.quillEditorRef.setEditorContent(data.content);
                this.setState({agreement:data, loading:false, selectedTemplate: e.target.value});
                // this.setState({loading:false});
            } else {
                this.setState({loading:false});
            }
        });
    }

    save = (e) => {
        const editorContent = this.quillEditorRef.current.getContent();
        let callData = {
            template: this.state.selectedTemplate,
            agreementType: this.props.user.userType === "inspector" ? "user" : "default",
            content: editorContent
        };
        let template_id = this.props.match.params.id;
        console.log(template_id);
        if(template_id !== undefined && template_id === "master") {
            callData.agreementType = "master";
            delete callData.template;
        }
        this.props.saveAgreement(callData, (data) => {
                    if(data){
                        toast.success("Agreement saved");
                    }
                });
    }

    editorLoaded = (editor) => {
        this.quillEditorRef.current = editor;
        if(this.quillEditorRef && this.quillEditorRef.current && this.state.agreement && this.state.agreement.content){
            setTimeout(() => {
                this.quillEditorRef.current.setContent(this.state.agreement.content);
                this.setState({loading: false});
            }, 500);
        } else {
            this.setState({loading: false});
        }
    }

    openDailog = (e) => {
        this.state.modalSettings.show = true;
        this.state.modalSettings.templates = this.props.templates;
        this.setState(this.state);
    }

    closeModal = (id) => {
        if(id !== "Cancel"){
            this.setState({loading:true});
            this.props.getAgreement({template:id}, (data) => {
                if(data){
                    this.quillEditorRef.current.setContent(data.content);
                    this.state.agreement = data;
                } else {
                    toast.error("Something went wrong! please try again.");
                }
            });
        }
        this.state.loading = false;
        this.state.modalSettings.show = false;
        this.setState(this.state);
    }

    uploadImage = (e, callback) => {
        var myPromise = new Promise( (resolve, reject) => {
            const file = e.target.files[0];
            let formData = new FormData();
            formData.append('mediaType', 'inspectionAgreement');
            formData.append('image', file);
            this.props.addMedia(formData, (data)=> {
                if(data){
                    callback(data[0].originalImgUrl, { title: file.name });
                    resolve("Promise resolved successfully");
                }else{
                    // TODO:::
                    reject(Error("Promise rejected"));
                }
            });
         });
         toast.promise(myPromise, {
            loading: 'Image is being uploaded...',
            success: 'Image uploaded successfully.',
            error: 'Something went wrong, please try logging out and login again!',
          });
    }

    render = () => {
        // const { templates } = this.props;
        return (
            <div style={{overflowX:'hidden'}}>
                {this.state.loading && <Spinner />}
                <div className="container">
                    <div className="row mt-3 mb-3 edit-template-head">
                        <div className="col-lg-8">
                            <div className="scope-templetes-title">
                                Agreement ({this.state.currentTemplate.templateName})
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 col-sm-8">
                            <div className="row margin-top-10">
                                <div className="col-lg-6 col-sm-4 col-4">
                                    <Button
                                        className="save-agreement"
                                        label={`Import`}
                                        showLeftIcon={true}
                                        showRightIcon={false}
                                        size="small"
                                        state="default"
                                        variant="outlined"
                                        type="secondary"
                                        clickEvent={(e) => this.openDailog(e)}
                                    />
                                </div>
                                <div className="col-lg-6 col-sm-4 col-4">
                                    <Button
                                        className="save-agreement"
                                        label={`Save`}
                                        showLeftIcon={true}
                                        showRightIcon={false}
                                        size="small"
                                        state="default"
                                        variant="filled"
                                        type="primary"
                                        clickEvent={(e) => this.save(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row row-flex">
                    <div className="col-10">
                        <select name='templates' className="form-control" onChange={(e) => this.onChangeTemplate(e)}>
                            {templates.map((template, i)=> {
                                return <option value={template.id}>{template.templateName}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-2">
                    <Button
                        className="save-agreement"
                        label={`Save`}
                        showLeftIcon={true}
                        showRightIcon={false}
                        size="small"
                        state="default"
                        variant="filled"
                        type="primary"
                        clickEvent={(e) => this.save(e)}
                    />
                    </div>
                </div> */}
                <div className="row">
                    <div className="col quileditor-container unlayer-editor-container ml-4 mr-4">
                        <BundledEditor
                            onInit={(evt, editor) => this.editorLoaded(editor)}
                            initialValue='<p>This is the initial content of the editor.</p>'
                            init={{
                                height: 550,
                                min_height:500,
                                max_height:800,
                                menubar: false,
                                elementpath: false,
                                branding: false,
                                resize: false,
                                plugins: [
                                    'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                                    'searchreplace', 'preview', 'table', 'wordcount', 'code', 'table', 'quickbars', 'pagebreak', 'save', 'preview'
                                ],
                                toolbar: 'blocks fontsize | ' +
                                    'bold italic underline strikethrough hr forecolor backcolor removeformat | alignleft aligncenter ' +
                                    'alignright alignjustify | lineheight | bullist numlist outdent indent | ' +
                                    'image link table refVars preview',
                                quickbars_selection_toolbar: 'bold italic underline fontsize | forecolor backcolor | quicklink',
                                quickbars_insert_toolbar: false,
                                quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
                                toolbar_mode: 'wrap',
                                // toolbar_sticky: true,
                                // toolbar_sticky_offset: 100,
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                advlist_bullet_styles: 'square',
                                advlist_number_styles: 'lower-alpha,lower-roman,upper-alpha,upper-roman',
                                file_picker_types: 'image',
                                file_picker_callback: (callback, value, meta) => {
                                    const input = document.createElement('input');
                                    input.setAttribute('type', 'file');
                                    input.setAttribute('accept', 'image/*');
                                    input.addEventListener('change', (e) => this.uploadImage(e, callback));
                                    // input.addEventListener('change', (e) => {
                                    //     const file = e.target.files[0];
                                    //     let formData = new FormData();
                                    //     formData.append('mediaType', 'inspectionAgreement');
                                    //     formData.append('image', file);
                                    //     this.props.addMedia(formData, (data)=> {
                                    //         if(data){
                                    //             callback(data[0].originalImgUrl, { title: file.name });
                                    //         }else{
                                    //             // TODO:::
                                    //         }
                                    //     });
                                    // });
                                    input.click();
                                },
                                setup: (editor) => {
                                    editor.ui.registry.addMenuButton('refVars', {
                                    text: 'Variables',
                                    fetch: function (callback) {
                                        var items = [
                                            {
                                                type: 'nestedmenuitem',
                                                text: 'Client',
                                                getSubmenuItems: function () {
                                                return [
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Full Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.full_name}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'First Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.first_name}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Last Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.last_name}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Email',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.email}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Phone',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.phone}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Address',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.address}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Signature',
                                                        onAction: function () {
                                                            editor.insertContent('{{client.sign_field}}');
                                                        },
                                                    }
                                                ];
                                                }
                                            },
                                            {
                                                type: 'nestedmenuitem',
                                                text: 'Inspector',
                                                getSubmenuItems: function () {
                                                return [
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Full Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.full_name}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'First Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.first_name}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Last Name',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.last_name}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Email',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.email}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Phone',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.phone}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Company Logo',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.company_logo}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Address',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.address}}');
                                                        },
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Signature',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspector.sign_field}}');
                                                        },
                                                    },
                                                ];
                                                }
                                            },
                                            {
                                                type: 'nestedmenuitem',
                                                text: 'Inspection',
                                                getSubmenuItems: function () {
                                                return [
                                                    {
                                                        type: 'menuitem',
                                                        text: 'ID',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.id}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Date',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.date}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Type',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.type}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Fees',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.fees}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Payment Method',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.payment_method}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: "Today's Date",
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.todays_date}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Address',
                                                        onAction: function () {
                                                            editor.insertContent('{{inspection.address}}');
                                                        },
                                                    }
                                                ];
                                                }
                                            },
                                            {
                                                type: 'nestedmenuitem',
                                                text: 'Invoice',
                                                getSubmenuItems: function () {
                                                return [
                                                    {
                                                        type: 'menuitem',
                                                        text: 'ID',
                                                        onAction: function () {
                                                            editor.insertContent('{{invoice.id}}');
                                                        }
                                                    },
                                                    {
                                                        type: 'menuitem',
                                                        text: 'Due Date',
                                                        onAction: function () {
                                                            editor.insertContent('{{invoice.due_date}}');
                                                        }
                                                    }
                                                ];
                                                }
                                            },
                                        ];
                                        callback(items);
                                    }
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <AgreementImportModal modalSettings={this.state.modalSettings} close={this.closeModal} />
            </div>
        );
    }
}
 
const mapStateToProps = (state) => {
    return {
        templates: state.appData.templates,
        rowsPerPage: state.appData.rowsPerPage,
        user: state.authData.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addMedia: (object, cb) => dispatch(addMedia(object, cb)),
        saveAgreement: (template, cb) => dispatch(saveAgreement(template, cb)),
        getAgreement: (template, cb) => dispatch(getAgreement(template, cb))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agreement);