import { Formik } from "formik";
import _ from "lodash";
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { forgotPassword } from '../../redux/apiCalls/authData';
import { LoginLogo } from '../icons/Icons';
import Spinner from '../shared/Spinner';
import { validateEmail, validatePassword } from "./../../helpers/helpers";

export class Reset extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
      loading:false,
      showPassword: true,
      mailMessage: ''
    };
  }

  componentDidMount = async () => {
   
  }

  toggleSwitch = (e) => {
    this.state.showPassword = !this.state.showPassword;
    this.setState(this.state);
  }
  
  submitForm = (values, { setSubmitting }) => {
    
    this.setState({loading:true});
    let data = {
        email: values.email,
        type: 'web'
    }
    this.props.forgotPassword(data, (res) => {
      this.setState({loading:false});
        if(res.status != 200){
            setSubmitting(false);
        } else {
          this.setState({mailMessage:res.data});
        }
    });
    
}
  render() {
    return (
      <div>
      <div className="login-container">
          <div className="width60">
            {this.state.loading ? <Spinner/> : <></>}
          </div> 
        <div className="auth-form-login">      
          <div className="welcome-back-login">
            <LoginLogo className="img-logo" />
          </div>
          <div className="scope-form">
            <div className="login-with">Forgot Password</div>
            <div className="login-form">
            <Formik
                    initialValues={{ email: "", password: "", }}
                    validate={values => {
                        let errors = {};
                        let email = validateEmail(values.email);
                        if(!_.isNil(email)) errors.email = email;
                        return errors;
                    }}
                    onSubmit={this.submitForm}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        clickHandler
                    }) => (
                <Form className="email-box">
                  <div className="email-title">Email</div>
                  <Form.Group className="email-box">
                    <Form.Control 
                    id="email"
                    type="email"
                    placeholder="Email" 
                    // size="lg" 
                    className="email-field" 
                    onChange={handleChange} 
                    onBlur={handleBlur} 
                    value={values.email}
                    />
                  </Form.Group>
                  {errors.email && touched.email ? <div className="text-danger mb-2">{errors.email}</div> : null}
                  {this.state.mailMessage != '' ? <div className="text-success mb-2">{this.state.mailMessage}</div> : null}
            <button type="submit" disabled={isSubmitting} onClick={handleSubmit} className="login-button">
            <div  className="login-button-title">Reset</div>
            </button>
            </Form>
                )}
                </Formik>
              
            </div>
            
          </div>
        </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.authData.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
      forgotPassword: (template, cb) => dispatch(forgotPassword(template, cb)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reset)