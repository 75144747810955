import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'; // Import the BrowserRouter component
import App from './app/App';
// import "./i18n";
import { createBrowserHistory } from 'history';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './redux/store';
import * as serviceWorker from './serviceWorker';

const { store, persistor } = configureStore(window.REDUX_INITIAL_DATA);
export const hist = createBrowserHistory();

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router> {/* Wrap your App with the BrowserRouter */}
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Router>
    <Toaster 
    position="bottom-right"
    reverseOrder={false}
    gutter={8}
    containerClassName=""
    containerStyle={{}}
    toastOptions={{
      // Define default options
      className: '',
      duration: 5000,
      style: {
        background: '#363636',
        color: '#fff',
      },
  
      // Default options for specific types
      success: {
        duration: 3000,
        theme: {
          primary: 'green',
          secondary: 'black',
        },
        style: {
          background: 'green',
        }
      },
      error: {
        duration: 3000,
        theme: {
          primary: 'red',
          secondary: 'black',
        },
        style: {
          background: 'red',
        },
      }
    }}
    />
  </ReduxProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
