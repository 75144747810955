import React, { useEffect, useState } from "react";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";
import { connect } from 'react-redux';
import { sendReport } from '../../../redux/apiCalls/appData';
import { SendIcon2 } from '../../icons/Icons';
import { Button } from "../button/button";

const PREFIX = 'SendReportDialog';

let emails = [];

function SendReport(props) {
  const [emailField, setemailField] = useState("");
  const [checkEmails, setCheckEmails] = useState(emails);
  const [message, setMessage] = useState("");
//   const classes = useStyles();

  const inspectionID = props.inspectionId;
  const [selectedEmails, setselectedEmails] = useState({selectedEmail:[]});
  var tempTextEmails = [];
  const { onClose, selectedValue, open } = props
  useEffect(() => {
    setCheckEmails(emails)
    setselectedEmails({selectedEmail:[emails[0]]});
  }, [emails]);

  const handleDublicateCheck = (val) => {
    var checked = selectedEmails['selectedEmail'].some(item => val === item);
    return checked;
    };

  const handleClose = () => {
    setemailField("");
    setselectedEmails({selectedEmail:[emails[0]]});
    onClose(selectedValue)
  }

  const setEmailList = (e) => {
    const { value, checked } = e.target; 
    const { selectedEmail } = selectedEmails; 
    if (checked) { 
        setselectedEmails({
          selectedEmail:[...selectedEmail, value]
        });
    } else { 
      setselectedEmails({
        selectedEmail: selectedEmail.filter((e) => e !== value)
      });
    } 
  }

  const setText = (e) => {
    setemailField(e.target.value);
    tempTextEmails=[];
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (e.target.value && !emailRegex.test(e.target.value)) {
      setMessage("Error! you have entered invalid email.");
    } else {
      setemailField(e.target.value);
      setMessage("");
    }
  }
  const handleSendEmailClose = () => {
    if(emailField && !handleDublicateCheck(emailField)){
        selectedEmails['selectedEmail'].push(emailField);
    }
    props.sendit({inspection: inspectionID, email: selectedEmails['selectedEmail']});
  }

  const handleAddEmailField = e => {

    var isAlreadyInTheList = checkEmails.some(item => emailField === item);
    if(emailField && !isAlreadyInTheList){
      console.log("handleAddEmailField", emailField);
      setCheckEmails(checkEmails =>[...checkEmails, emailField]);
      setemailField("");
    } else {
      setMessage("Error! Already in the list.");
    }
  }

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" keyboard={false}>
    <Modal.Header closeButton>
      <Modal.Title>Send Report</Modal.Title>
    </Modal.Header>
    <Modal.Body>Report will be sent to selected emails
    {checkEmails.map((email,i) => (
            <div key={email+i} className={email+i}>
        <div >
            <input
            className="margintop20 marginright10"
              type="checkbox"
              name="selectEmail"
              value= {email}
            onChange={setEmailList}
            defaultChecked= {i == 0 ? true : false}
            />
            {email}
          </div>
            </div>
            ))}
      <div style = {{ color: "red", fontSize: 12, padding: "20 0 20 0" }}> {message} </div>
      <div className="input-group">
        
        <input 
            margin="dense"
            name="customemail"
            id="custom"
            className="email-field form-control"
            type="email"
            placeholder="email address"
            onChange={setText}
            label="Custom email"
            value={emailField}
            />
            <div className="input-group-append">
                <span className="input-group-text add-email cursor primary-color" onClick={handleAddEmailField} > + </span>
            </div>
      </div>

    </Modal.Body>
    <Modal.Footer>
                <Button
                className="button-instance"
                label="Cancel"
                size="small"
                state="pressed"
                style="outlined"
                type="primary"
                showIcon={false}
                showRightIcon={false}
                clickEvent={handleClose}
                />
        {/* <Button onClick={handleClose} color="secondary"> */}
            {/* Cancel */}
        {/* </Button> */}
        {/* <Button disabled={selectedEmails['selectedEmail'] < 1} onClick={handleSendEmailClose} color="primary">
            Send
        </Button> */}
        <Button
                className="button-instance"
                label="Send"
                size="small"
                disabled= {selectedEmails['selectedEmail'] < 1 || props.buttonState}
                state="default"
                variant="filled"
                type="primary"
                showIcon={false}
                showRightIcon={false}
                clickEvent={handleSendEmailClose}
                />
    </Modal.Footer>
  </Modal>
  );
}

class ReportSent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inspectionId:props.row.id,
      inspection:props.row,
      contacts:this.props.contacts,
      sent:[],
      isOpen: false,
      loading: true,
      buttonState: false
    };
  }
 
  handleIdsToContactObject = (id, emails) => {
        this.state.contacts.map(item =>{
            if(id == item.id){
                emails.push(item.email);
            }
        });
  }
  
  handleSendEmails = (send) => {
      this.props.sendReport(send, (data) => {
        console.log(data);
    });
  }

handleClickOpen = () => {
    emails = [];
    this.handleIdsToContactObject(this.state.inspection.client, emails);
    this.handleIdsToContactObject(this.state.inspection.buyerAgent, emails);
    this.handleIdsToContactObject(this.state.inspection.sellerAgent, emails);
    emails.push(this.state.inspection.user.email);
    this.openDialog();
}

  openDialog = () => {
    this.setState({ isOpen: true });
  };

  closeDialog = () => {
    this.setState({ isOpen: false });
  };

  sendEmails =(data) => {
    this.setState({buttonState:true});
    this.props.sendReport({inspectionId: data.inspection, email: data.email}, (datax) => {
      if(data){
        toast.success(`Report has been sent to selected emails (${data.email.join(', ')})`);
      }
      this.setState({buttonState:false});
      console.log("got response ---- ",datax);
      this.closeDialog();
    });
  }

  render() {
    return (
      <div>
          <span color="primary" className="template-action-button cursor" onClick={this.handleClickOpen}>
            <SendIcon2 />
          </span>
          <SendReport
            inspectionId={this.state.inspectionId}
            sendit={this.sendEmails}
            email={emails}
            open={this.state.isOpen}
            buttonState={this.state.buttonState}
            onClose={this.closeDialog} />
      </div>
    );
  }
  

}
const mapStateToProps = (state) => {
  return {
      reportSent: state.appData.reportSent
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
      sendReport: (object, cb) => dispatch(sendReport(object, cb)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportSent);