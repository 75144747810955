/* eslint-disable react/no-direct-mutation-state */
import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { getEmailHooks } from '../../../redux/apiCalls/appData';
import Spinner from '../../shared/Spinner';
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { EditIcon } from "../../icons/Icons";

const sortNumberSorting = (items) => {
    return items.map((item, index) =>{
        item.sortNo = index+1;
        return item;
    })
}

class EmailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            input: '',
            sort: 'DESC',
            sortIcon: 'caret-down',
            hooks: [],
            loading: true,
            totalRecords: 0,
            rowsPerPage: 20,
            user:{}
        };
    }
    componentDidMount = async () => {
        this.props.getEmailHooks({}, (data)=> {
            console.log(data);
            if(data){
                this.state.hooks = data;
            }
            this.state.loading = false;
            this.setState(this.state);
        });
    }

    componentDidUpdate = () => {

    }
    
    editEmail = (hook) => {
        this.props.history.push(`/email/${hook}`);
    }

    render = () => {
        const { hooks } = this.state;
        return (
            <div>
                <div className="scope-templetes-heading email-hooks">
                    <div className="scope-templetes-title">Email Hooks</div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="hooks_description col p-3">
                            Automate Email Notifications: Streamline email dispatches triggered by specific events such as sending agreements. Configure tailored emails to be automatically sent, enhancing communication efficiency for various user interactions.
                        </div>
                    </div>
                </div>
                {this.state.loading ? <Spinner /> :
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <ListGroup className="list email-hook-list">
                                {
                                    hooks.length > 0 ?
                                    hooks.map((hook, i)=> {
                                        return (
                                            <ListGroup.Item key={i} className="listitem email-hook-list-item" action onClick={(e) => this.editEmail(hook.hook)}>
                                                <div className="labelName">
                                                    {hook.label}
                                                    <br/>
                                                    <span className={"subLabelName"}>{hook.description}</span>
                                                </div>
                                                {hook.sentTo && hook.sentTo.to && 
                                                <div className="sentTo">
                                                    <div className="sent_label">Email will be sent to followings</div>
                                                    <div className="to">To: {hook.sentTo.to.join(',')}</div>
                                                    {hook.sentTo.cc && <div className="cc">CC: {hook.sentTo.cc.join(',')}</div>}
                                                </div>
                                                }
                                                <div className="manage-actions">
                                                <div className="editIcon">
                                                    <EditIcon /> 
                                                </div>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })
                                :
                                <ListGroup.Item key={'noItem'}>
                                    No Any hook available for now
                                </ListGroup.Item>
                                }
                            </ListGroup>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}
  
const mapStateToProps = (state) => {
    return {
        user: state.authData.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getEmailHooks: (object, cb) => dispatch(getEmailHooks(object, cb))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailPage);