// types of action
const Types = {
    LOGIN: 'LOGIN',
};

// actions
export const setUser = user => ({
    type: Types.LOGIN,
    payload: user
});
export default {
    Types
};
