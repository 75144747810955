/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconexFilledStar } from "../Icons/IconexFilledStar";

export const Button = ({
  showButtonLabel = true,
  label = "Button Label",
  showRightIcon = true,
  showIcon = true,
  showLeftIcon = true,
  leftIcon,
  variant,
  type,
  state,
  size,
  className,
  clickEvent,
  disabled=false
}) => {
  return (
    <button disabled={disabled} className={`button custom-btn ${size} ${state} ${type} ${variant} ${className} ${disabled && 'disabled'}`} onClick={clickEvent}>
      {showLeftIcon && (
        leftIcon
      )}

      {showButtonLabel && <div className="button-label">{label}</div>}

      {showRightIcon && (
        <IconexFilledStar
          className={`${size === "large" && "class-3"} ${
            ((size === "small" && state === "default" && variant === "error") ||
              (size === "small" && state === "default" && variant === "filled") ||
              (size === "small" && state === "default" && variant === "ghost") ||
              (size === "small" && state === "default" && variant === "outlined" && type === "secondary") ||
              (size === "small" && state === "default" && type === "error") ||
              (size === "small" && state === "focused" && variant === "error") ||
              (size === "small" && state === "focused" && variant === "filled") ||
              (size === "small" && state === "focused" && variant === "ghost") ||
              (size === "small" && state === "focused" && variant === "outlined" && type === "secondary") ||
              (size === "small" && state === "focused" && type === "error") ||
              (size === "small" && state === "pressed")) &&
            "class-9"
          } ${type === "primary" && state === "disabled" && variant === "ghost" && size === "small" && "class-10"} ${
            state === "default" && type === "primary" && variant === "outlined" && size === "small" && "class-11"
          } ${type === "primary" && variant === "outlined" && size === "small" && state === "focused" && "class-12"} ${
            ((size === "small" && state === "disabled" && variant === "filled") ||
              (size === "small" && state === "disabled" && variant === "ghost" && type === "secondary") ||
              (size === "small" && state === "disabled" && variant === "outlined" && type === "primary") ||
              (size === "small" && state === "disabled" && variant === "outlined" && type === "secondary")) &&
            "class-13"
          } ${size === "small" && state === "disabled" && (variant === "error" || type === "error") && "class-14"}`}
          star={
            (size === "large" && state === "default" && variant === "error") ||
            (size === "large" && state === "default" && variant === "filled") ||
            (size === "large" && state === "focused" && variant === "error") ||
            (size === "large" && state === "focused" && variant === "filled") ||
            (size === "large" && state === "pressed" && variant === "error") ||
            (size === "large" && state === "pressed" && variant === "filled")
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-128.svg"
              : (size === "small" && state === "default" && variant === "error") ||
                (size === "small" && state === "default" && variant === "filled") ||
                (size === "small" && state === "focused" && variant === "error") ||
                (size === "small" && state === "focused" && variant === "filled") ||
                (size === "small" && state === "pressed" && variant === "error") ||
                (size === "small" && state === "pressed" && variant === "filled")
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-127.svg"
              : (size === "large" && state === "default" && variant === "outlined" && type === "primary") ||
                (size === "large" && state === "focused" && variant === "ghost" && type === "primary") ||
                (size === "large" && state === "focused" && variant === "outlined" && type === "primary") ||
                (size === "large" && state === "pressed" && variant === "ghost" && type === "primary") ||
                (size === "large" && state === "pressed" && variant === "outlined" && type === "primary")
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-105.svg"
              : (size === "small" && state === "focused" && variant === "ghost" && type === "primary") ||
                (size === "small" && state === "pressed" && variant === "ghost" && type === "primary") ||
                (size === "small" && state === "pressed" && variant === "outlined" && type === "primary")
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-103.svg"
              : size === "large" && state === "disabled" && ["primary", "secondary"].includes(type)
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-97.svg"
              : type === "primary" && state === "disabled" && variant === "ghost" && size === "small"
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-94.svg"
              : type === "error" && size === "large"
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-91.svg"
              : state === "default" && type === "primary" && variant === "outlined" && size === "small"
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-88.svg"
              : type === "error" && size === "small"
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-87.svg"
              : type === "primary" && variant === "outlined" && size === "small" && state === "focused"
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-72.svg"
              : (size === "small" && state === "disabled" && variant === "error") ||
                (size === "small" && state === "disabled" && variant === "filled") ||
                (size === "small" && state === "disabled" && variant === "ghost" && type === "secondary") ||
                (size === "small" && state === "disabled" && variant === "outlined" && type === "primary") ||
                (size === "small" && state === "disabled" && variant === "outlined" && type === "secondary")
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-65.svg"
              : (size === "large" && state === "default" && variant === "ghost") ||
                (size === "large" && state === "default" && variant === "outlined" && type === "secondary") ||
                (size === "large" && state === "focused" && variant === "ghost" && type === "secondary") ||
                (size === "large" && state === "focused" && variant === "outlined" && type === "secondary") ||
                (size === "large" && state === "pressed" && variant === "ghost" && type === "secondary") ||
                (size === "large" && state === "pressed" && variant === "outlined" && type === "secondary")
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-61.svg"
              : (size === "small" && state === "default" && variant === "ghost") ||
                (size === "small" && state === "default" && variant === "outlined" && type === "secondary") ||
                (size === "small" && state === "focused" && variant === "ghost" && type === "secondary") ||
                (size === "small" && state === "focused" && variant === "outlined" && type === "secondary") ||
                (size === "small" && state === "pressed" && variant === "ghost" && type === "secondary") ||
                (size === "small" && state === "pressed" && variant === "outlined" && type === "secondary")
              ? "https://c.animaapp.com/NHnYvKQm/img/star-2-59.svg"
              : undefined
          }
          starClassName={`${
            ((size === "large" && state === "disabled") ||
              (size === "large" && state === "focused" && variant === "outlined") ||
              (size === "small" && state === "disabled" && variant === "error") ||
              (size === "small" && state === "disabled" && variant === "filled") ||
              (size === "small" && state === "disabled" && variant === "ghost" && type === "secondary") ||
              (size === "small" && state === "disabled" && variant === "outlined") ||
              (size === "small" && state === "focused" && variant === "outlined" && type === "secondary") ||
              (size === "small" && state === "focused" && type === "error") ||
              state === "default" ||
              (state === "focused" && variant === "error") ||
              (state === "focused" && variant === "filled") ||
              (state === "focused" && variant === "ghost") ||
              state === "pressed") &&
            "class"
          } ${
            ((size === "small" && state === "disabled" && variant === "ghost" && type === "primary") ||
              (size === "small" && state === "focused" && variant === "outlined" && type === "primary")) &&
            "class-2"
          }`}
          starClassNameOverride={`${
            ((size === "small" && state === "disabled" && variant === "ghost" && type === "primary") ||
              (size === "small" && state === "focused" && variant === "outlined" && type === "primary")) &&
            "class"
          }`}
        />
      )}
    </button>
  );
};

Button.propTypes = {
  showButtonLabel: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  showRightIcon: PropTypes.bool,
  showIcon: PropTypes.bool,
  showLeftIcon: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "filled", "error", "ghost"]),
  type: PropTypes.oneOf(["primary", "secondary", "error"]),
  state: PropTypes.oneOf(["focused", "disabled", "pressed", "default"]),
  size: PropTypes.oneOf(["large", "small"]),
  clickEvent: PropTypes.func
};
