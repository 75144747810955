/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import {TrashIcon, LeftArrowIcon} from "../../icons/Icons";
import Modal from 'react-bootstrap/Modal';
import {Button as Btn} from "../../components/button/button";
import Dropdown from 'react-bootstrap/Dropdown';
import toast, { Toaster } from 'react-hot-toast';

class AddRefItemModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalSettings: {
          show:false,
          templates:[],
          itemIndex:null,
          view:"menu",
          ref: {},
          currentTempId: "",
          item:{}
        },
        inspectionKeys:[
            {
                "key": "InspectionAddress",
                "name": "Inspection Address (Complete address)",
                "type": ["text"]
            },
            {
                "key": "InspectionAddressStreet",
                "name": "Inspection Address (Street only)",
                "type": ["text"]
            },
            {
                "key": "InspectionAddressCity",
                "name": "Inspection Address (City only)",
                "type": ["text"]
            },
            {
                "key": "InspectionAddressZipcode",
                "name": "Inspection Address (Zipcode only)",
                "type": ["text"]
            },
            {
                "key": "InspectionAddressCountry",
                "name": "Inspection Address (Country only)",
                "type": ["text"]
            },
            {
                "key": "InspectionAddressState",
                "name": "Inspection Address (State only)",
                "type": ["text"]
            },
            {
                "key": "InspectionDate",
                "name": "Inspection Date",
                "type": ["timestamp"]
            },
            {
                "key": "ClientName",
                "name": "Client Name",
                "type": ["text"]
            },
            {
                "key": "ClientPhone",
                "name": "Client Phone",
                "type": ["phone"]
            },
            {
                "key": "ClientEmail",
                "name": "Client Email",
                "type": ["email"]
            },
            {
                "key": "BuyerName",
                "name": "Buyer Name",
                "type": ["text"]
            },
            {
                "key": "BuyerPhone",
                "name": "Buyer Phone",
                "type": ["phone"]
            },
            {
                "key": "BuyerEmail",
                "name": "Buyer Email",
                "type": ["email"]
            },
            {
                "key": "SellerName",
                "name": "Seller Name",
                "type": ["text"]
            },
            {
                "key": "SellerPhone",
                "name": "Seller Phone",
                "type": ["phone"]
            },
            {
                "key": "SellerEmail",
                "name": "Seller Email",
                "type": ["email"]
            }
        ],
        inspectorKeys: [
            {
                "key": "InspectorName",
                "name": "Inspector Name",
                "type": ["text"]
            },
            {
                "key": "InspectorEmail",
                "name": "Inspector Email",
                "type": ["email"]
            },
            {
                "key": "InspectorPhone",
                "name": "Inspector Phone",
                "type": ["phone"]
            },
            {
                "key": "InspectorAddress",
                "name": "Inspector Address (Complete address)",
                "type": ["text"]
            },
            {
                "key": "InspectorAddressStreet",
                "name": "Inspector Address (Street only)",
                "type": ["text"]
            },
            {
                "key": "InspectorAddressCity",
                "name": "Inspector Address (City only)",
                "type": ["text"]
            },
            {
                "key": "InspectorAddressZipcode",
                "name": "Inspector Address (Zipcode only)",
                "type": ["text"]
            },
            {
                "key": "InspectorAddressCountry",
                "name": "Inspector Address (Country only)",
                "type": ["text"]
            },
            {
                "key": "InspectorAddressState",
                "name": "Inspector Address (State only)",
                "type": ["text"]
            },
            {
                "key": "InspectorLicense",
                "name": "Inspector License",
                "type": ["text"]
            },
            {
                "key": "InspectorOrganization",
                "name": "Inspector Organization",
                "type": ["organization"]
            },
            {
                "key": "InspectorWebsite",
                "name": "Inspector Website",
                "type": ["website"]
            },
            {
                "key": "InspectorSignature",
                "name": "Inspector Signature",
                "type": ["signature"]
            },
            {
                "key": "InspectorInitials",
                "name": "Inspector Initials",
                "type": ["text"]
            }
        ],
        selectedTemplate:{},
        selectedSectionIndex:null,
        selectedSection:{},
        selectedSubSection:{},
        selectedSubSectionIndex:null
    }
  }
  componentDidMount = async () => {
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.modalSettings = nextProps.modalSettings;
    this.setState( this.state );
  }

  onChange = (e) => {
    this.state.modalSettings.value = e.target.value;
    this.setState(this.state);
  }

  changeView = (view) => {
    this.state.modalSettings.view = view;
    this.setState(this.state);
  }

  nestingTemplate = (templateId, i, path, item, type) => {
    if(path === "template"){
      this.state.selectedTemplate = this.state.modalSettings.templates.find(
        (template) => template.id === templateId
      );
      this.state.modalSettings.view = "sections";
    }
    if(path === "section"){
      this.state.selectedSection = this.state.selectedTemplate.templateContent[i];
      this.state.selectedSectionIndex = i;
      this.state.modalSettings.view = "items";
    }
    if(path === "selectedItem"){
      if(type === "item"){
        let itemTrail = {
          ref: this.state.selectedTemplate.templateName
        };
        if(item.uid){
          itemTrail.uid = item.uid;
        }
        if(this.state.selectedSectionIndex != null && this.state.selectedSubSectionIndex == null){
          itemTrail.path = `${this.state.selectedSection.sectionLabel} > ${item.itemLabel}`
        } else {
          itemTrail.path = `${this.state.selectedSection.sectionLabel} > ${this.state.selectedSubSection.sectionLabel} > ${item.itemLabel}`
        }
        this.state.modalSettings.item.itemTrail = {
          ...this.state.modalSettings.item.itemTrail,
          [this.state.selectedTemplate.id]: itemTrail
        };
      } else {
        if(!item.type.includes(this.state.modalSettings.item.itemType)){
          toast.error("This reference can not be mapped to current item due to difference in their types", {position:"bottom-left"});
          return;
        }
        let itemTrail = {
          ref: templateId.key,
          uid: type,
          path: `${type} > ${item.name}`
        }
        this.state.modalSettings.item.itemTrail = {
          ...this.state.modalSettings.item.itemTrail,
          [type]: itemTrail
        };
      }
    }
    this.setState(this.state);
    if(path === "selectedItem"){
      this.props.close("Save", this.state.modalSettings);
    }
  }

  changeSubSection = (e) => {
    let index = e.target.value;
    if(index === ""){
      this.state.selectedSection = this.state.selectedTemplate.templateContent[this.state.selectedSectionIndex];
      this.state.selectedSubSection = {};
      this.state.selectedSubSectionIndex = null;
      this.state.modalSettings.view = "items";
    } else {
      // this.state.selectedSectionIndex = index;
      this.state.selectedSubSection = this.state.selectedTemplate.templateContent[this.state.selectedSectionIndex]["subSections"][index];
      this.state.selectedSubSectionIndex = index;
      this.state.modalSettings.view = "subitems";
    }
    this.setState(this.state);
  }

  stepBack = (view) => {
    if(view === "templates" || view === "inspectionReference" || view === "inspectorReference"){
      this.state.modalSettings.view = "menu";
    }
    if(view === "sections"){
      this.state.modalSettings.view = "templates";
    }
    if(view === "items" || view === "subitems"){
      this.state.modalSettings.view = "sections";
    }
    this.setState(this.state);
  }

  removeTrail = (key) => {
      delete this.state.modalSettings.item.itemTrail[key];
      this.setState(this.state);
  }

  render = () => {
    const {modalSettings} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            // onHide={() => this.props.close(item)}
            dialogClassName="modal-90w itemEdit modal-scope"
            aria-labelledby="template-item-edit"
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
            <Modal.Title id="template-item-ref-add">
              {modalSettings.view !== "menu" && <>
              <div className="backIcon" onClick={(e) => this.stepBack(modalSettings.view)}>
                <LeftArrowIcon/>
              </div>
              </>}
                Adding Reference to {modalSettings.item.itemLabel}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup className="list">
              {modalSettings.view === "menu" && <>
                <ListGroup.Item action onClick={(e) => this.changeView("templates")}>
                  Link other template values
                </ListGroup.Item>
                <ListGroup.Item action onClick={(e) => this.changeView("inspectionReference")}>
                  Link inspection values
                </ListGroup.Item>
                <ListGroup.Item action onClick={(e) => this.changeView("inspectorReference")}>
                  Link inspector values
                </ListGroup.Item>
              </>}
              {modalSettings.view === "templates" && <>
              {modalSettings.templates.map((template, i)=> {
                return (
                  <ListGroup.Item action disabled={template.id === modalSettings.currentTempId ? true : false} onClick={(e) => this.nestingTemplate(template.id, i, 'template')}>
                    {template.templateName} {template.id === modalSettings.currentTempId && "(Current Template)"}
                  </ListGroup.Item>
                );
              })}
              </>}
              {modalSettings.view === "sections" && <>
              {this.state.selectedTemplate.templateContent.map((section, i)=> {
                return (
                  <ListGroup.Item action onClick={(e) => this.nestingTemplate(section, i, 'section')}>
                    {section.sectionLabel}
                  </ListGroup.Item>
                )
              })}
              </>}
              {(modalSettings.view === "items" || modalSettings.view === "subitems") && <>
              {this.state.selectedSection.subSections && this.state.selectedSection.subSections.length > 0 && <>
                <select className="form-control" onChange={(e) => this.changeSubSection(e)}>
                  <option value={""}>select subsection</option>
                  {this.state.selectedSection.subSections.map((sub, i) => {
                    return (<option value={i}>{sub.sectionLabel}</option>)
                  })}
                </select>
              </>}
              {modalSettings.view === "items" && <>
              {this.state.selectedSection.items.filter((item) => item.itemType === modalSettings.item.itemType).map((item, i)=> {
                return (
                  <ListGroup.Item action onClick={(e) => this.nestingTemplate(item, i, 'selectedItem', item, "item")}>
                    {item.itemLabel}
                  </ListGroup.Item>
                )
              })}
              {this.state.selectedSection.items.filter((item) => item.itemType === modalSettings.item.itemType).length === 0 &&
              <>
                <ListGroup.Item action disabled>
                    No relevant item found in this section to map.
                  </ListGroup.Item>
              </>}
              </>}
              {modalSettings.view === "subitems" && <>
              {this.state.selectedSubSection.items.filter((item) => item.itemType === modalSettings.item.itemType).map((item, i)=> {
                console.log(item);
                return (
                  <ListGroup.Item action onClick={(e) => this.nestingTemplate(item, i, 'selectedItem', item, "item")}>
                    {item.itemLabel}
                  </ListGroup.Item>
                )
              })}
              {this.state.selectedSubSection.items.filter((item) => item.itemType === modalSettings.item.itemType).length === 0 && <>
                <ListGroup.Item action disabled>
                    No relevant item found in this sub section to map.
                  </ListGroup.Item>
              </>}
              </>}
              </>}
              {modalSettings.view === "inspectionReference" && 
              <>
              {this.state.inspectionKeys.map((ref, i) => {
                return (
                  <ListGroup.Item action onClick={(e) => this.nestingTemplate(ref, i, 'selectedItem', ref, "Inspection")}>
                    {ref.name}
                  </ListGroup.Item>
                )
              })}
              </>}
              {modalSettings.view === "inspectorReference" && 
              <>
              {this.state.inspectorKeys.map((ref, i) => {
                return (
                  <ListGroup.Item action onClick={(e) => this.nestingTemplate(ref, i, 'selectedItem', ref, "Inspector")}>
                    {ref.name}
                  </ListGroup.Item>
                )
              })}
              </>}
              </ListGroup>
              {modalSettings.item.itemTrail && Object.entries(modalSettings.item.itemTrail).length > 0 && 
                <>
                <Dropdown.Divider />
                <ListGroup className="list">
                {Object.entries(modalSettings.item.itemTrail).map(([key, item]) => {
                  return (
                    <ListGroup.Item className="listbody listitem">
                      <div className="labelName">
                        {item.uid === "Inspector" || item.uid === "Inspection" ? `${item.uid} reference` :`Temp: ${item.ref}`}<br/>
                        <span className="subLabelName">{item.path}</span>
                      </div>
                      <div className="manage-actions">
                        <div className="trashIcon" onClick={(e) => this.removeTrail(key)}>
                          <TrashIcon/>
                        </div>
                      </div>
                    </ListGroup.Item>
                  )
                })}
                </ListGroup>
                </>
              }
            </Modal.Body>
            <Modal.Footer>
            <Btn className={`template-item-edit`}
                label={`Cancel`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="ghost"
                type="primary"
                clickEvent={(e) => this.props.close("Cancel", modalSettings)} />
                {/* <Btn className={`template-item-edit`}
                label={`Save`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                style="filled"
                type="primary"
                clickEvent={(e) => this.props.close("Save", modalSettings)} /> */}
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default AddRefItemModal;