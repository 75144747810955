import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { deleteTemplates, setRowsPerPage } from '../../../redux/actions/appAction';
import { destroyTemplate, getAllTemplates, updateMultTemplates, updateTemplate } from '../../../redux/apiCalls/appData';
import { Switch } from '../../components/common/Switch.js';
import CustomTable from "../../components/tables/CustomTable";
import { CopyIcon, DragIcon, DragSortIcon, EditIcon, PlusIcon, SortIcon, TrashIcon } from '../../icons/Icons';
import Spinner from '../../shared/Spinner';


let currentUserData = '';
const loggedInUser = localStorage.getItem("persist:root");
if (loggedInUser) {
    const foundUser = JSON.parse(loggedInUser);
    const authData = JSON.parse(foundUser.authData);
    currentUserData = authData.user;
}
const isInspector = (currentUserData && currentUserData.userType == 'inspector') ? true : false;

class TemplatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            input: '',
            sort: 'DESC',
            sortIcon: 'caret-down',
            templates: [],
            loading: true,
            totalRecords: 0,
            rowsPerPage: 20,
            user:{},
            type: '',
            activeType: ''
        };
        this.load = true;
    }
    componentDidMount = async () => {
        this.timer = null;
        this.props.deleteTemplates();
        this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage:this.state.rowsPerPage, 
            defaultTemplate: true, baseTemplate: true, type:this.state.type}, (data) => {
            this.setState({loading:false, templates: this.props.templates, totalRecords: data.totalRecords, recordsPerPage: this.state.rowsPerPage
            });
        });
        try {
            let session = await sessionService.loadSession();
            this.setState({user:session});
            } catch (error) {
            return null;    
        }
    }

    componentDidUpdate = () => {

    }

    sortingTemplates = (templates) =>{
        console.log("new sorting");
        this.setState({templates});
        this.props.updateMultTemplates(templates.map((template)=>{
            return {
                id: template.id,
                sortNo: template.sortNo + (this.state.page * this.state.rowsPerPage),
            }
        }));
    }
    handleChangePage = (page) => {
        this.setState({page});
        this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: page, rowsPerPage:this.state.rowsPerPage, 
            defaultTemplate: true, baseTemplate: true}, (data) => {
            let appendNewRecords = [...this.state.templates, ...data.records]
            this.setState({templates: appendNewRecords, totalRecords: data.totalRecords, page});
        });
    }
    handleSorting = (orderBy) => {
        let sort = this.state.sort === 'DESC' ? 'ASC' : this.state.sort;
        this.setState({sort: sort, loading:true});
        let currentPageRecords = (this.state.page+1 * 3);
        console.log("handleSorting ", sort, currentPageRecords);
        this.props.getAllTemplates({sortKey: orderBy, sortValue:sort, input: this.state.input, page: 0, rowsPerPage: currentPageRecords,//this.state.rowsPerPage, 
            defaultTemplate: true, baseTemplate: true}, (data) => {
            this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords});
        });
    }
    handleChangeRowsPerPage = (newRowSet) => {
        this.setState({ page: 0, loading:true });
        this.props.setRowsPerPage(newRowSet);
        this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage: newRowSet, defaultTemplate: true, baseTemplate: true}, (data) => {
            this.props.deleteTemplates();
            this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords});
        });
    }
    handleMoveUp = (id) => {
        const template = _.find(this.props.templates, template => template.id === id);
        let arr = repositionObjects(this.state.templates, template.sortNo-1, "up");
        this.setState({templates:arr});
        this.props.updateMultTemplates(this.state.templates);
    }

    handleMoveDown = (id) => {
        const template = _.find(this.props.templates, template => template.id === id);
        let arr = repositionObjects(this.state.templates, template.sortNo-1, "down");
        this.setState({templates:arr});
        this.props.updateMultTemplates(this.state.templates);
    }

    handleDestroy = (id, index) => {
        this.state.templates.map((item, index) =>{
            if(item.id == id ){
                let data = {id: id, action: 'destroy'};
                this.props.destroyTemplate(data);
                let arr = removeTemplate(this.state.templates, index);
                this.setState({templates:arr});
            }
        }
        )
    }

    handleTemplateStatus = (id, value, tableIndex) => {
        this.state.templates.map((item, index) =>{
            if(item.id == id ){
                let checkValue = this.state.templates[index].adminPublished ? false : true;
                this.state.templates[index].adminPublished = checkValue;
                this.props.updateTemplate(this.state.templates[index]);
                this.setState({templates: this.state.templates});
            }
        }
        )
    }
    listAdminTemplateInUserTemplate = (id, value, tableIndex) => {
        this.state.templates.map((item, index) =>{
            if(item.id == id ){
                let checkValue = this.state.templates[index].listWithUserTemplates ? false : true;
                this.state.templates[index].listWithUserTemplates = checkValue;
                this.props.updateTemplate(this.state.templates[index]);
                this.setState({templates: this.state.templates});
            }
        }
        )
    }

    editTemplate = (id) =>{
        this.props.history.push(`/template/${id}`);
    }

    cloneTemplate = (id) => {
        this.props.history.push(`/template/clone=${id}`);
    }

    newTemplate = () => {
        this.props.history.push(`/template`);
    }

    handleTypeFilter = (type) => {
        this.state.type = type;
        this.setState(this.state);
        this.setState({ loading:true });
        this.setState({activeType:type});
        this.props.getAllTemplates({sort: this.state.sort, input: this.state.input, page: this.state.page, rowsPerPage:this.state.rowsPerPage, 
            defaultTemplate: true, baseTemplate: true, type:this.state.type}, (data) => {
                this.setState({loading:false, templates: data.records, totalRecords: data.totalRecords, recordsPerPage: this.state.rowsPerPage
            });
        });
    }

    handleDrop = (e, targetIndex) => {
        const sourceIndex = e.dataTransfer.getData('index');
        let reorderedItems = [...this.state.templates];
        const [movedItem] = reorderedItems.splice(sourceIndex, 1);
        reorderedItems.splice(targetIndex, 0, movedItem);
        console.log("handleDrop",reorderedItems);
        this.setState({
            templates: reorderedItems
        })
    };
    
    render = () => {
        const { loading, rowsPerPage, templates, page, totalRecords, user } = this.state;
        const isInspector = (user.userType == 'inspector') ? true : false;
        // const getItems = [{id: '1', content: 'hello'}, {id: '2', content: 'hello'}];
        return (
            <div>
                <div className="scope-templetes-heading">
                    <div className="scope-templetes-title">Templates</div>
                    <div className="mobile scope-templetes-add-new-button" onClick={this.newTemplate}>
                        <PlusIcon className="plus"/>
                        <div className="scope-templetes-add-new-title">Add New</div>
                    </div>
                    <div className="scope-templetes-filters">
                        {/* <div className="scope-templetes-filters-option">
                            <div className="scope-templetes-filters-button">
                                <div className="scope-templetes-filters-field">All</div>
                            </div>
                            <div className="scope-templetes-filters-button">
                                <div className="scope-templetes-filters-field">Admin</div>
                            </div>
                            <div className="scope-templetes-filters-button">
                                <div className="scope-templetes-filters-field">Inspector</div>
                            </div>
                        </div> */}
                    </div>
                    {
                    isInspector ? 
                    <div className="scope-comment-filters">
                        <div className="scope-templetes-filters-option">
                            <div className={this.state.activeType == '' ? 'scope-templetes-filters-button active' : 'scope-templetes-filters-button' }  onClick={()=> this.handleTypeFilter('')}>
                                <div className="scope-templetes-filters-field">All</div>
                            </div>
                            <div className={this.state.activeType == 'admin' ? 'scope-templetes-filters-button active' : 'scope-templetes-filters-button' } onClick={()=> this.handleTypeFilter('admin')}>
                                <div className="scope-templetes-filters-field">Admin</div>
                            </div>
                            <div className={this.state.activeType == 'user' ? 'scope-templetes-filters-button active' : 'scope-templetes-filters-button' } onClick={()=> this.handleTypeFilter('user')} >
                                <div className="scope-templetes-filters-field">Inspector</div>
                            </div>
                        </div>
                    </div>
                    : <></>
                    }
                    <div className="desktop scope-templetes-add-new-button" onClick={this.newTemplate}>
                        <PlusIcon className="plus"/>
                        <div className="scope-templetes-add-new-title">Add New</div>
                    </div>
                </div>
                {loading ? <Spinner/> :  !_.isEmpty(templates) ?
                                        <CustomTable
                                        // tableHead={isInspector ? tableHeadInspector : tableHeadAdmin}
                                        tableHead={tableHead}
                                        tableData={templates}
                                        editRow={this.editTemplate}
                                        clone={this.cloneTemplate}
                                        remove={this.handleDestroy}
                                        moveDown={this.handleMoveDown}
                                        handleStatus={this.handleTemplateStatus}
                                        listToUserTemplate={this.listAdminTemplateInUserTemplate}
                                        moveUp={this.handleMoveUp}
                                        type="templates"
                                        page={page}
                                        loading={loading}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={this.handleChangePage}
                                        handleSorting={this.handleSorting}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        totalNumber={totalRecords}
                                        sorting={this.sortingTemplates}
                                        currentUser={user}
                                        disableDrag={isInspector ? true : false }
                                    />

                    : <h4 className="scope-templetes-heading">No Template Found</h4> }  
        </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        templates: state.appData.templates,
        rowsPerPage: state.appData.rowsPerPage,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllTemplates: (object, cb) => dispatch(getAllTemplates(object, cb)),
        updateMultTemplates: (object, cb) => dispatch(updateMultTemplates(object, cb)),
        destroyTemplate: (object, cb) => dispatch(destroyTemplate(object, cb)),
        deleteTemplates: () => dispatch(deleteTemplates()),
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows)),
        updateTemplate: (template, cb) => dispatch(updateTemplate(template, cb))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);
let tableHead = [
    { id: 'templateName', label: 'Template Name', sortable: false, beforeIcon: <DragSortIcon className="header-col scope-svg-header-icon"/>,  width:"150px"},
    { id: 'templateDescription', label: 'Description', sortable: false},
    // isInspector ? : { id: 'adminPublished', label: 'Status', sortable: false},
    // isInspector ? : { id: 'listWithUserTemplates', label: 'Add in User Templates', sortable: false},
    { id: 'templateEdit/delete', label: 'Action', sortable: false },
];
if(!isInspector){
    tableHead = [...tableHead.slice(0,2),
        { id: 'adminPublished', label: 'Status', sortable: false, width:"150px"},
        { id: 'listWithUserTemplates', label: 'Add in User Templates', sortable: false, width:"150px"},
        ...tableHead.slice(2,tableHead.length)
    ]  
}
if(isInspector){
    tableHead = [...tableHead.slice(0,2),
        { id: 'templateUser', label: 'Type', sortable: false},
        ...tableHead.slice(2,tableHead.length)
    ]  
}
// const tableHeadAdmin = [
//     { id: 'templateName', label: 'Template Name', sortable: false, beforeIcon: <DragSortIcon className="header-col scope-svg-header-icon"/>,  width:"150px"},
//     { id: 'templateDescription', label: 'Description', sortable: false},
//     { id: 'adminPublished', label: 'Status', sortable: false},
//     { id: 'listWithUserTemplates', label: 'Add in User Templates', sortable: false},
//     { id: 'templateEdit/delete', label: 'Action', sortable: false },
// ];
// const tableHeadInspector = [
//     { id: 'templateName', label: 'Template Name', sortable: false, beforeIcon: <DragSortIcon className="header-col scope-svg-header-icon"/>,  width:"150px"},
//     { id: 'templateDescription', label: 'Description', sortable: false},
//     { id: 'templateEdit/delete', label: 'Action', sortable: false },
// ];



function moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; 
};

function repositionObjects(arr, index, position){
    if(position == "down"){
        let item = arr[index + 1];
        item.sortNo--;
        arr[index].sortNo++;
        arr[index + 1] = arr[index];
        arr[index] = item;
    } else {
        let item = arr[index - 1];
        item.sortNo++;
        arr[index].sortNo--;
        arr[index - 1] = arr[index];
        arr[index] = item;
    }
    return arr;
};

function removeTemplate(arr, index){
    arr.splice(index,1);
    // if(arr.length == index){
    //     delete arr[index];
    // } else {
    //     let item = arr[index+1];
    //     item.sortNo--;
    //     delete arr[index+1];
    //     arr[index] = item;
    // }
    return arr;
}