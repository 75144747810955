import ACTIONS from '../actions/authAction';
const initialState = {
    user: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch(action.type) {
        case ACTIONS.Types.LOGIN:
            return state = {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
}
