import { Formik } from "formik";
import _ from "lodash";
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { resetPassword } from '../../redux/apiCalls/authData';
import { LoginLogo } from '../icons/Icons';
import Spinner from '../shared/Spinner';
import { validateEmail } from "./../../helpers/helpers";
import toast from "react-hot-toast";

export class Verifycode extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
      loading:false,
      showPassword: true,
      showConfirmPassword: true,
      message: {
        success:'',
        error:'',
      },
      isSubmitting:false,
      input:{},
      errors:{},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount = async () => {
  }

  toggleSwitch = (e) => {
    this.state.showPassword = !this.state.showPassword;
    this.setState(this.state);
  }
  toggleConfirmSwitch = (e) => {
    this.state.showConfirmPassword = !this.state.showConfirmPassword;
    this.setState(this.state);
  }

  handleChange(event) {
    let input = this.state.input;
    // console.log("handleChange", event.target.name, event.target.value);
    input[event.target.name] = event.target.value;
    this.validate();
    this.setState({
      input:input
    });
  }
  handleSubmit(event) { 
    this.setState({loading:true});
    event.preventDefault();
    if(this.validate()){
      let currentCode = this.props.location.pathname.split("/");
      let data={
        password: this.state.input["password"],
        confPassword: this.state.input["confirmpassword"],
        verificationCode: currentCode[2]
      }
      this.props.resetPassword(data, (res) => {
        this.setState({loading:false});
          if(res.status == 200){
            let input = {};
            input["password"] = "";
            input["confirmpassword"] = "";
            this.setState({input:input});
            toast.success(res.data.message);
            setTimeout(() => {
              window.location = "/login";
            }, 2000);
          }
      }); 
    }
  }

  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
      if (!input["password"]) {
        isValid = false;
        errors["password"] = "Please Enter your Password.";
      } else {
        isValid = true;
        errors["password"] = "";
      }

      if (!input["confirmpassword"]) {
        isValid = false;
        errors["confirmpassword"] = "Please Enter your Confirm Password.";
      } else {
        isValid = true;
        errors["password"] =  "";
      }

      if (typeof input["password"] !== "undefined" && typeof input["confirmpassword"] !== "undefined") {

        if (input["password"] != input["confirmpassword"]) {
          isValid = false;
          errors["password"] = "Confirm password is Not Matched";
        } else {
          isValid = true;
          errors["password"] = "";
        }
      }

      this.setState({
        errors: errors,
        isSubmitting:isValid
      });

      return isValid;
  }
  render() {
    return (
      <div>
      <div className="login-container">
          <div className="width60">
            {this.state.loading ? <Spinner/> : <></>}
          </div> 
        <div className="auth-form-login">      
          <div className="welcome-back-login">
            <LoginLogo className="img-logo" />
          </div>
          <div className="scope-form">
            <div className="login-with">Reset Password</div>
            <div className="login-form">
                <Form className="email-box">
                  <div className="password-title">Password</div>
                  <Form.Group className="password-box">
                  <div className="input-group">
                    <input className="form-control"  
                    id="password"
                    type={this.state.showPassword ? "password" : "text"} 
                    name="password" 
                    placeholder="Password" 
                    value={this.state.input.password}
                    onChange={this.handleChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text pass-eye"> <i onClick={this.toggleSwitch} className={this.state.showPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}></i></span>
                    </div>
                  </div>
                  </Form.Group>
                  <hr /> 
                  <div className="password-title">Confirm Password</div>
                  <Form.Group className="password-box">
                  <div className="input-group">
                    <input className="form-control" 
                    id="confirm-password"
                    type={this.state.showConfirmPassword ? "password" : "text"} 
                    placeholder="Confirm Password" 
                    value={this.state.input.confirmpassword}
                    onChange={this.handleChange}
                    name="confirmpassword"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text pass-eye"> <i onClick={this.toggleConfirmSwitch} className={this.state.showPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}></i></span>
                    </div>
                  </div>
                  </Form.Group>
                  <div className="text-danger">{this.state.errors.password}</div>
                  <div className="text-danger">{this.state.errors.confirmpassword}</div>
                  {this.state.message.error !='' ? <div className="text-danger mb-2">{this.state.message.error}</div> : null}
                  {this.state.message.success != '' ? <div className="text-success mb-2">{this.state.message.success}</div> : null}
            <button type="submit" disabled={!this.state.isSubmitting} onClick={this.handleSubmit} className="login-button">
            <div  className="login-button-title">Reset</div>
            </button>
            </Form>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.authData.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
      resetPassword: (template, cb) => dispatch(resetPassword(template, cb)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Verifycode)