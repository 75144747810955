/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import {Button as Btn} from "../button/button";
import Form from 'react-bootstrap/Form';
import _ from "lodash";
import { ChoiceIcon, CurrencyIcon, DateIcon, DublicateIcon,  MailIcon,  ORGIcon,  PhoneIcon,  PhotoIcon,  PlusIcon, SignIcon, TextIcon, WebSiteIcon } from "../../icons/Icons";


class AddItemModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalSettings: {
            show: false,
            listOfItems: [],
            duplicates: [],
            selectedItem: {},
            view: "menu"
        },
        saveBtnDisabled: false
    }
  }
  componentDidMount = async () => {
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.modalSettings = nextProps.modalSettings;
    this.state.saveBtnDisabled = false;
    this.setState( this.state );
  }

  changeMenu = (view) => {
    this.state.modalSettings.view = view;
    this.setState(this.state);
  }

  selectingItem = (item, type) => {
    let newItem = {
        itemType : item.itemType,
        visibility: true,
        editable:true
    };
    if(type === "new") {
      if(item.itemType === "choice"){
          newItem.itemOptions = ["Good"];
          newItem.itemSelected = "Good";
          newItem.itemLabel = "Choices"
          newItem.allowMultipleSelection = false;
          newItem.prohibitConditionRatings = false;
      } else if(item.itemType === "timestamp") {
          newItem.itemValue = null;//moment().valueOf();
          newItem.itemLabel = item.itemName;
      } else {
          newItem.itemValue = "";
          newItem.itemLabel = item.itemName;
      }
    } else if( type ===  "duplicate"){
        newItem = _.merge({}, item); 
        newItem.editable = true;
        this.props.close("Save", newItem);
    }
    this.state.modalSettings.selectedItem = newItem;
    this.state.modalSettings.view = "rename";
    this.setState(this.state);
  }

  onChange = (e) => {
    this.state.modalSettings.selectedItem.itemLabel = e.target.value;
    if(this.state.modalSettings.selectedItem.itemLabel.trim().length === 0){
      this.state.saveBtnDisabled = true;
    } else {
      this.state.saveBtnDisabled = false;
    }
    this.setState(this.state);
  }

  render = () => {
    const {modalSettings} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            // onHide={() => this.props.close(item)}
            dialogClassName="modal-90w itemEdit modal-scope"
            aria-labelledby="template-item-edit"
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
            <Modal.Title id="template-item-edit ">
                Adding new Item
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup key={"addItemModal"} className="list">
                {modalSettings.view === "menu" ? 
                  <>
                  <ListGroup.Item action onClick={(e) => this.changeMenu("new")}>
                   <PlusIcon className="add-new-item" /> New Item
                  </ListGroup.Item>
                  <ListGroup.Item action onClick={(e) => this.changeMenu("duplicates")}>
                    <DublicateIcon className="dublicate-item" /> Duplicate Item
                  </ListGroup.Item>
                  </>
                : modalSettings.view === "new" ? 
                  <>
                  {modalSettings.listOfItems.map((item, index) => {
                    return (
                      <ListGroup.Item key={`${item}-${index}`} action onClick={(e) => this.selectingItem(item, "new")}>
                      {
                        item.itemName=="Website" ? <WebSiteIcon className="add-new-item" /> : 
                        item.itemName=="Phone" ? <PhoneIcon className="add-new-item" /> : 
                        item.itemName=="Photo" ? <PhotoIcon className="add-new-item" /> : 
                        item.itemName=="Organization" ? <ORGIcon className="add-new-item" /> : 
                        item.itemName=="Email" ? <MailIcon className="add-new-item" /> : 
                        item.itemName=="Signature" ? <SignIcon className="add-new-item" /> : 
                        item.itemName=="Text" ? <TextIcon className="add-new-item" /> : 
                        item.itemName=="Date and Time" ? <DateIcon className="add-new-item" /> : 
                        item.itemName=="Choice" ? <ChoiceIcon className="add-new-item" /> : 
                        item.itemName=="Currency" ? <CurrencyIcon className="add-new-item" /> : 
                      
                        <></>
                      }
                        {item.itemName}
                      </ListGroup.Item>
                    );
                  })}
                  </>
                : modalSettings.view === "duplicates" ? 
                <>
                {modalSettings.duplicates.length > 0 ? modalSettings.duplicates.map((item, index) => {
                    return (
                      <ListGroup.Item key={`${item.itemLabel}-${index}`} action onClick={(e) => this.selectingItem(item, "duplicate")}>
                        {item.itemLabel}
                      </ListGroup.Item>
                    );
                  })
                : 
                <ListGroup.Item disabled>
                  No item available in this section to copy
                </ListGroup.Item>
                }
                </>
                : modalSettings.view === "rename" ? 
                <>
                  <Form.Control
                    type="text"
                    placeholder={`Enter label ${modalSettings.selectedItem.itemLabel}`}
                    value={modalSettings.selectedItem.itemLabel}
                    onChange={(e) => this.onChange(e)}
                  />
                </>
                :
                <ListGroup.Item action onClick={(e) => this.props.close("Cancel", modalSettings)}>Some went wrong! please try adding again.</ListGroup.Item>
                }
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
            <Btn className={`template-item-edit`}
                label={`Cancel`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="ghost"
                type="primary"
                clickEvent={(e) => this.props.close("Cancel", modalSettings)} />
                {modalSettings.view === "rename" && 
                <Btn className={`template-item-edit`}
                label={`Save`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="filled"
                type="primary"
                disabled={this.state.saveBtnDisabled}
                clickEvent={(e) => this.props.close("Save", this.state.modalSettings.selectedItem)} />}
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default AddItemModal;