/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import Modal from 'react-bootstrap/Modal';
import {Button as Btn} from "../../components/button/button";

class UserEditModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalSettings: {
            show: false,
            user: {},
          },
        saveBtnDisabled:false
    }
  }
  componentDidMount = async () => {
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.modalSettings = nextProps.modalSettings;
    this.state.saveBtnDisabled = false;
    this.setState( this.state );
  }

  onChange = (e, field) => {
    this.state.modalSettings.user[field] = e.target.value;
    this.setState(this.state);
  }


  render = () => {
    const {modalSettings} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            // onHide={() => this.props.close(item)}
            dialogClassName="modal-10w itemEdit modal-scope-40"
            aria-labelledby="template-item-edit"
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
            <Modal.Title id="template-item-edit">
                User Information
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container userModalBody">
                  <div className="row">
                    <div className="col-3">Full Name</div>
                    <div className="col">{modalSettings.user.firstname} {modalSettings.user.lastname}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Email</div>
                    <div className="col">{modalSettings.user.email}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Phone</div>
                    <div className="col">{modalSettings.user.phone}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Organization</div>
                    <div className="col">{modalSettings.user.organization}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Website</div>
                    <div className="col">{modalSettings.user.website}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Plan</div>
                    <div className="col">{modalSettings.user.planMode}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">User Type</div>
                    <div className="col">
                      <select className="form-control" id="select-user-type" onChange={(e) => this.onChange(e, "userType")}>
                        <option value={'inspector'} selected={modalSettings.user.userType === "inspector"}>Inspector</option>
                        <option value={'admin'} selected={modalSettings.user.userType === "admin"}>Admin</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">Report Limits</div>
                    <div className="col">
                      <input onChange={(e) => this.onChange(e, "reportLimits")} className="form-control" type="number" name="reportLimit" value={modalSettings.user.reportLimits} onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}/>
                    </div>
                  </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Btn className={`template-item-edit`}
                label={`Cancel`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="ghost"
                type="primary"
                clickEvent={(e) => this.props.close("Cancel", this.state.modalSettings.user)} />
                <Btn className={`template-item-edit`}
                label={`Save`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="filled"
                type="primary"
                disabled={this.state.saveBtnDisabled}
                clickEvent={(e) => this.props.close("Save", modalSettings.user)} />
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default UserEditModal;