import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { setRowsPerPage } from '../../../redux/actions/appAction';
import { getContact, getUserInspections } from '../../../redux/apiCalls/appData';
import CustomTable from "../../components/tables/CustomTable";
import Spinner from '../../shared/Spinner';
import { PlusIcon } from '../../icons/Icons';

const PREFIX = 'UserInspections';

const styles = {
    cardCategoryWhite: {
        '&,& a,& a:hover,& a:focus': {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        '& a,& a:hover,& a:focus': {
            color: '#FFFFFF',
        },
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '600',
        fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: '#777',
            fontSize: '65%',
            fontWeight: '400',
            lineHeight: '1',
        },
    },
    iconButton: {
        width: "27px",
        height: "27px",
        padding: "0",
        margin:0,
    },
    icon: {
        width: "20px",
        height: "20px"
    },
};

class UserInspections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            input: '',
            sort: 'DESC',
            sortIcon: 'caret-down',
            inspections: [],
            contacts:[],
            reportSent:[],
            loading: true,
            totalRecords: 0,
            recordsPerPage: 20,
        };
        this.load = true;
    }

    componentDidMount = () => {
        this.timer = null;
        this.props.getUserInspections({sort: this.state.sort, page: this.state.page, rowsPerPage: this.props.rowsPerPage}, (data) => {
           console.log("Data : ", data);
            this.setState({loading:false, inspections: this.props.inspections, totalRecords: data.totalRecords, recordsPerPage:this.props.rowsPerPage});
            this.getContactsFromInspections();
        });
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     return {
    //         inspections: nextProps.inspections
    //     }
    // }
    handleChangePage = (page) => {
        this.setState({page});
        this.props.getUserInspections({sort: this.state.sort, input: this.state.input, page: page, rowsPerPage:this.props.rowsPerPage}, (data) => {
            let appendNewRecords = [...this.state.inspections, ...data.records]
            this.setState({inspections: appendNewRecords, totalRecords: data.totalRecords, page});
        });
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({ page: 0, loading:true });
        this.props.setRowsPerPage(event.target.value);
        this.props.getUserInspections({ sort: this.state.sort, page: this.state.page, rowsPerPage: event.target.value }, (data) => {
            this.setState({ loading: false, inspections: this.props.inspections, totalRecords: data.totalRecords, recordsPerPage: this.props.rowsPerPage });
        });
    }

    getContactsFromInspections = () => {
        this.setState({loading:true });
        this.props.getContact(null, (data) => {
            this.setState({ loading: false, contacts: data.records });
        });
    }

    render = () => {
        const {rowsPerPage } = this.props;
        const { loading, page, totalRecords, inspections, contacts } = this.state;
        return (
            <div>

            <div className="scope-templetes-heading">
                <div className="scope-templetes-title">Inspections</div>
               
            </div>
             {loading ? <Spinner/> :  !_.isEmpty(inspections) ?
                                    <CustomTable
                                    tableHead={tableHead}
                                    tableData={inspections}
                                    contacts={contacts}
                                    type="inspections"
                                    page={page}
                                    loading={loading}
                                    rowsPerPage={rowsPerPage}
                                    totalNumber={totalRecords}
                                    handleChangePage={this.handleChangePage}
                                    sorting={this.sortingTemplates}
                                    disableDrag={true}
                                />

                : <h4 className="scope-templetes-heading" >No User's Inspection Found</h4> }
    </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rowsPerPage: state.appData.rowsPerPage,
        inspections: state.appData.inspections,
        contacts: state.appData.contacts
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setRowsPerPage: (rows) => dispatch(setRowsPerPage(rows)),
        getUserInspections: (object, cb) => dispatch(getUserInspections(object, cb)),
        getContact: (object, cb) => dispatch(getContact(object, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInspections);

const tableHead = [
    { id: 'viewReport-clientName', label: 'Client Name', sortable: false, width:"150px" },
    { id: 'inspection-status', label: 'Inspection status', sortable: false, width:"150px" },
    { id: 'hasSubmittedAgreement', label: 'Agreement Status', sortable: false, width:"150px" },
    { id: 'hasPaidInvoice', label: 'Invoice', sortable: false, width:"150px" },
    { id: 'hasSubmittedReport', label: 'Report Status', sortable: false, width:"150px" },
    { id: 'viewReport-inspectionAddress', label: 'Address', sortable: false, width:"350px"},
    { id: 'viewReport-inspectionCreatedAt', label: 'Date ', sortable: false, width:"150px" },
    { id: 'viewReport/sendReport', label: 'Actions', sortable: false, width:"10px"},
];