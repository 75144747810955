/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import {EditIcon} from "../../icons/Icons";


class ListHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sectionIndex: null,
      subSectionIndex: null,
      header: "",
      footer: "",
      section: {},
      type:{}
    }
  }
  componentDidMount = async () => {
    this.state.sectionIndex = this.props.sectionIndex;
    this.state.subSectionIndex = this.props.subSectionIndex;
    this.state.sectionName = this.props.sectionName;
    this.state.footer = this.props.footer;
    this.state.section = this.props.section;
    this.state.type = this.props.type;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.sectionIndex = nextProps.sectionIndex;
    this.state.subSectionIndex = nextProps.subSectionIndex;
    this.state.section = nextProps.section;
    this.state.type = nextProps.type;
    this.setState( this.state );
}
  render = () => {
    const { section, type} = this.state;
    let sectionName = section.sectionLabel;
    return (
        <ListGroup className={`list list_headers list-${sectionName}`}>
          <ListGroup.Item key={`headerFooters-header-${sectionName}-${this.state.sectionIndex}`} disabled className={`listTitle title${sectionName}`}>
            <div className="titleLabel">
              {sectionName} Header & Footer
            </div>
            </ListGroup.Item>
            <ListGroup.Item key={`headerOne-header-${sectionName}-${this.state.sectionIndex}`} action className="headerItem">
                <div className="labelName">
                  Header<br/>
                  <span className="subLabelName">{section.header}</span>
                </div>
                <div className="manage-actions">
                    <div className="editIcon" onClick={(e) => this.props.editHeaderFooter({show:true,field:"header", type: type})}>
                        <EditIcon />
                    </div>
                </div>
            </ListGroup.Item>
            <ListGroup.Item key={`footerOne-header-${sectionName}-${this.state.sectionIndex}`} action className="headerItem">
                <div className="labelName">
                  Footer<br/>
                  <span className="subLabelName">{section.footer}</span>
                </div>
                <div className="manage-actions">
                    <div className="editIcon" onClick={(e) => this.props.editHeaderFooter({show:true,field:"footer", type: type})}>
                        <EditIcon />
                    </div>
                </div>
            </ListGroup.Item>
        </ListGroup>
    )
  }
}
export default ListHeader;