/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import Modal from 'react-bootstrap/Modal';
import {Button as Btn} from "../../components/button/button";
import Form from 'react-bootstrap/Form';


class SectionHeaderFooterEditModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        modalSettings: {
            show: false,
            title: "",
            section: {},
            index: null,
            value: "",
            field: "",
            type: "",
        }
    }
  }
  componentDidMount = async () => {
    this.state.modalSettings = this.props.modalSettings;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.modalSettings = nextProps.modalSettings;
    this.setState( this.state );
  }

  onChange = (e) => {
    this.state.modalSettings.value = e.target.value;
    this.setState(this.state);
  }

  render = () => {
    const {modalSettings} = this.state;
    return (
        <>
        <Modal
            show={modalSettings.show}
            dialogClassName="modal-90w itemEdit modal-scope"
            aria-labelledby="template-item-edit"
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
            <Modal.Title id="template-item-edit">
                Editing {modalSettings.title} {modalSettings.field}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                as="textarea"
                placeholder={`${modalSettings.field} text goes here`}
                value={modalSettings.value}
                onChange={(e) => this.onChange(e)}
                rows={20}
                />
            </Modal.Body>
            <Modal.Footer>
            <Btn className={`template-item-edit`}
                label={`Cancel`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="ghost"
                type="primary"
                clickEvent={(e) => this.props.close("Cancel", modalSettings)} />
                <Btn className={`template-item-edit`}
                label={`Save`}
                showLeftIcon={false}
                showRightIcon={false}
                size="large"
                state="default"
                variant="filled"
                type="primary"
                clickEvent={(e) => this.props.close("Save", modalSettings)} />
            </Modal.Footer>
        </Modal>
        </>
    );
  }
}

export default SectionHeaderFooterEditModal;