const CONSTANTS = {
    /********* PRODUCTION MODE *********/
    SERVER_URL: 'https://api.scopeinspectapp.com/',
    ADMIN_URL: 'https://dashboard.scopeinspectapp.com/',
    CDN_URL: 'https://cdn.scopeinspectapp.com',
    REVIEW_BASE: "https://review.scopeinspectapp.com/",

    /********* STAGING MODE *********/
    // SERVER_URL: 'https://staging-api.scopeinspectapp.com/',
    // ADMIN_URL: 'https://dashboard-staging.scopeinspectapp.com/',
    // CDN_URL: 'https://scdn.scopeinspectapp.com',
    // REVIEW_BASE: "https://review-staging.scopeinspectapp.com/",
    
    /********* DEVELOPMENT MODE *********/
    // SERVER_URL: 'http://localhost:1337/',
    // ADMIN_URL: 'http://localhost:3000/',
    // CDN_URL: 'http://localhost:1337',
    // REVIEW_BASE: "http://localhost:63238/",

    STRIPE_PAYMENT_LINKS: {
        MONTHLY: "https://buy.stripe.com/test_aEUaER57NdiIdpKfYY", //TEST
        // MONTHLY: "https://buy.stripe.com/test_aEUaER57NdiIdpKfYY", //LIVE
        ANNUAL: "https://buy.stripe.com/test_cN2aERas792s0CY8wx", //TEST
        // ANNUAL: "https://buy.stripe.com/test_cN2aERas792s0CY8wx", //LIVE
    },
    GOOGLE_CLIENT_ID : "123268600332-ii755d8hndd6sp4lrbq1jak2lbf7dtu7.apps.googleusercontent.com",
    CALLS: {
        GET_ALL_TEMPLATES: 'admin/get/templates',
        ADMIN_VALIDATE: 'validateSession',
        UPDATE_MULT_TEMPLATES: 'update/templates',
        CREATE_OR_UPDATE_TEMPLATE: 'update/template',
        DELETE_TEMPLATE: 'delete/template',
        GET_TEMPLATE_BY_ID: 'get/template',
        GET_ITEMS: 'get/items',
        ADMIN_LOGIN: 'login',
        GET_USERS: 'admin/get/users',
        SET_USER_STATUS: 'update/user/status',
        SET_USER_TYPE: 'update/user/type',
        STRIPE_PAYMENT: 'stripe/payment',
        GET_USER_PLAN: 'user/plan',
        GET_CURRENT_USER: 'get/current/user',
        CANCEL_SUBSCRIPTION: 'cancel/subscription',
        GET_ENTRIES: 'get/entries',
        GET_PLANS: 'get/plans',
        STRIPE_CONNECT: 'stripe/connect',
        STRIPE_GET_PAYMENT_LINK: 'stripe/get/payment/link',
        STRIPE_ACCOUNT_RETRIEVE: 'stripe/retrieve/account',
        GET_COMMENTS: 'get/library/comment',
        UPDATE_COMMENT: 'update/library/comment',
        ADD_COMMENT: 'add/library/comment',
        ADD_MEDIA: 'add/media',
        SAVE_AGREEMENT: 'save/agreement',
        GET_AGREEMENT: 'get/agreement',
        DELETE_COMMENT: 'delete/library/comment',
        GET_USER_INSPECTIONS: 'admin/get/inspections',
        GET_CONTACT: 'get/contact',
        SEND_REPORT: 'send/inspection/report',
        UPDATE_USER: 'admin/update/user',
        SOCIAL_LOGIN: 'social/login',
        APPLE_LOGIN: 'auth/apple/callback',
        FORGOT_PASSWORD: 'user/forgotpassword',
        RESET_PASSWORD: 'user/resetpassword',
        GET_EMAIL_HOOKS: 'get/email/hooks',
        SAVE_EMAIL_HOOK: 'save/email/hook',
        GET_EMAIL_COPY: 'get/email/copy',
        SIGNUP: 'add/user',
    },
    MESSAGES: {
        SESSION_NOT_FOUND: 'Session not found',
        SESSION_NOT_SAVED: 'Session not saved',
        SESSION_USER_NOT_SAVED: 'Session user not saved',
        NETWORK_ERROR: 'NETWORK ERROR',
        ERROR: 'Something went wrong'
    }
};

export const BASE_URL = path => {
    return CONSTANTS.SERVER_URL + path;
};


export default CONSTANTS;
