/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import {EyeIcon, EditIcon, TrashIcon} from "../../icons/Icons";
import DeleteConfirmationModal from "../modal/deleteConfirmationModal";
/* eslint-disable react/no-direct-mutation-state */


class ListSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      section: {},
      type: "",
      options: {},
      index: 0,
      active: "",
      templateType: "",
      templateOwner: true,
      user: {},
      deleteConfirmationModal:{
        show: false,
        title: "",
        body: "",
        type: "",
      }
    }
  }
  componentDidMount = async () => {
    this.state.section = this.props.section;
    this.state.type = this.props.type;
    this.state.index = this.props.index;
    this.state.options = this.props.options;
    this.state.active = this.props.active;
    this.state.templateType = this.props.templateType;
    this.state.templateOwner = this.props.templateOwner;
    this.state.user = this.props.user;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.section = nextProps.section;
    this.state.type = nextProps.type;
    this.state.options = nextProps.options;
    this.state.index = nextProps.index;
    this.state.active = nextProps.active;
    this.state.user = nextProps.user;
    this.state.templateOwner = nextProps.active;
    this.state.templateType = nextProps.templateType;
    this.setState( this.state );
  }

  deleteConfirmationModalClose = (action) => {
    this.state.deleteConfirmationModal = {
      show: false,
      title: "",
      body: "",
      type: "",
    };
    this.setState(this.state);
    if(action === "Yes"){
      this.props.deleteSection(this.state.index, this.state.type);
    }
  }

  openDeleteConfirmationModal = () => {
    this.state.deleteConfirmationModal = {
      show: true,
      title: `Delete ${this.state.type}`,
      body: ``,
      type: 'danger'
    }
    this.setState(this.state);
  }

  render = () => {
    const {type, section, options, index, active} = this.state;
    return (
      <>
        <ListGroup.Item key={section.uid ? section.uid : index} action onClick={(e) => this.props.listClick(index, type, this.props.index)} className={`listitem label item${type} ${active}`} as={this.props.draggableComponent}>
            <div className="labelName">{section.sectionLabel}</div>
            <div className="manage-actions">
              {options.visible && 
                <div className="eyeIcon" onClick={(e) => {e.stopPropagation(); this.props.visibilityHandler(index, type)}}>
                  <EyeIcon icon={section.visibility === true ? "visible" : "invisible"}/>
                </div>
              }
                {options.edit && 
                <div className="editIcon" onClick={(e) => this.props.editSection({show:true, section:section, index:index, title: section.sectionLabel})}>
                    <EditIcon />
                </div>
                }
                {options.delete && 
                <div className="trashIcon" onClick={(e) => {e.stopPropagation(); this.openDeleteConfirmationModal()}}>
                    <TrashIcon/>
                </div>
                }
            </div>
        </ListGroup.Item>
        <DeleteConfirmationModal modalSettings={this.state.deleteConfirmationModal} close={this.deleteConfirmationModalClose} />
      </>
      )
    }
}

export default ListSection;