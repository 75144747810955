/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import ListItem  from "./listItem.jsx";
import ListSection from "./listSection";
import {Button} from "../button/button"
import {PlusIcon, EyeIcon} from "../../icons/Icons";


class List extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      type: "",
      options: {}
    }
  }
  componentDidMount = async () => {
    this.state.list = this.props.list;
    this.state.type = this.props.type;
    this.state.options = this.props.options;
    this.setState( this.state );
  }
  componentWillReceiveProps(nextProps) {
    this.state.list = nextProps.list;
    this.state.type = nextProps.type;
    this.state.options = nextProps.options;
    this.setState( this.state );
}

  add = (e) => {
    if(this.state.type === "Items"){
      this.props.add({show: true, duplicates: this.props.list, view:"menu"});
    } else {
      this.props.add({show: true, title: this.state.type, type: this.state.type, index: this.props.selectedIndex, value:""}, this.state.type);
    }
  }
  
  render = () => {
    const {type, list, options} = this.state;
    let updatedOptions = options;
    let listHeading = "Items";
    let btnLabel = "Item";
    if(type === "Section"){
      listHeading = "Sections";
      btnLabel = "Section";
    } else if( type === "Subsections"){
      listHeading = "Subsections";
      btnLabel = "Subsection";
    }
    return (
        <ListGroup key={`${type}-id-for-${type}-${Object.keys(options).join('-')}`} className={`list list-${type}`} as={this.props.droppableComponent ? this.props.droppableComponent : undefined}>
          <ListGroup.Item key={`id-for-${type}-${Object.keys(options).join('-')}`} className={`listTitle title${type}`}>
            <div className="titleLabel">
              {listHeading}
            </div>
            <div className="titleAction">
              {type === "Items" && this.props.sectionEditable &&
              <div className="eyeIcon" onClick={(e) => {e.stopPropagation(); this.props.visibilityHandler(this.props.selectedIndex)}}>
                <EyeIcon width="28" height="28" icon={this.props.sectionVisibility === true ? "visible" : "invisible"}/>
              </div>
              }
              <Button
                  className={`listbuttons add-new-btn btn-${type}`}
                  label={`New ${btnLabel}`}
                  showLeftIcon={true}
                  leftIcon={<PlusIcon className="editTemplate_btn" height="18" width="18"/>}
                  showRightIcon={false}
                  size="large"
                  state="default"
                  variant="outlined"
                  type="primary"
                  clickEvent={(e) => this.add(e)}
                  />
            </div>
          </ListGroup.Item>
          {list && list.length > 0 ? 
            list.map((l, i)=>{
              if(l.hasOwnProperty("editable") && l.editable === false && this.props.user.userType !== "developer"){
                updatedOptions = {
                  edit: false,
                  delete: false,
                  visible: false
                }
              } else {
                updatedOptions = options;
              }
              return (
                type === "Subsections" || type === "Section" ? 
                  <ListSection
                  section={l}
                  type={type}
                  options={updatedOptions}
                  deleteSection={this.props.deleteSection}
                  editSection={this.props.editSection}
                  index={i}
                  visibilityHandler={this.props.visibilityHandler}
                  sectionIndex={this.props.selectedIndex}
                  subSectionIndex={this.props.selectedSubIndex}
                  templateType={this.props.templateType}
                  user={this.props.user}
                  template={this.props.templateOwner}
                  listClick={this.props.listClick}
                  draggableComponent={this.props.draggableComponent ? this.props.draggableComponent(i+"", i, l) : undefined}
                  sectionHandler={this.props.sectionHandler}
                  active={this.props.selectedIndex === i && type === "Section" ? "active" : this.props.selectedSubIndex === i && type === "Subsections" ? "active" : ""}
                  />
                  :
                  <ListItem
                  item={l}
                  type={type}
                  options={updatedOptions}
                  editItem={this.props.editItem}
                  choiceCheckHandle={this.props.choiceCheckHandle}
                  deleteItem={this.props.deleteItem}
                  index={i}
                  sectionIndex={this.props.selectedIndex}
                  subSectionIndex={this.props.selectedSubIndex}
                  collapseState={this.props.getCollapseStateValue(this.props.itemCollapsebleState, this.props.selectedIndex, this.props.selectedSubIndex, i)}
                  changeCollapseState={this.props.changeCollapseState}
                  deleteChoiceOption={this.props.deleteChoiceOption}
                  addChoiceOption={this.props.addChoiceOption}
                  addRef={this.props.addRef}
                  visibilityHandler={this.props.visibilityHandler}
                  updateChoiceOption={this.props.updateChoiceOption}
                  getCollapseStateValue={this.props.getCollapseStateValue}
                  listClick={this.props.listClick}
                  draggableComponent={this.props.draggableComponent(i+"", i, l)}
                  sectionHandler={this.props.sectionHandler}
                  templateType={this.props.templateType}
                  user={this.props.user}
                  template={this.props.templateOwner}
                  // active={this.props.selectedIndex === i ? "active" : ""}
                  />
              );
            })
          : 
          <ListGroup.Item disabled>
            No {type} found
          </ListGroup.Item>
          }
        </ListGroup>
    );
  }
}

export default List;