/* eslint-disable no-unused-vars */
/* eslint-disable handle-callback-err */
/* eslint-disable callback-return */
import axios from 'axios';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { sessionService } from 'redux-react-session';
import CONSTANTS, { BASE_URL } from '../../config/constants.react';
import { setUser } from '../actions/authAction';
// import {ShowAlert, AlertTypes} from '../../components/Alert.react';

export const loginUser = (params, cb = null) => {
    return (dispatch, getState) => {
        axios.post(BASE_URL(CONSTANTS.CALLS.ADMIN_LOGIN), params).then((res) => {
            // console.log("Login Call Success :", res, cb);
            // if(res.data.userType == "admin"){
                sessionService.saveSession({token: res.data.accessToken, userType: res.data.userType, email: res.data.email, id: res.data.id}).then(() => {
                    // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
                    sessionService.saveUser(res.data).then(() => {
                        dispatch(setUser(res.data));
                        if(_.isFunction(cb)) cb();
                    }).catch(() => {
                        toast.error(CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                        if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                        // console.log("Session Service save user err");
                    });
                }).catch(() => {
                    if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_NOT_SAVED);
                    toast.error(CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                });
            // } else {
                // //dispatch(alertPopupAction(true, "Invalid Credentials", "danger"));
            //     if(_.isFunction(cb)) cb(true, "Invalid Credentials");
            // }
        }).catch( err => {
            if(!_.isNil(err.response)) {
                // ShowAlert(err.response.data.message,AlertTypes.DANGER,'bell');
                console.log("Login call failed :", err.response.data.message);
                toast.error(err.response.data.message);
                // dispatch(alertPopupAction(true, err.response.data.message, "danger"));
                if(_.isFunction(cb)) cb(true, err.response);
            } else {
                // ShowAlert(CONSTANTS.MESSAGES.NETWORK_ERROR,AlertTypes.DANGER,'info');
            }
        });
    }
}
export const googleloginUser = (params, cb = null) => {
    return (dispatch, getState) => {
        axios.get(BASE_URL(CONSTANTS.CALLS.SOCIAL_LOGIN), {params:params}).then((res) => {
            console.log("Login Google Social Call Success :", res, cb);
            // if(res.data.userType == "admin"){
                sessionService.saveSession({token: res.data.accessToken, userType: res.data.userType, email: res.data.email, id: res.data.id}).then(() => {
                    // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
                    sessionService.saveUser(res.data).then(() => {
                        dispatch(setUser(res.data));
                        if(_.isFunction(cb)) cb(res.data);
                    }).catch(() => {
                        toast.error(CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                        if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                        // console.log("Session Service save user err");
                    });
                }).catch(() => {
                    if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_NOT_SAVED);
                    toast.error(CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                });
            // } else {
                // //dispatch(alertPopupAction(true, "Invalid Credentials", "danger"));
            //     if(_.isFunction(cb)) cb(true, "Invalid Credentials");
            // }
        }).catch( err => {
            if(!_.isNil(err.response)) {
                // ShowAlert(err.response.data.message,AlertTypes.DANGER,'bell');
                console.log("Login call failed :", err.response.data.message);
                toast.error(err.response.data.message);
                // dispatch(alertPopupAction(true, err.response.data.message, "danger"));
                if(_.isFunction(cb)) cb(false, err.response);
            } else {
                // ShowAlert(CONSTANTS.MESSAGES.NETWORK_ERROR,AlertTypes.DANGER,'info');
            }
        });
    }
}

export const appleloginUser = (params, cb = null) => {
    return (dispatch, getState) => {
        axios.post(BASE_URL(CONSTANTS.CALLS.APPLE_LOGIN), params).then((res) => {
            console.log("Login Apple Social Call Success :", res, cb);
            // if(res.data.userType == "admin"){
                sessionService.saveSession({token: res.data.accessToken, userType: res.data.userType, email: res.data.email, id: res.data.id}).then(() => {
                    // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
                    sessionService.saveUser(res.data).then(() => {
                        dispatch(setUser(res.data));
                        if(_.isFunction(cb)) cb(res.data);
                    }).catch(() => {
                        toast.error(CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                        if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                        // console.log("Session Service save user err");
                    });
                }).catch(() => {
                    if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_NOT_SAVED);
                    toast.error(CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                });
            // } else {
                // //dispatch(alertPopupAction(true, "Invalid Credentials", "danger"));
            //     if(_.isFunction(cb)) cb(true, "Invalid Credentials");
            // }
        }).catch( err => {
            if(!_.isNil(err.response)) {
                // ShowAlert(err.response.data.message,AlertTypes.DANGER,'bell');
                console.log("Login call failed :", err.response.data.message);
                toast.error(err.response.data.message);
                // dispatch(alertPopupAction(true, err.response.data.message, "danger"));
                if(_.isFunction(cb)) cb(false, err.response);
            } else {
                // ShowAlert(CONSTANTS.MESSAGES.NETWORK_ERROR,AlertTypes.DANGER,'info');
            }
        });
    }
}

export const SignUpUser = (params, cb = null) => {
    return (dispatch, getState) => {
        axios.post(BASE_URL(CONSTANTS.CALLS.SIGNUP), params).then((res) => {
            // console.log("Login Call Success :", res, cb);
            // if(res.data.userType == "admin"){
                sessionService.saveSession({token: res.data.accessToken, userType: res.data.userType, email: res.data.email, id: res.data.id}).then(() => {
                    // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
                    sessionService.saveUser(res.data).then(() => {
                        dispatch(setUser(res.data));
                        if(_.isFunction(cb)) cb();
                    }).catch(() => {
                        toast.error(CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                        if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                        // console.log("Session Service save user err");
                    });
                }).catch(() => {
                    if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_NOT_SAVED);
                    toast.error(CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                });
            // } else {
                // //dispatch(alertPopupAction(true, "Invalid Credentials", "danger"));
            //     if(_.isFunction(cb)) cb(true, "Invalid Credentials");
            // }
        }).catch( err => {
            if(!_.isNil(err.response)) {
                // ShowAlert(err.response.data.message,AlertTypes.DANGER,'bell');
                console.log("Signup call failed :", err.response.data.message);
                toast.error(err.response.data.message);
                // dispatch(alertPopupAction(true, err.response.data.message, "danger"));
                if(_.isFunction(cb)) cb(true, err.response);
            } else {
                // ShowAlert(CONSTANTS.MESSAGES.NETWORK_ERROR,AlertTypes.DANGER,'info');
            }
        });
    }
}

export const logoutUser = (cb) => {
    return (dispatch) => {
        sessionService.loadSession().then((currentSession) => {
            axios({
                method: 'post',
                url: BASE_URL(CONSTANTS.CALLS.ADMIN_LOGOUT),
                headers: { Authorization: `Bearer ${currentSession.token}` }
            }).then(res => {}).catch(err => {});
            sessionService.deleteSession().then(() => {
                sessionService.deleteUser().then(() => {
                    if(_.isFunction(cb)) cb();
                });
            });
        }).catch(err => {
            cb(err);
        });
    };
};

export const changePassword = (params, cb) => {
    return (dispatch) => {
        sessionService.loadSession().then(currentSession => {
            axios({
                method: 'post',
                url: BASE_URL(CONSTANTS.CALLS.ADMIN_CHANGE_PASSWORD),
                headers: { Authorization: `Bearer ${currentSession.token}` },
                data: {
                    currentPassword: params.currentPassword,
                    newPassword: params.newPassword,
                    verifyPassword: params.verifyPassword,
                }
            }).then(res => {
                if(res.status === 200 && _.isFunction(cb)) {
                    cb(true, res.data.message);
                } else { cb(false, res.data.message); }
            }).catch(err => {
                if(!_.isNil(err.response)) {
                    if(err.response.data && err.response.data.data && err.response.data.data.field){
                        // ShowAlert(err.response.data.data.field, AlertTypes.DANGER, 'info', true, 1000);
                    } else if(err.response.data && err.response.data.data && err.response.data.data.password){
                        console.log('err.response.data');
                        console.log(err.response.data);
                        // ShowAlert(`${err.response.data.message} Please include ${err.response.data.data.password} to make your password strong`, AlertTypes.DANGER, 'info', true, 10000);
                        // ShowAlert("HELLO", AlertTypes.DANGER, "info", true, 10000);
                    }else {
                        // ShowAlert(err.response.data.message, AlertTypes.DANGER, 'info');
                    }
                    // console.log("Change password :", JSON.stringify(err));
                    if (_.isFunction(cb)) {
                        cb(false, err.response.data.message);
                    }
                } else {
                    if (_.isFunction(cb)) {
                        cb(false, CONSTANTS.MESSAGES.NETWORK_ERROR);
                    }
                }
            });
        }).catch(err => {
            if(_.isFunction(cb)){
                cb(false, err);
            }
        });
    };
};

export const forgotPassword = (params, cb = null) => {
    return (dispatch, getState) => {
        axios.get(BASE_URL(CONSTANTS.CALLS.FORGOT_PASSWORD), {params:params}).then((res) => {
            // console.log("forgotPassword Call Success :", res, cb);
            cb(res);
        }).catch( err => {
            if(!_.isNil(err.response)) {
                console.log("forgotPassword call failed :", err.response.data.message);
                toast.error(err.response.data.message);
                if(_.isFunction(cb)) cb(false, err.response);
            } else {
            }
        });
    }
}
export const resetPassword = (params, cb = null) => {
    return (dispatch, getState) => {
        axios.post(BASE_URL(CONSTANTS.CALLS.RESET_PASSWORD), params).then((res) => {
            // console.log("forgotPassword Call Success :", res, cb);
            cb(res);
        }).catch( err => {
            if(!_.isNil(err.response)) {
                console.log("resetPassword call failed :", err.response.data.message);
                toast.error(err.response.data.message);
                if(_.isFunction(cb)) cb(false, err.response);
            } else {
            }
        });
    }
}

export const getOtp = (params, cb = null) => {
    return (dispatch, getState) => {
        axios.post(BASE_URL(CONSTANTS.CALLS.ADMIN_GET_OTP), params).then((res) => {
            // console.log("GET OTP CALL:",res);
            // console.log("Login Call Success :", res, cb);
            // sessionService.saveSession({token: res.data.accessToken}).then(() => {
            //     // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
            //     sessionService.saveUser(res.data).then(() => {
            //         dispatch(setUser(res.data));
                    if(_.isFunction(cb)) cb(res.data.id);
            //     }).catch(() => {
            //         if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
            //         // console.log("Session Service save user err");
            //     });
            // }).catch(() => {
            //     if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_NOT_SAVED);
            // });
        }).catch( err => {
            if(!_.isNil(err.response)) {
                // ShowAlert(err.response.data.message,AlertTypes.DANGER,'bell');
                // console.log("Login call failed :", err.response.data.message);
            } else {
                // ShowAlert(CONSTANTS.MESSAGES.NETWORK_ERROR,AlertTypes.DANGER,'info');
            }
        });
    };
};


export const verifyOtp = (params, cb = null) => {
    return (dispatch, getState) => {
        axios.post(BASE_URL(CONSTANTS.CALLS.ADMIN_VERIFY_OTP), params).then((res) => {
            // console.log("Login Call Success :", res, cb);
            sessionService.saveSession({token: res.data.accessToken}).then(() => {
                // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
                sessionService.saveUser(res.data).then(() => {
                    dispatch(setUser(res.data));
                    // history.push("/");
                    if(_.isFunction(cb)) cb(res.data);
                }).catch(() => {
                    if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_USER_NOT_SAVED);
                    // console.log("Session Service save user err");
                });
            }).catch(() => {
                if(_.isFunction(cb)) cb(false, CONSTANTS.MESSAGES.SESSION_NOT_SAVED);
            });
        }).catch( err => {
            if(!_.isNil(err.response)) {
                // ShowAlert(err.response.data.message,AlertTypes.DANGER,'bell');
                if(err.response.data && err.response.data.code && err.response.data.code == 'ERRACC0023'){
                    // history.push("/login");
                    cb(err.response.data);
                }
            } else {
                // ShowAlert(CONSTANTS.MESSAGES.NETWORK_ERROR,AlertTypes.DANGER,'info');
            }
        });
    }
}