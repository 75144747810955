import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import './App.scss';
import routes from './routes';
import Footer from './shared/Footer';
import Navbar from './shared/Navbar';
import SettingsPanel from './shared/SettingsPanel';
import Sidebar from './shared/Sidebar';

class App extends Component {
  state = {
    user:'',
    userType: ''
  }
  componentDidMount = () => {
    this.onRouteChanged();
  }
  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar user={this.props.user} /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar user={this.props.user} /> : '';
    let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    let hasCompany = (this.props.user && (this.props.user.logo || this.props.user.organization)) ? true : false;
    const switchRoutes = (
      <Suspense fallback={<Spinner/>}>
      <Switch>
          {routes.map((prop, key) => {
            if (prop.path === '/login' || prop.path === '/reset' || prop.path === '/signup' || prop.path === '/verification/:code') {
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
            if (this.props.user && (!prop.allowedRoles || prop.allowedRoles.includes(this.props.user.userType) || this.props.user.userType === "developer")) {
              return (
                  <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                  />
              );
            }
            return null;
          })}
          <Redirect from="/" to="/login" />
      </Switch>
      </Suspense>
    );
    return (
      <div className="container-scroller">
        { navbarComponent }
        <div className={hasCompany ? "container-fluid page-body-wrapper has-company" : "container-fluid page-body-wrapper"} >
          { sidebarComponent }
          <div className={hasCompany ? "main-panel" : "main-panel"}  id="scrollableDiv">
            <div className="content-wrapper1">
                <div>
                    <div>{switchRoutes}</div>
                </div>
              {/* { SettingsPanelComponent } */}
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.getElementsByClassName("main-panel")[0].scrollTo(0,0);
    const fullPageLayoutRoutes = ['/login', '/reset', '/verification', '/signup', '/user-pages/register-1', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    let verifyRoute = this.props.location.pathname.split("/");
    let isVerification = false;
    if(verifyRoute[1] && verifyRoute[1] == 'verification'){
      isVerification = true;
    }
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i] || isVerification) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}


const mapStateToProps = state => ({
  user: state.authData.user
});

export default connect(mapStateToProps)(withRouter(App));