import PropTypes from "prop-types";
import React from "react";
import { IconexFilledCheck } from "../Icons/IconexFilledCheck";
// import { IconexFilled } from "../Icons/IconexFilled";
import {Button} from "../button/button";
import moment from 'moment';

export const Plans = ({
  className,
  buttetPoints,
  planTitle,
  planPrice,
  planDuration,
  active,
  bestValue = false,
  displayButton = false,
  buttonType = "cancel",
  onPurchase,
  onCancel,
  price_id,
  status
}) => {
  return (
    <div className={`plans ${className}`}>
        <div className="frame">
          <div className="free-wrapper">
            <div className="div">
                {planTitle}
            </div>
          </div>
          {active && <>
          <div className="frame-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                    <path d="M1.8929 6.90951L4.55957 9.57617L9.8929 4.24284" stroke="#11C979" strokeWidth="2.15836" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            <div className="text-wrapper">Active</div>
          </div>
          </>}
        </div>

      
          <div className="element plan-pricing">
            {planTitle === "Free" && <>{planPrice}</>}

            {planTitle !== "Free" && (
              <>
                <p className="span-wrapper">
                  <span className="span">${planPrice}</span>
                </p>
                <p className="span-wrapper">
                  <span className="span-2">/ {planDuration}</span>
                </p>
              </>
            )}
          </div>
      <div className="rectangle" />

          { buttetPoints && buttetPoints.length > 0 ? 
            buttetPoints.map((point, index) => {
                return (
                    <div className="frame-7">
                    <div className="div-2">
                        <IconexFilledCheck />
                    </div>
                    <div className="div-3">
                        {point}
                        </div>
                    </div>
                );
            })
         : ""}

        {bestValue && <>
        <div className={`best-value-badge default plan-4`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.78229 1.67972C4.82747 1.48827 5.09995 1.48827 5.14513 1.67972L5.50664 3.21174C5.52285 3.28047 5.57652 3.33413 5.64524 3.35035L7.17726 3.71186C7.36872 3.75704 7.36872 4.02951 7.17726 4.07469L5.64524 4.4362C5.57652 4.45242 5.52285 4.50608 5.50664 4.57481L5.14513 6.10683C5.09995 6.29828 4.82747 6.29828 4.78229 6.10683L4.42078 4.57481C4.40457 4.50608 4.3509 4.45242 4.28218 4.4362L2.75016 4.07469C2.5587 4.02951 2.5587 3.75704 2.75016 3.71186L4.28218 3.35035C4.3509 3.33413 4.40457 3.28047 4.42078 3.21174L4.78229 1.67972ZM9.86656 3.28273C9.96874 2.84969 10.5851 2.84968 10.6872 3.28273L11.5049 6.74801C11.5416 6.90346 11.663 7.02484 11.8185 7.06152L15.2837 7.87922C15.7168 7.98141 15.7168 8.59772 15.2837 8.69991L11.8185 9.51761C11.663 9.55429 11.5416 9.67567 11.5049 9.83112L10.6872 13.2964C10.5851 13.7294 9.96874 13.7294 9.86656 13.2964L9.04886 9.83112C9.01217 9.67567 8.8908 9.55429 8.73534 9.51761L5.27007 8.69991C4.83702 8.59772 4.83702 7.98141 5.27007 7.87922L8.73534 7.06152C8.8908 7.02484 9.01217 6.90346 9.04886 6.74801L9.86656 3.28273ZM4.67205 10.2724C4.60732 9.99808 4.21696 9.99808 4.15223 10.2724L3.63431 12.4672C3.61108 12.5657 3.5342 12.6426 3.43574 12.6658L1.24087 13.1837C0.966584 13.2485 0.966585 13.6388 1.24087 13.7035L3.43574 14.2215C3.5342 14.2447 3.61108 14.3216 3.63431 14.42L4.15223 16.6149C4.21696 16.8892 4.60732 16.8892 4.67205 16.6149L5.18997 14.42C5.2132 14.3216 5.29008 14.2447 5.38855 14.2215L7.58341 13.7035C7.8577 13.6388 7.8577 13.2485 7.58341 13.1837L5.38855 12.6658C5.29008 12.6426 5.2132 12.5657 5.18997 12.4672L4.67205 10.2724Z" fill="white"/>
            </svg>
            <div className="text-wrapper-3">Best Value</div>
          </div>
          </>}

          {displayButton && <>
            {buttonType === "cancel" ? 
            !status.cancelAtEnd &&
            <div className="plan-action-button">
            <Button
                className="button-instance"
                label="Cancel"
                showLeftIcon={false}
                showRightIcon={false}
                size="small"
                state="default"
                variant="outlined"
                type="error"
                disabled={status.btnState}
                clickEvent={(e) => onCancel(e, planTitle)}
                />
            </div>
            : 
            <div className="plan-action-button">
            <Button
                className="button-instance"
                label="Purchase"
                showLeftIcon={false}
                showRightIcon={false}
                size="small"
                state="default"
                variant="filled"
                type="secondary"
                disabled={status.btnState}
                clickEvent={(e) => onPurchase(e, planTitle, price_id)}
                />
            </div>}
          </>}

          { active && planTitle !== "Free" &&
            <div className="purchase_status">
              <div className="activation_stats">
                Activated on: {moment(status.purchaseDate, "X").format('DD MMM YYYY')}
              </div>
              {status.cancelAtEnd ?
                <div className="if_canceled">
                  *Your subscription will be terminated before {moment(status.purchaseDate, "X").add(1, status.cycleEnd).format('DD MMM YYYY')}
                </div>
                :
                <div className="if_continues">
                  Next Billing: {moment(status.purchaseDate, "X").add(1, status.cycleEnd).format('DD MMM YYYY')}
                </div>
              }
            </div>
          }
    </div>
  );
};

Plans.propTypes = {
  property1: PropTypes.oneOf(["annual", "monthly", "free"]),
  property2: PropTypes.oneOf(["purchased", "hover", "default"]),
  frame: PropTypes.string,
  line: PropTypes.string,
  onPurchase: PropTypes.func,
  onCancel: PropTypes.func,
  img: PropTypes.string,
  price_id: PropTypes.string,
  line1: PropTypes.string,
  status: PropTypes.object,
};
